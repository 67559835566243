
import React, { useEffect, useState, useRef } from 'react';
import { Grid, Box, Avatar, Button, IconButton , InputAdornment, Checkbox} from "@mui/material";
import TypographySubHeading from "../Components/TypographySubHeading";
import TypographyHeading  from "../Components/TypographyHeading";
import TypographyNormal from "../Components/TypographyNormal";
import Colors from '../Components/Colors';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import popupbg from '../Images/popup.png'
import whitelogo from '../Images/whitelogo.svg'
import TextfieldNormal from '../Components/TextfieldNormal';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ButtonBlue from '../Components/ButtonBlue';
import TypographyError from '../Components/TypographyError';
import UserTermPopUp from './UserTermPopUp';
import axios from "axios";
import { API_URL } from '../Components/Constants';
import passwordvisible from '../Images/passwordvisible.svg'
import passwordhide from '../Images/passwordhide.svg'



function SpResetPassword(props){

    const {setSpPopupType, spPopupType} = props
   
   
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordError, setNewPasswordError] = useState("");
    const [showNewPassword, setShowNewPassword] = useState(false);  
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [showConfirmPassword, setShowConfirmPassword] = useState(false); 
    const [forgotError, setForgotError] = useState("");

    const timeoutRef = useRef(null);

   

    const handleNewPasswordChange = (event) => {
        setNewPassword(event.target.value);
        setNewPasswordError(""); 
        setForgotError("")       
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
        setConfirmPasswordError(""); 
        setForgotError("")        
    };  
    
    const savedData = JSON.parse(localStorage.getItem('spforgotpassword'));

    const handleSaveButtonClick = async () => {       
                 
          
            if (newPassword.trim() === "") {
                setNewPasswordError("Please enter your password");
            }
            else if (newPassword.length > 64) {
                setNewPasswordError("Password is too long.");
            }
            else if (newPassword.length < 4) {
                setNewPasswordError("Password is too short.");
            }
            else if (confirmPassword.trim() === "") {
                setConfirmPasswordError("Please enter your password");
            }
            else if(newPassword.trim() !== confirmPassword.trim()){
                setForgotError("The new password and confirm password do not match.")
            }
           
        else {
          
            try {                    
                const response = await axios.post(`${API_URL}/reset_sp_password`, {                    
                    spforgottoken: localStorage.getItem("SpForgotToken"),              
                    password: confirmPassword
                    
                }); 
                console.log('User data inserted successfully:', response.data); 
                setForgotError(response.data.error)
                if(response.data.success === true){
                        localStorage.removeItem('spforgotpassword')
                        setSpPopupType('successmsg')
                    
                }
            } catch (error) {
                console.error('Error inserting user data:', error);
            } 
        
         }
    };   

    const toggleNewPasswordVisibility = () => {
        setShowNewPassword(!showNewPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };
  

    const handleCloseChangePasswordPopUp = () => {
        setSpPopupType(null);     
    }
    

    useEffect(() => {
        const resetTimer = () => {
            // Clear the previous timer and set a new one
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
            timeoutRef.current = setTimeout(() => {
                setSpPopupType('Spforgotph'); // Redirect to the previous screen after 2 minutes
            }, 120000); // 120000 milliseconds = 2 minutes
        };

        const handleUserActivity = () => {
            resetTimer(); // Reset the timer on user activity
        };

        // Event listeners for user activity
        window.addEventListener('mousemove', handleUserActivity);
        window.addEventListener('keypress', handleUserActivity);
        window.addEventListener('click', handleUserActivity);
        
        resetTimer(); // Start the timer on component mount

        // Cleanup function
        return () => {
            clearTimeout(timeoutRef.current); // Clear the timer on unmount
            window.removeEventListener('mousemove', handleUserActivity);
            window.removeEventListener('keypress', handleUserActivity);
            window.removeEventListener('click', handleUserActivity);
        };
    }, [setSpPopupType, spPopupType]); // No need to include `history`, just use `setSpPopupType`

   



return(
<Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px' }}>

        {spPopupType === 'changePassword' && <Box sx={{
            position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.6)',
            display: 'flex',alignItems: 'center',justifyContent: 'center',zIndex: '1500',

        }}>
            <Box sx={{
                width: '100%', height: '100%', gap: '5px',
                justifyContent: 'center', alignItems: 'center', position: 'fixed',
                display: 'flex', flexDirection: 'column',
            }}>
                <Box sx={{
                    width: { md: '75%', sm: '75%', xs: '85%' }, height: '36px',
                    justifyContent: 'right', alignItems: 'right', display: 'flex',
                }}>
                        <IconButton onClick={handleCloseChangePasswordPopUp} sx={{
                    position: 'fixed', width: '36px', height: '36px',}} >
                        <CloseOutlinedIcon sx={{ width: '36px', height: '36px', color: Colors.black_60, '&:hover': {
                        width: '40px', height: '40px', color: Colors.black_60, backgroundColor: 'none',
                        background:'none'},}} ></CloseOutlinedIcon>
                    </IconButton>
                </Box>
                <Box sx={{width: { md: '75%', sm: '75%', xs: '85%' },height: 'fit-content',
                        backgroundColor: Colors.white, border: '1px solid',
                        borderColor: Colors.white, borderRadius: '4px ',boxShadow: 'none',
                        zIndex: '3', alignItems: 'center', 
                    }}>

                 
                            <Box sx={{
                                display: 'flex', flexDirection: 'row', width: '100%', 
                                justifyContent: 'center', alignContent: 'center', height: '100%'}}>
                                    
                                    <Box sx={{
                                        display: {xs: 'none', md:'flex' }, flexDirection: 'column', width: '45%', gap: '8px',
                                        justifyContent: 'center',  height: '100%', alignItems: 'center', borderRadius: '4px 0px 0px 4px',
                                        backgroundImage: `url(${popupbg})`, backgroundRepeat: 'no-repeat', border:'1px solid', 
                                        backgroundSize: '100% 100%',backgroundPosition: 'center',borderColor: Colors.primary_blue,}}>
                                              <Box sx={{
                                                display: 'flex', flexDirection: 'column', width: '100%', gap: '12px',
                                                justifyContent: 'center', maxWidth:'75%',alignItems: 'left', }}>
                                                <TypographyNormal sx={{fontSize:'16px', color: Colors.white_80}}>Welcome</TypographyNormal>
                                                <Box sx={{
                                                display: 'flex', flexDirection: 'row',  width: '200px', height: '32px',alignContent:'flex-start',
                                                justifyContent: 'flex-start',alignItems: 'flex-start', }}>
                                                <img src={whitelogo} alt="logo" style={{width: '100%', height: '100%', }}/>
                                                </Box>
                                                <TypographyNormal sx={{ color: Colors.white_80}}>Find the help you need, right in your neighborhood.</TypographyNormal>
                                             </Box>
                                    </Box> 
                                  


                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', width: {xs: '100%', md:'55%' }, gap: '8px',
                                        justifyContent: 'center',  height: '100%', alignItems: 'center', backgroundColor: Colors.white, border: '1px solid',
                                        borderColor: Colors.white,}}>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '8px',paddingTop:'20%',
                                            height:'100%', justifyContent: 'center', maxWidth:'75%',alignItems: 'center',paddingBottom:'20%'}}>

                                                    <Box sx={{ gap: '8px' ,  display: 'flex', flexDirection: 'column', justifyContent: 'center',
                                                        alignItems: 'left', width:'100%'}}>
                                                        <TypographyNormal sx={{ fontSize: '24px', color: Colors.black_80, }}>Create new  password</TypographyNormal>
                                                        <TypographyNormal >Create strong and secure password that protect your account.</TypographyNormal>
                                                    </Box>
                                                    <Box sx={{ gap: {xs:  '8px', md: '16px' }, display: 'flex', flexDirection: 'column', paddingTop:{xs:  '8px', md: '16px' }, width:'100%'}}>
                                                    

                                                        <Box sx={{ gap: '8px', width:'100%',flexDirection: 'column',  }}>
                                                            <TypographyNormal sx={{ fontSize: {xs:  '10px', md: '14px' }, color: Colors.black_80, }}>New password*</TypographyNormal>
                                                            <TextfieldNormal placeholder="Enter your password"
                                                               type={showNewPassword ? 'text' : 'password'}
                                                                value={newPassword}
                                                                onChange={handleNewPasswordChange} error={!!newPasswordError}
                                                                InputProps={{
                                                                    endAdornment: newPassword && (
                                                                        <InputAdornment position="end">
                                                                            <IconButton
                                                                                onClick={toggleNewPasswordVisibility}
                                                                                edge="end"
                                                                                aria-label="toggle password visibility"
                                                                                color="primary">
                                                                                {showNewPassword ? (
                                                                                    <img src={passwordvisible} alt="password Icon" />
                                                                                ) : (
                                                                                    <img src={passwordhide} alt="password Icon" />
                                                                                )}
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}></TextfieldNormal>
                                                            {newPasswordError && <TypographyError sx={{paddingTop:{xs:  '4px', md: '8px' }}}>{newPasswordError}</TypographyError>}
                                                        </Box>

                                                        <Box sx={{ gap: '8px', width:'100%',flexDirection: 'column',  }}>
                                                            <TypographyNormal sx={{ fontSize: {xs:  '10px', md: '14px' }, color: Colors.black_80, }}>Confirm password*</TypographyNormal>
                                                            <TextfieldNormal placeholder="Enter your password"
                                                               type={showConfirmPassword ? 'text' : 'password'}
                                                                value={confirmPassword}
                                                                onChange={handleConfirmPasswordChange} error={!!confirmPasswordError}
                                                                InputProps={{
                                                                    endAdornment: confirmPassword && (
                                                                        <InputAdornment position="end">
                                                                            <IconButton
                                                                                onClick={toggleConfirmPasswordVisibility}
                                                                                edge="end"
                                                                                aria-label="toggle password visibility"
                                                                                color="primary">
                                                                                {showConfirmPassword ? (
                                                                                    <img src={passwordvisible} alt="password Icon" />
                                                                                ) : (
                                                                                    <img src={passwordhide} alt="password Icon" />
                                                                                )}
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}></TextfieldNormal>
                                                            {confirmPasswordError && <TypographyError sx={{paddingTop:{xs:  '4px', md: '8px' }}}>{confirmPasswordError}</TypographyError>}
                                                        </Box>

                                                      

                                                        {forgotError && <TypographyError>{forgotError}</TypographyError>}
                                                            <Box sx={{ width:'100%',flexDirection: 'column',justifyContent:'center', display:'flex',
                                                            alignItems:'center', alignContent:'center', gap:'8px' , paddingTop: '12px' }}>
                                                                <ButtonBlue onClick={handleSaveButtonClick}>Save</ButtonBlue>
                                                                
                                                            </Box>

                                                    
                                                    </Box>

                                            </Box>
                                    </Box>
                            </Box>
                    
                </Box>
            </Box>
        </Box>}
</Grid>
)
}
export default SpResetPassword;