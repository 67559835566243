

import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import { IMG_URL } from "../Components/Constants";
import { useState, useEffect, useRef } from 'react';
import Colors from '../Components/Colors';
import TypographyNormal from '../Components/TypographyNormal';
import ButtonBlue from '../Components/ButtonBlue';
import CancelIcon from '@mui/icons-material/Cancel';
import axios from 'axios';
import { API_URL } from '../Components/Constants';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import logo from '../Images/logo.png'
import { useNavigate } from 'react-router-dom';
import { Grid, TextField, Tabs, Tab, Divider, Switch, FormControlLabel, Avatar, TablePagination, List, ListItem, } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddIcon from '@mui/icons-material/Add';
import AssociateHeader from '../Components/AssociateHeader';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import TextfieldNormal from '../Components/TextfieldNormal';
import EmailIcon from '@mui/icons-material/Email';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import LanOutlinedIcon from '@mui/icons-material/LanOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import WysiwygOutlinedIcon from '@mui/icons-material/WysiwygOutlined';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Oops from '../Images/oops.png'
import ButtonWhite from '../Components/ButtonWhite';
import ProfileComponent from '../Components/ProfileComponent';
import TypographySubHeading from '../Components/TypographySubHeading';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const colors = ['#503259', '#4C50AE', '#FF7D6A', '#B07CC6', '#20C925'];

  function getAvatarColor(index) {
  return colors[index % colors.length];
  }

  function stringAvatar(name = '', index) {
    console.log(name[0])
  const initials = name[0].toUpperCase() || '';
  const bgColor = getAvatarColor(index);
  return {
      sx: {
      bgcolor: bgColor,
      width: '64px',
      height: '64px',
      fontSize: '56px',
      color: '#fff',
      },
      children: initials,
  };
  }


const StyledTableCell = styled(TableCell)({
  fontSize: 14,
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function AlphaAssociateLanding() {
  const [showDropdown, setShowDropdown] = useState(false);
  const [isViewProfile, setIsViewProfile] = useState(false);
  const [assossiateName, setAssossiateName] = useState("");
  const [assossiateId, setAssossiateId] = useState("");
  const [searchGroupChar, setSearchGroupChar] = useState('');
  const [serviceProviderDtls, setServiceProviderDtls] = useState(null);
  const [newProfilePic, setNewProfilePic] = useState(null);
  const [serviceProviderQrCode, setServiceProviderQrCode] = useState(null);
  const [serviceProviderUrl, setServiceProviderUrl] = useState(null); 
  const [professionName, setProfessionName] = useState([]);  
  const [serviceDtls, setServiceDtls] = useState([]);  
  const [paginatedServiceProviders, setPaginatedServiceProviders] = useState([]);
  const [page, setPage] = useState(0);
  const [serviceProviderDetails, setServiceProviderDetails] = useState([]);
  const [qrCodeUrl, setQrCodeUrl] = useState(null);
  const [serviceDetails, setServiceDetails] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null); 
  const [serviceTabName, setServiceTabName] = useState([]);
  const [selectedServiceData, setSelectedServiceData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredProviders, setFilteredProviders] = useState([]);
  const [selectedProviderId, setSelectedProviderId] = useState(null);
  const [comments, setComments] = useState({}); 
  const [serviceProviders, setServiceProviders] = useState([]);
  const [supportTeams, setSupportTeams] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isImagePopUp, setIsImagePopUp] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const tabs = ['New Onboarding', 'Pending', 'Rejected', 'Accepted'];
  const colors = ['#F0624E', '#45DCDC', '#A14C93', '#F22B7E', '#5F90DB'];
  
  const [rowsPerPage, setRowsPerPage] = useState(7);

  const searchListRef = useRef(null);
  const searchBoxRef = useRef(null);
  const dropdownRef = useRef(null);
  const triggerRef = useRef(null);

  const [selectedTab, setSelectedTab] = useState(() => {  
    return localStorage.getItem('selectedTab') ? parseInt(localStorage.getItem('selectedTab')) : 0;
  });
  const [value, setValue] = useState(selectedTab);

  useEffect(() => {    
    localStorage.setItem('selectedTab', value);
  }, [value]);

  const formatDateTime = (dateTimeString) => {
    const dateUTC = new Date(dateTimeString);
  
    // Check if dateUTC is valid
    if (isNaN(dateUTC.getTime())) {
      return 'Invalid Date'; // Return a default message for invalid dates
    }
  
    const timeOptions = {
      timeZone: 'America/Toronto',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    };
  
    const timeFormatter = new Intl.DateTimeFormat('en-CA', timeOptions);
    const formattedTime = timeFormatter.format(dateUTC);
  
    const dateInToronto = new Date(dateUTC.toLocaleString('en-US', { timeZone: 'America/Toronto' }));
    const day = String(dateInToronto.getDate()).padStart(2, '0');
    const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    const month = monthNames[dateInToronto.getMonth()];
    const year = dateInToronto.getFullYear();

  return `${year}-${month}-${day} ${formattedTime}`;
  };
  


  const navigate = useNavigate();

  const handleSignOutPopUpOkButtonClick = async () => {
    localStorage.removeItem('token');
    navigate('/signin', { replace: true });
  };

 
  

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setShowDropdown((prev) => !prev);
  };

  // Click outside handler
  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      triggerRef.current &&
      !triggerRef.current.contains(event.target)
    ) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    if (showDropdown) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    // Cleanup listener on unmount or when showDropdown changes
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showDropdown]);

  const handleMenuItemClick = (value) => {
    let sortedProviders;
    if (value === "name") {
      sortedProviders = [...serviceProviders].sort((a, b) => 
        a.serviceProvider.business_name.localeCompare(b.serviceProvider.business_name)
      );
    }
    if (value === "time") {
      sortedProviders = [...serviceProviders].sort((a, b) => {
       
        const normalizeDate = (dateString) => {         
          if (dateString.includes('T')) {
            return new Date(dateString);
          } else {          
            return new Date(dateString + 'T00:00:00.000Z');
          }
        };
        
        const dateA = normalizeDate(a.serviceProvider.joined_date);
        const dateB = normalizeDate(b.serviceProvider.joined_date);
        
        // Compare dates
        return dateB - dateA;
  });

    }
    setServiceProviders(sortedProviders);
    setPaginatedServiceProviders(sortedProviders)    
    setShowDropdown(false);
  }

  const fetchAssossiateDetails = async () => {
    const associatetocken = localStorage.getItem("associatetocken")
    try {
      const response = await axios.post(`${API_URL}/select_associate_details_with_token`,
    { associateTocken: localStorage.getItem("associateToken") });
      console.log(response.data.result.associate_name)
      setAssossiateName(response.data.result.associate_name);
      setAssossiateId(response.data.result.associate_id)
    } catch (error) {
      console.error('Error fetching service categories:', error);
    }
  };

  useEffect(() => {
    fetchAssossiateDetails();

  }, []);

   function handleAddServiceProviderClick() {
    localStorage.removeItem('ASPID')
    const isEdit = false; 
     window.location.href = `/associate-profile?edit=${isEdit}`;  
  }

  function handleViewSupportTeamClick() {   
     window.location.href = `/associate-support-team`;  
  }

  function handleAddtoSupportTeamClick() {   
    window.location.href = `/assign-support-team`;  
 }
  
  

  
 

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setServiceProviders([])
    setPage(0)
  };

 
  const fetchServiceProviders = async () => {
    const status = tabs[value];
    console.log(status)
    setLoading(true); // Set loading to true before the fetch
    try {
      const response = await axios.post(`${API_URL}/associate_sp_details_with_tab`, { status });
      console.log(response.data.data);
      setServiceProviders(response.data.data || []);
      setPaginatedServiceProviders(response.data.data || []); 
    } catch (error) {
      console.error('Error fetching service providers:', error);
    } finally {
      setLoading(false); // Set loading to false after the fetch
    }
  };

  useEffect(() => {
    fetchServiceProviders();
  }, [value]);
  


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchListRef.current && !searchListRef.current.contains(event.target) && !searchBoxRef.current.contains(event.target)) {
        setSearchGroupChar('');
        setFilteredProviders([]);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchGroupChar(value);
  
    if (value) {
      const filtered = serviceProviders.filter(provider => 
        provider.serviceProvider.business_name.toLowerCase().startsWith(value.toLowerCase()) ||
        provider.serviceProvider.phone_number.toLowerCase().startsWith(value.toLowerCase())
      );
      setFilteredProviders(filtered);
    } else {
      setFilteredProviders([]);
    }
  };
  
  


  const handleSelectProvider = (serviceProviderId) => {
    setSelectedProviderId(serviceProviderId);
  
    // Find the selected provider and other providers
    const selectedProvider = serviceProviders.find(provider => provider.serviceProvider.service_provider_id === serviceProviderId);
    const otherProviders = serviceProviders.filter(provider => provider.serviceProvider.service_provider_id !== serviceProviderId);
  
    // Create a new list with the selected provider at the top
    const sortedProviders = [selectedProvider, ...otherProviders];
  
    // Calculate the current page index
    const startIndex = page * rowsPerPage;
    const endIndex = (page + 1) * rowsPerPage;
  
    // Ensure the selected provider is within the current page
    const providerIndex = sortedProviders.findIndex(provider => provider.serviceProvider.service_provider_id === serviceProviderId);
    if (providerIndex < startIndex || providerIndex >= endIndex) {
      // Move the provider to the top of the current page if it isn't already in view
      const paginatedProviders = sortedProviders.slice(startIndex, endIndex);
      if (providerIndex < startIndex) {
        const newPage = Math.floor(providerIndex / rowsPerPage);
        setPage(newPage);
      }
      setPaginatedServiceProviders(paginatedProviders);
    } else {
      setPaginatedServiceProviders(sortedProviders.slice(startIndex, endIndex));
    }
  
    // Update the full list of providers
    setServiceProviders(sortedProviders);
  
    // Clear search filters
    setFilteredProviders([]);
    setSearchGroupChar('');
  };
  
  

  useEffect(() => {
    // Calculate the start and end index for pagination
    const startIndex = page * rowsPerPage;
    const endIndex = Math.min(serviceProviders.length, (page + 1) * rowsPerPage);
    
    // Get the paginated list based on updated serviceProviders
    const paginatedProviders = serviceProviders.slice(startIndex, endIndex);
    
    setPaginatedServiceProviders(paginatedProviders);
  }, [page, serviceProviders]); // Depend on both page and serviceProviders
  
  
  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
 

 


 

  const rowRefs = useRef({}); // To store references to table rows

  useEffect(() => {
    if (selectedProviderId && rowRefs.current[selectedProviderId]) {
      // Ensure row is visible in the viewport
      rowRefs.current[selectedProviderId].scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [selectedProviderId, page]);


  const handleEditProfileButtonClick = (id) => {
    console.log(id)
    localStorage.setItem("ASPID", id)
   const isEdit = true; 
   window.location.href = `/associate-profile?edit=${isEdit}`;
  };

  const handleEditLocationButtonClick = (id) => {
    localStorage.setItem("ASPID", id)
   // window.location.href = '/associate-location'
   const isEdit = true; 
   window.location.href = `/associate-location?edit=${isEdit}`;
  };

  const handleEditServiceButtonClick = (id) => {
    localStorage.setItem("ASPID", id)
    localStorage.setItem('editservice', 'true');
    localStorage.removeItem('addservice');
   // window.location.href = '/associate-service'
   const isEdit = true; 
   window.location.href = `/associate-service?edit=${isEdit}`;
  };

  const handleAddServiceClick = (id) => {
    localStorage.setItem("ASPID", id)
    localStorage.setItem('addservice', 'true');
    localStorage.removeItem('editservice');
    const isEdit = true; 
    window.location.href = `/associate-service?edit=${isEdit}`;
  }
  const [checked, setChecked] = useState(false);
  const [profileIndex, setProfileIndex] = useState(0);
 
  const handleViewProfileClick = (id, index) => {
    console.log(id, index)   
    setProfileIndex(index)
    fetchserviceProviderDtls(id)
    localStorage.setItem("changeId",id)
  };

  let searchServiceId
  let joinedDate
  let formattedDate
 
  async function fetchserviceProviderDtls(spId) {
    try { 
      const response = await axios.post(`${API_URL}/select_sp_details_with_spId`, {                    
        spId
      }); 
      setIsViewProfile(true)
      setChecked(response.data.result.result.service_details[0].associate_status === 'Pending')  
      setAlignment(response.data.result.result.service_details[0].associate_status);     
      console.log(response.data.result.result.service_provider_id)
      const serviceDetails = response.data.result.result.service_details;
      searchServiceId = serviceDetails[0].services_id
      setSelectedTab(0)
      console.log(response.data.result.result)
      setSelectedServiceData(serviceDetails[0])

      setServiceProviderDetails(response.data.result.result); 
      setServiceDetails(serviceDetails);  
      setQrCodeUrl(response.data.result.qrCode)             

      const tabNames = serviceDetails.map(item => item.professional_name);
      setServiceTabName(tabNames);
      setServiceProviderDtls(response.data.data.serviceProvider)
      
       
       
      console.log(response.data.result.result.service_details[0].associate_status)
      

     
  } catch (error) {
      console.error('Error fetching data:', error);
     
  }
  setIsViewProfile(true)
  }



  
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    const selectedService = serviceDetails[newValue];
    console.log(selectedService)
    setSelectedServiceData(selectedService);
};

  useEffect(() => {
    if (selectedServiceData) {
      setSelectedServiceData(selectedServiceData)
    }
  }, [selectedServiceData]);

  useEffect(() => {
    if (serviceDtls) {
      const extractedProfessionalNames = Array.isArray(serviceDtls)
        ? serviceDtls.map(detail => ({
          professional_name: detail.professional_name,
          services_id: detail.services_id
        }))
        : [{
          professional_name: serviceDtls.professional_name,
          services_id: serviceDtls.services_id
        }];
      setProfessionName(extractedProfessionalNames);
      // setSelectedServiceData(serviceDtls[0])
    }
    setSelectedServiceData(selectedService)
  }, [serviceDtls]);
  const selectedService = serviceDtls[selectedTab] || {};


  const handleCloseViewProfilePopUp = () => {
    localStorage.removeItem("changeId")
    setIsViewProfile(false)
  };

  const [alignment, setAlignment] = useState('');

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
      // handleSwitchChange(event, newAlignment);
    }
  };

  const [buttons, setButtons] = useState([]);

  // Function to get buttons based on the selected tab
  const getButtonsForTab = (tabIndex) => {
    switch (tabIndex) {
      case 0:
        return ['New Onboarding', 'Pending'];
      case 1:
        return ['Pending', 'Rejected'];
      case 2:
        return ['Rejected', 'Pending'];
      case 3:
        return ['Accepted'];
      default:
        return [];
    }
  };

  useEffect(() => {
    console.log('Selected tab:', selectedTab);
    setButtons(getButtonsForTab(value));
  }, [value]);
  

  const handleSwitchChange = async (event, newAlignment) => {
    setChecked(newAlignment);
    console.log(event.target.value)
    let Statusvalue = event.target.value
 
    const SpIdChange = localStorage.getItem("changeId")
    console.log(SpIdChange)
    console.log(Statusvalue)
    try {      
      const response = await axios.post(`${API_URL}/associate_enable_profile`, { 
          tocken:  localStorage.getItem("associateToken"),         
          SpId: SpIdChange, 
          status: Statusvalue,
        });
        console.log(response.data.success)
        if(response.data.success === true)  {
           fetchServiceProviders();
           setIsViewProfile(false)
        }
          } catch (error) {
          console.error('Error:', error.message);
          }   
  };



  const handleAddComment = async (e, spid) => {
    if (e.key === 'Enter' && comments[spid]?.trim() !== '') {
      console.log(spid,assossiateId,comments[spid])
      try {
        const response = await axios.post(`${API_URL}/associate_add_comment`, {
          tocken:  localStorage.getItem("associateToken"),
          SpId: spid,          
          comment: comments[spid]
        });

        if (response.data.success) {
         
          setComments((prevComments) => ({
            ...prevComments,
            [spid]: '', 
          }));
          fetchComments();
        }
      } catch (error) {
        console.error('Error adding comment:', error);        
      }
    }
  };

  const handleCommentChange = (e, spid) => {
    const value = e.target.value;
    setComments((prevComments) => ({
      ...prevComments,
      [spid]: value,
    }));

  };


  const fetchComments = async () => {
    try {
      const response = await axios.get(`${API_URL}/select_all_associate_comments`);
     console.log(response.data.result)
      if (response.data.success) {       
        const fetchedComments = response.data.result.reduce((acc, comment) => {
          acc[comment.service_provider_id] = comment.associate_comments;
          return acc;
        }, {});
        setComments(fetchedComments);
      }
    } catch (error) {
      console.error('Error fetching comments:', error);
      // Handle error appropriately in the UI
    }
  };
useEffect(() => {
  fetchComments();
}, []);



    
useEffect(() => {
    
  const fetchSupportTemDetails = async () => {
    try {
      const response = await axios.get(`${API_URL}/select_support_team_details`); 
      console.log(response.data.result)
      setSupportTeams(response.data.result); 
    } catch (error) {
      console.error('Error fetching support team data:', error);
      
    }
  };
  fetchSupportTemDetails();
}, []); 

const handleImageClick = (image, index) => {
  setSelectedImage(IMG_URL + image);
  setCurrentImageIndex(index)
  setIsImagePopUp(true);
};
  


  return (
    <Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px', }}>

      <AssociateHeader></AssociateHeader>

      <Box sx={{
        display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
        width: '100%', height: '100%', paddingTop: '2.5%', paddingBottom: '2.5%',
      }}>

        <Box sx={{
          display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
          width: '100%', height: '100%', maxWidth: '96%', gap: '16px'
        }}>
          <Box sx={{
            display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',
            width: '100%', height: '100%',
          }}>
            <TypographyNormal sx={{
              fontSize: { xs: '16px', md: '18px' }, paddingLeft: { xs: '8px', md: '0px' },
              fontColor: Colors.black_80
            }}>
              List of service providers
            </TypographyNormal>
            <Box sx={{
            display: 'flex', flexDirection: 'row', justifyContent: 'right', alignItems: 'center',
            width: '70%', height: '100%', gap: '16px', }}>
              <ButtonWhite sx={{ width: 'fit-content', borderRadius: '8px' }} onClick={handleAddtoSupportTeamClick}>
              <AddIcon sx={{ paddingRight: '3px' }} />Add to support team</ButtonWhite>
                <ButtonWhite sx={{ width: 'fit-content', borderRadius: '8px' }} onClick={handleViewSupportTeamClick}>
                  <EditIcon sx={{ paddingRight: '3px', fontSize:'16px' }} />View support team</ButtonWhite>
                <ButtonBlue sx={{ width: 'fit-content', borderRadius: '8px' }} onClick={handleAddServiceProviderClick}>
                <AddIcon sx={{ paddingRight: '3px' }} />Add service provider </ButtonBlue>
            </Box>
          </Box>
        
          <Box sx={{
            display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',
            width: '100%', height: '100%',
          }}>
          <Box sx={{ display: "flex", width: '62%', flexDirection: 'row', justifyContent: 'left', alignItems: 'center', gap: '12px' }}>

                      <Tabs  value={value}
                        onChange={handleChange}
                        scrollButtons="auto"
                        variant="scrollable"                                     
                        TabIndicatorProps={{ style: { display: 'none' } }}
                        sx={{gap: '0px', '& .MuiTab-root': {  
                                padding: '0px',
                                minWidth: 'unset !important',  
                            }}}>                                       
                        {tabs.map((service, index) => (
                            <Tab
                                key={index}
                                label={
                                    <TypographyNormal sx={{
                                            fontFamily: 'Roboto',minWidth:'80px', color: value === index ? Colors.white : Colors.black_80,
                                            cursor: 'pointer', width: 'fit-content', overflow: 'hidden',textOverflow: 'ellipsis', textAlign: 'center',
                                            whiteSpace: 'nowrap', border: '1px solid', borderColor:  value === index ? Colors.secondary_blue : Colors.grey_30,
                                            padding: value === index ? '12px 12px 8px 12px' : '8px 12px', borderRadius: value === index ? '4px 4px 0px 0px' : '0px',
                                            textTransform: 'none', backgroundColor: value === index ? Colors.secondary_blue : 'transparent', marginBottom: value === index ? '4px' : '0px',
                                        }}>
                                    {service}
                                    </TypographyNormal>
                                }
                            />
                        ))}
                    </Tabs>
            </Box>

            <Box sx={{ display: "flex", width: '8%', flexDirection: 'row', justifyContent: 'left', alignItems: 'center', gap: '12px', position: 'relative' }}>
              <TypographyNormal  onClick={() => setShowDropdown(!showDropdown)} sx={{ fontSize: '14px', color: Colors.black_80 }}>
                Sort by
              </TypographyNormal>

              {showDropdown ? (
                <KeyboardArrowUpIcon 
                  sx={{ display: 'flex', width: '24px', color: Colors.black_80 }}
                  onClick={() => setShowDropdown(!showDropdown)}
                />
              ) : (
                <KeyboardArrowDownIcon  
                  sx={{ display: 'flex', width: '24px', color: Colors.black_80 }}
                  onClick={() => setShowDropdown(!showDropdown)}
                />
              )} 
                          {showDropdown && (
                <Box ref={dropdownRef} className="dropdown__menu" sx={{
                  position: 'absolute', top: '40px', right: '0px', zIndex: 1000,
                  fontFamily: 'Roboto', fontSize: '16px', fontWeight: '400',
                  lineHeight: '20px', width: '170px', background: Colors.white,
                  borderRadius: '4px', boxShadow: '0px 4px 4px 0px #00000040',
                  padding: '8px', border: '1px solid', borderColor: Colors.grey_30
                }}>
                  <style>
                    {`
                      .dropdown__menu .MuiMenuItem-root:hover {
                        background-color: #415FE2;
                        color: #FFFFFF !important;
                      }
                      .dropdown-menu__triangle {
                        position: absolute;
                        top: -10px;
                        right: 10px;
                        width: 0;
                        height: 0;
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        border-bottom: 10px solid white;
                        border-top: 10px solid ${Colors.grey_30};
                        box-shadow: 0px 4px 4px 0px #00000040;
                      }
                    `}
                  </style>

                  <MenuItem onClick={() => handleMenuItemClick("name")} value="option2" style={{
                    fontFamily: 'Roboto', fontSize: '16px', color: Colors.black_80,
                    fontWeight: '400', lineHeight: '20px', minHeight: '20px', borderRadius: '4px'
                  }}>
                    Name
                  </MenuItem>
                  <MenuItem onClick={() => handleMenuItemClick("time")} value="option2" style={{
                    fontFamily: 'Roboto', fontSize: '16px', color: Colors.black_80,
                    fontWeight: '400', lineHeight: '20px', minHeight: '20px', borderRadius: '4px'
                  }}>
                    Created Time
                  </MenuItem>
                </Box>
              )}          
            </Box>



            <Box sx={{ position: 'relative', display: "flex", width: '25%', flexDirection: 'row', justifyContent: 'left', alignItems: 'center', gap: '12px' }} ref={searchBoxRef}>
      <TextField
        variant="outlined"
        placeholder="Search"
        value={searchGroupChar}
        onChange={handleSearchChange}
        sx={{
          boxShadow: 'none', width: '100%', height: '38px',
          '& .MuiOutlinedInput-root': {
            borderRadius: '4px', background: 'white', height: '38px',
            '& fieldset': {
              borderColor: '#CFC9C9',
            },
            '&:hover fieldset': {
              borderColor: '#313131',
            },
            color: '#313131', fontFamily: 'Roboto', fontSize: '14px', lineHeight: '18px',
            fontWeight: '400',
          },
        }}
      />

      {searchGroupChar && (
        <Paper
          elevation={3}
          sx={{
            position: 'absolute',
            top: '42px', // Adjust based on TextField height
            width: '100%',
            maxHeight: '200px',
            overflowY: 'auto',
            zIndex: 1
          }}
          ref={searchListRef}
        >
          <List>
            {filteredProviders.length > 0 ? (
              filteredProviders.map(({ serviceProvider }) => (
                <ListItem
                  key={serviceProvider.service_provider_id}
                  sx={{
                    cursor: 'pointer',
                    padding: '10px',
                    '&:hover': { backgroundColor: '#f0f0f0' }
                  }}
                  onClick={() => handleSelectProvider(serviceProvider.service_provider_id)}
                >
                 <Typography>
                  {serviceProvider.business_name} ({serviceProvider.phone_number})
                </Typography>
                </ListItem>
              ))
            ) : (
              <ListItem>
                <Typography>No results found</Typography>
              </ListItem>
            )}
          </List>
        </Paper>
      )}
    </Box>

            </Box>
          <Box sx={{
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
            width: '100%', height: '100%',
          }}>
            {loading ? (
                            <Box sx={{ width: '70%', textAlign: 'center', mt: 2 ,}}>  
                            <Typography > Loading... </Typography>
                            </Box>
                        ) :
           serviceProviders.length === 0 ? (
              <Box sx={{
                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                width: '100%', height: '100%',paddingTop:'10%', gap:'24px'}}>
              <img src={Oops} alt="No service" style={{ width: '56px', height: '56px' }} />
              <Typography >No service providers found for {tabs[value]} status</Typography>
              </Box>
            ) : (

              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableBody>
                    {console.log(paginatedServiceProviders)}
                  {paginatedServiceProviders.map(({ serviceProvider, teamMemberName }, index) => (
                      <StyledTableRow
                      key={serviceProvider.service_provider_id}
               
                sx={{
                  backgroundColor: selectedProviderId === serviceProvider.service_provider_id ? '#f0f0f0' : 'transparent'
                }}
                    >
                        <StyledTableCell component="th" scope="row">
                          <Box sx={{
                            display: 'flex', flexDirection: 'row', gap: { md: '12px', sm: '16px', xs: '16px' },
                            width: '100%', alignItems: 'center', justifyContent: 'left', minWidth: '220px', maxWidth: '240px',
                            overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'
                          }}>
                            {serviceProvider.profile_image ? (
                              <Avatar alt="Profile Image" src={IMG_URL + serviceProvider.profile_image} sx={{
                                width: { md: '40px', sm: '40px', xs: '40px' },minWidth:'40px',
                                height: { md: '40px', sm: '40px', xs: '40px' }
                              }} />
                            ) : (
                              <Box
                                sx={{
                                  width: { md: '40px', sm: '40px', xs: '40px' },
                                  height: { md: '40px', sm: '40px', xs: '40px' },minWidth:'40px',
                                  borderRadius: '50%',
                                  backgroundColor: colors[index % colors.length],
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  fontSize: { xs: '24px', md: '26px' },
                                  fontWeight: 'bold',
                                  color: Colors.white,
                                  textAlign: 'center',
                                  lineHeight: '40px',
                                }}
                              >
                                {serviceProvider?.business_name ? serviceProvider.business_name[0].toUpperCase() : ''}
                              </Box>)}
                            <Box sx={{
                              width: 'calc(100% - 40px)', display: 'flex', flexDirection: 'column',
                              maxWidth: 'calc(100% - 48px)', alignItems: 'left', justifyContent: 'center',
                            }}>
                              <TypographyNormal sx={{
                                fontSize: '16px',
                                color: Colors.black_80,
                                whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
                              }}>
                                {serviceProvider.business_name}
                              </TypographyNormal>
                              <TypographyNormal sx={{
                                fontSize: '14px',
                                color: Colors.grey_60, 
                                overflow: 'hidden',  textOverflow: 'ellipsis',  whiteSpace: 'nowrap', textTransform: 'none',
                              }}>
                                {`${serviceProvider.service_details.map(detail => detail.professional_name).join(', ')}/${serviceProvider.city}`}
                              </TypographyNormal>
                              <TypographyNormal sx={{
                                fontSize: '14px',
                                color: Colors.grey_60,
                                whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
                              }}>
                                {`+${serviceProvider.country_code} ${serviceProvider.phone_number}`}
                              </TypographyNormal>

                            </Box>

                          </Box>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <span>{formatDateTime(serviceProvider.joined_date).split(' ')[0]}</span>
                            <span>
                              {formatDateTime(serviceProvider.joined_date).split(' ')[1]} {formatDateTime(serviceProvider.joined_date).split(' ')[2]}
                            </span>
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell>                        
                          <Box  onClick={value !== 2 && value !== 3 ? () => handleEditProfileButtonClick(serviceProvider.service_provider_id) : null}
                            sx={{ display: 'flex', alignItems: 'center', gap: '2px', cursor: 'pointer' }}>
                            <EditIcon style={{ color: Colors.primary, fontSize: '16px' }} /><TypographyNormal sx={{ fontSize: '16px' }}> Profile</TypographyNormal>
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Box onClick={value !== 2 && value !== 3 ? () => handleEditLocationButtonClick(serviceProvider.service_provider_id) : null}                        
                            sx={{ display: 'flex', alignItems: 'center', gap: '2px', cursor: 'pointer' }}>
                            <EditIcon style={{ color: Colors.primary, fontSize: '16px' }} /><TypographyNormal sx={{ fontSize: '16px' }}> Location</TypographyNormal>
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Box onClick={value !== 2 && value !== 3 ? () => handleEditServiceButtonClick(serviceProvider.service_provider_id) : null}                          
                            sx={{ display: 'flex', alignItems: 'center', gap: '2px', cursor: 'pointer' }}>
                            <EditIcon style={{ color: Colors.primary, fontSize: '16px' }} /><TypographyNormal sx={{ fontSize: '16px' }}>Service</TypographyNormal>
                          </Box>
                        </StyledTableCell>                       
                        <StyledTableCell>
                          <ButtonWhite sx={{ borderRadius: '4px', width:'fit-content' }} onClick={() => handleViewProfileClick(serviceProvider.service_provider_id, index)} >View Profile</ButtonWhite>
                        </StyledTableCell>
                        {serviceProvider && console.log(teamMemberName)}
                        <StyledTableCell>
                        {teamMemberName ? (
                          <TypographyNormal sx={{ fontSize: '16px' }}>
                            {teamMemberName}
                          </TypographyNormal>
                        ) : (
                          <TypographyNormal sx={{ fontSize: '16px' }}>
                            Not Assigned
                          </TypographyNormal>
                        )}
                      </StyledTableCell>

                        <StyledTableCell>
                        <TextfieldNormal
                          placeholder="Add comments"
                          sx={{ borderRadius: '10px' }}
                          value={comments[serviceProvider.service_provider_id] || ''}
                          onChange={(e) => handleCommentChange(e, serviceProvider.service_provider_id)}
                           onKeyDown={(e) => handleAddComment(e, serviceProvider.service_provider_id)}
                        />
                        </StyledTableCell>
                        <StyledTableCell>
                          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <span>{formatDateTime(serviceProvider.associate_status_time).split(' ')[0]}</span>
                            <span>
                              {formatDateTime(serviceProvider.associate_status_time).split(' ')[1]} {formatDateTime(serviceProvider.associate_status_time).split(' ')[2]}
                            </span>
                          </Box>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                component="div"
                count={serviceProviders.length} // Use full list length
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[7]} // Keeping rowsPerPage fixed as 7
              />
              </TableContainer>
           )}
            
          </Box>

         
        </Box>

      </Box>




      {isViewProfile && 
        <Box
          sx={{
            position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.6)',
            display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 2,}} >
          <Box sx={{
            width: '100%', height: '100%', gap: '5px',
            justifyContent: 'center', alignItems: 'center', position: 'fixed',
            display: 'flex', flexDirection: 'column',
          }}>
            <Box sx={{
              width: { md: '50%', sm: '50%', xs: '90%' }, height: '36px',
              justifyContent: 'right', alignItems: 'right', display: 'flex',
            }}>
              <IconButton onClick={handleCloseViewProfilePopUp} sx={{
                width: '36px', height: '36px',}} >                 
               <CloseOutlinedIcon sx={{
                                width: '36px', height: '36px', color: Colors.black_60, '&:hover': {
                                    width: '40px', height: '40px', color: Colors.black_60, backgroundColor: 'none',
                                    background: 'none'
                                },
                            }} ></CloseOutlinedIcon>
              </IconButton>
            </Box>
            <Box
              sx={{

                width: { md: '50%', sm: '50%', xs: '90%' },
                height: 'fit-content',
                backgroundColor: Colors.grey_20,
                border: '1px solid #E6E6E6',
                borderRadius: '4px ',
                boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
                boxSizing: 'border-box',
                zIndex: 3,
                padding: '35px 0px',
                alignItems: 'center',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: '11px',
                  height: '8px', // Added height for horizontal scrollbars
                  borderRadius: '16px',

              },
              '&::-webkit-scrollbar-thumb': {
                  backgroundColor: Colors.grey_30,
                  borderRadius: '16px',
                  border: '2px solid white', // Adds some padding around the thumb to create the appearance of rounded edges
              },
              '&::-webkit-scrollbar-track': {
                  borderRadius: '16px',
                  backgroundColor: Colors.white,
                  
              },
              }}
            >

              <Box sx={{
                width: '100%', height: '100%', display: 'flex', flexDirection: 'column',
               
              }}>
              <Box  sx={{width: '100%', height: '100%', justifyContent: 'right',
                      display: 'flex', alignItems: 'center',}} >
              
               
             {console.log(alignment)}
                 <ToggleButtonGroup
                  value={alignment}
                  exclusive
                  disabled={alignment === 'Accepted' }
                   onChange={(event, newAlignment) => handleSwitchChange(event, newAlignment)}
                                    sx={{
                                        backgroundColor: Colors.white,
                                        borderRadius: '4px',marginBottom:'4px',
                                        border:'1px solid' , marginRight:'24px',
                                        borderColor: Colors.hovering_blue,
                                        overflow: 'hidden',
                                        gap: '2px', // Added gap between buttons
                                        '& .MuiToggleButton-root': {
                                        border: 'none',
                                        padding: { xs: '5px 8px', md: '5px 10px' },
                                        textTransform: 'none',
                                        '&.Mui-selected': {
                                            backgroundColor: Colors.hovering_blue,
                                            color: Colors.white,
                                            borderRadius: '4px',
                                            '&:hover': {
                                            backgroundColor: Colors.hovering_blue, // Keep same background on hover
                                            color: Colors.white, // Keep same text color on hover
                                            }
                                        },        
                                        '&:not(.Mui-selected)': {
                                            backgroundColor: Colors.white,
                                            color: '#000',
                                            borderRadius: '4px',
                                            '&:hover': {
                                            backgroundColor: Colors.white, // Keep same background on hover
                                            color: '#000', // Keep same text color on hover
                                            }
                                        },
                                        '&:not(:first-of-type)': {
                                            marginLeft: '2px', // Adds a 2px gap between buttons
                                        },
                                        },
                                    }}   
                                    >
                                   {buttons.map((button) => (
                                  <ToggleButton key={button} value={button}>
                                    {button}
                                  </ToggleButton>
                                ))}
                                    </ToggleButtonGroup>
               
                </Box>
                <Box sx={{width: '100%', height: 'fit-content',justifyContent: 'center', alignItems: 'center', display:  'flex', padding:0,
                flexDirection: 'column',border:{ xs: '0px solid #E6E6E6', md: '1px solid  #E6E6E6' }, borderColor: Colors.grey_30, gap:'16px' }}>
                       
                                <Box sx={{width: '100%', height: '94%',justifyContent: 'center', alignItems: 'center', display:  'flex', 
                                flexDirection: 'column',paddingBottom:'3%',}}>
                                      <Box sx={{width: '100%', height: 'fit-content',justifyContent: 'center', 
                                      alignItems: 'center', display:  'flex', flexDirection: 'column',
                                      border:{ xs: '1px solid #E6E6E6', md: '0px solid' }, borderRadius:'4px', paddingBottom:'3%',}}>
                                                <Box sx={{width: '100%', height:'fit-content',justifyContent: 'center', alignItems: 'center', display:  'flex', 
                                                flexDirection: 'row',gap:'8px', background:'#E6E6E6', width:'100%' }}>  
                                                     
                                                <Box sx={{width: '100%', height:'fit-content',justifyContent: 'left', alignItems: 'center', display:  'flex', 
                                                flexDirection: 'row',gap:'8px', maxWidth: { xs:'90%', md:'95%' } , }}>  
                                                       
                                                        <Box sx={{ height: 'fit-content', width:{ xs: '40px', md: '80px' } , justifyContent: 'center', alignItems: 'center',
                                                            display:  'flex', flexDirection: 'row', }}>
                                                            <Box >  
                                                            {console.log(serviceProviderDetails)}                                                                                        
                                                                {serviceProviderDetails.profile_image ? (                                                                    
                                                                    <Avatar alt="Profile"                                                                     
                                                                        src={IMG_URL + serviceProviderDetails.profile_image}
                                                                        sx={{ width: { xs: '40px', md: '80px' }, height: { xs: '40px', md: '80px' },  }}/>
                                                                        ) : (
                                                                        <Avatar alt="Profile"
                                                                            sx={{
                                                                            ...stringAvatar(serviceProviderDetails.business_name, selectedIndex).sx,
                                                                            width: { xs: '40px', md: '80px' }, height:{ xs: '40px', md: '80px' }, fontSize: { xs: '32px', md: '58px'},}}>
                                                                            {stringAvatar(serviceProviderDetails.business_name, selectedIndex).children}
                                                                        </Avatar>
                                                                )}                                    
                                                                
                                                            </Box>

                                                        </Box>
                                                        <Box sx={{height: 'fit-content', width: { xs:'calc(100% - 40px)', md:'calc(100% - 80px)'}, justifyContent: 'center', 
                                                        alignItems: 'left',display:  'flex', flexDirection: 'column',gap:'8px',
                                                       }}> 

                                                                                               
                                                    <Box sx={{width: '100%', height: 'fit-content',justifyContent: 'center', alignItems: 'left', display:  'flex', 
                                                        flexDirection: 'column', gap:'4px', }}>
                                                            
                                                          <TypographySubHeading>{serviceProviderDetails.business_name}</TypographySubHeading>
                                                               
                                                            <TypographyNormal sx={{fontSize:{ xs:'14px', md:'16px'},
                                                            color: Colors.black_80,whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',}}> {serviceProviderDetails.city}, {serviceProviderDetails.province}, {serviceProviderDetails.country}</TypographyNormal>
                                                        </Box>
                                                    </Box> 
                                                </Box>
                                                </Box>
                                                <Box sx={{width: '100%', height: 'fit-content',justifyContent: 'left', alignItems: 'flex-start', display:  'flex', 
                                                flexDirection: 'row',gap:'32px', maxWidth: { xs:'90%', md:'95%' } , }}>  
                                                        <Box sx={{ height: 'fit-content', width:{ xs: '40px', md: '80px' } , 
                                                        justifyContent: 'flex-end', alignItems: 'center',
                                                            display:  'flex', flexDirection: 'column',paddingTop:'4px'}}>
                                                            <img src={qrCodeUrl} alt="qrcode" style={{ width: '56px', height: '56px' }} />                                                   
                                                            <TypographyNormal sx={{ marginTop: 'auto' }}>{serviceProviderDetails.service_provider_code}</TypographyNormal>
                                                        
                                                        </Box>
                                                        <Box sx={{  height: 'fit-content', width: 'calc(100% - 80px)',justifyContent: 'center', 
                                                        alignItems: 'left',display:  'flex', flexDirection: 'column',gap:'8px',
                                                    }}> 
                                                            <Box sx={{
                                                                        width: '100%', height: 'fit-content', justifyContent: 'space-between',
                                                                        alignItems: 'flex-start', display:  'flex' ,
                                                                        flexDirection: 'column', gap: '8px', 
                                                                        }}>
                                                                    <Box
                                                                        sx={{ width: '100%', height: 'fit-content', justifyContent: 'flex-start',
                                                                        alignItems: 'flex-start', display: 'flex', flexDirection:{ xs: 'column', md: 'row'} ,
                                                                        flexWrap: 'wrap',gap: '8px', paddingTop:'8px'}}>
                                                                        {[
                                                                        { label: 'Contact', value: serviceProviderDetails.contact_person },
                                                                        { label: 'Phone number', value: `+${serviceProviderDetails.country_code} ${serviceProviderDetails.phone_number}` },
                                                                        { label: 'Language', value: serviceProviderDetails.languages },
                                                                        { label: 'Email id', value: serviceProviderDetails.email },
                                                                        { label: 'Website', value: serviceProviderDetails.website }
                                                                        ]
                                                                        .filter(item => item.value)
                                                                        .map((item, index, arr) => (
                                                                            <Box
                                                                            key={index}
                                                                            sx={{
                                                                                display: 'flex', alignItems: 'center', gap: '12px', flexDirection: 'row',
                                                                                width: arr.length === 1 ? '100%' : { xs: '100%', md: '48%' },
                                                                                justifyContent: 'flex-start'}}>
                                                                            <TypographyNormal sx={{ minWidth: '12ch', fontWeight: 500 }}>{item.label}</TypographyNormal>
                                                                            <TypographyNormal>:</TypographyNormal>
                                                                            <TypographyNormal
                                                                                sx={{ maxWidth: '95%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                                                {item.value}
                                                                            </TypographyNormal>
                                                                            </Box>
                                                                        ))}
                                                                    </Box>
                                                            </Box>
                                                        </Box> 
                                                        
                                                </Box> 
                                         </Box>

                                         <Box sx={{width: '100%', height: '66%',justifyContent: 'center', alignItems: 'center', display:  'flex', 
                                           flexDirection: 'column',gap:'0px' , }}>
                                                <Box sx={{width: '100%', height: 'fit-content',justifyContent: 'center', alignItems: 'center', display:  'flex', 
                                                flexDirection: 'column', border:'1px solid', borderColor: Colors.grey_30,
                                                borderLeft:{ xs: '1px solid #E6E6E6', md: '0px solid' }, borderRight:{ xs: '1px solid #E6E6E6', md: '0px solid' },
                                                borderRadius:{ xs: '4px', md: '0px' },maxWidth: { xs: '100%', md: '98%' }}}>
                                                        <Box sx={{width: '100%', height: 'fit-content',justifyContent: 'center', alignItems: 'left', display:  'flex', 
                                                            flexDirection: 'column', maxWidth: { xs:'90%', md:'95%' }, gap:  '4px' , paddingTop:'1.5%', paddingBottom:'1.5%'}}>
                                                                
                                                                
                                                                        <TypographyNormal sx={{fontSize:{ xs: '14px', md: '18px' }, fontWeight:500}}>About</TypographyNormal>
                                                                    
                                                                        <TypographyNormal>{serviceProviderDetails.about_us}</TypographyNormal>
                                                                   
                                                                
                                                        </Box>
                                               </Box>
                                          

                                          <Box sx={{width: '100%', height: 'fit-content',justifyContent: 'center', alignItems: 'center', display:  'flex', 
                                           flexDirection: 'column',borderRadius:'4px',paddingTop:'1.5%',
                                           border:{ xs: '1px solid #E6E6E6', md: '0px solid  #E6E6E6' }, }}>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', 
                                                maxWidth: { xs:'90%', md:'95%' } , overflowX: 'auto', }} >
                                                <Tabs value={selectedTab}
                                                    onChange={handleTabChange}  
                                                    scrollButtons="auto"
                                                    variant="scrollable"                                     
                                                    TabIndicatorProps={{ style: { display: 'none' } }}
                                                    sx={{gap: '0px', '& .MuiTab-root': {  
                                                            padding: '0px',
                                                            minWidth: 'unset !important',  
                                                        }}}>                                       
                                                    {serviceTabName.map((service, index) => (
                                                        <Tab
                                                            key={index}
                                                            label={
                                                                <TypographyNormal sx={{
                                                                        fontFamily: 'Roboto',minWidth:'80px', color:selectedTab === index ? Colors.white : Colors.black_80,
                                                                        cursor: 'pointer', width: 'fit-content', overflow: 'hidden',textOverflow: 'ellipsis', textAlign: 'center',
                                                                        whiteSpace: 'nowrap', border: '1px solid', borderColor:  selectedTab === index ? Colors.secondary_blue : Colors.grey_30,
                                                                        padding: selectedTab === index ? '6px 6px 4px 6px' : '4px 6px', borderRadius: selectedTab === index ? '4px 4px 0px 0px' : '0px',
                                                                        textTransform: 'none', backgroundColor: selectedTab === index ? Colors.secondary_blue : 'transparent', marginBottom: selectedTab === index ? '4px' : '0px',
                                                                    }}>
                                                                {service}
                                                                </TypographyNormal>
                                                            }
                                                        />
                                                    ))}
                                                </Tabs>


                                            </Box>

                                            <Box sx={{width: '100%', height: 'fit-content',justifyContent: 'center', alignItems: 'left', display:  'flex', 
                                                    flexDirection: 'column', gap:'4px', maxWidth: { xs:'90%', md:'95%' } ,}}>
                                                        <Box sx={{width: '100%', height: 'fit-content',justifyContent: 'center', alignItems: 'left', display:  'flex', 
                                                        flexDirection: 'column',gap:'4px'}}>
                                                            <TypographyNormal sx={{fontSize:{ xs: '14px', md: '16px' }, fontWeight:500}}>Type of services</TypographyNormal>
                                                            {selectedServiceData?.type_details && 
                                                                    <TypographyNormal>
                                                                      {selectedServiceData.type_details.map(item => item.types).join(', ')}
                                                                    </TypographyNormal>                                                                  }
                                                        </Box>
                                                        
                                                        <Box sx={{ width: '100%', height: 'fit-content',
                                                             justifyContent: 'center', alignItems: 'left', display: 'flex', 
                                                             flexDirection: 'column', gap: '4px' }}>
                                                            {console.log(selectedServiceData)}
                                                            {selectedServiceData &&  (
                                                            [
                                                                { label: 'License No', value: selectedServiceData.license },
                                                                { label: 'Office address', value: selectedServiceData.office_address },
                                                                { label: 'Certifications', value: selectedServiceData.certifications },
                                                                { label: 'Franchise', value: selectedServiceData.franchise }
                                                            ]
                                                                .filter(item => item.value) // Filter out items with no value
                                                                .reduce((rows, item, index) => {
                                                                    // Push items into rows, grouping them in pairs
                                                                    if (index % 2 === 0) rows.push([]);
                                                                    rows[rows.length - 1].push(item);
                                                                    return rows;
                                                                }, [])
                                                                .map((row, rowIndex) => (
                                                                    <Box
                                                                        key={rowIndex}
                                                                        sx={{
                                                                            width: '100%',
                                                                            height: 'fit-content',
                                                                            justifyContent: 'center',
                                                                            alignItems: 'left',
                                                                            display: 'flex',
                                                                            flexDirection: {xs: 'column', md: 'row' },
                                                                            gap: '8px'
                                                                        }}>
                                                                        {row.map((item, itemIndex) => (
                                                                            <Box
                                                                                key={itemIndex}
                                                                                sx={{
                                                                                    width: row.length === 1 ? '100%' : '50%', // Full width if only one item in the row
                                                                                    height: 'fit-content',
                                                                                    justifyContent: 'center',
                                                                                    alignItems: 'left',
                                                                                    display: 'flex',
                                                                                    flexDirection: 'column', gap:'4px'
                                                                                }}>
                                                                                <TypographyNormal sx={{fontSize:{ xs: '14px', md: '16px' }, fontWeight:500}}>{item.label}</TypographyNormal>
                                                                                <TypographyNormal>{item.value}</TypographyNormal>
                                                                            </Box>
                                                                        ))}
                                                                    </Box>
                                                                )))}
                                                        </Box>
                                                        {selectedServiceData && selectedServiceData.image_details && selectedServiceData.image_details.length > 0 && (
                                                        <Box sx={{height: '100%', width: 'calc(100% - 140px)', height: '100%',justifyContent: 'center', 
                                                        alignItems: 'left',display:  'flex', flexDirection: 'column',gap:'8px',}}> 
                                                <TypographyNormal sx={{ fontSize: { xs: '14px', md: '16px' }, fontWeight: 600 }}>
                                                Projects
                                                </TypographyNormal>
                                               
                                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', flexWrap: 'wrap' }}>                                                        
                                                        {selectedServiceData.image_details.map((image, index) => (
                                                            <Box key={index} sx={{ width: '62px', height: '62px' }}>  
                                                              { console.log(IMG_URL + image.image_name)}
                                                              { console.log(index)}                                                            
                                                                <img
                                                                    src={IMG_URL + image.image_name}
                                                                    alt="Projects"
                                                                    style={{
                                                                        width: '100%', height: '100%',objectFit: 'cover',
                                                                        borderRadius: '4px', border: '1px solid',
                                                                        borderColor: Colors.black_60, cursor:'pointer'
                                                                    }}
                                                                   
                                                                />
                                                            </Box>
                                                        ))}
                                                    </Box>
                                               
                                                </Box>
                                                      )}
                                                        <TypographyNormal sx={{textAlign:'center', width:'100%', paddingTop:'32px'}}>Joined by {formatDateTime(serviceProviderDetails.joined_date).split(' ')[0]}</TypographyNormal>

                                            </Box>
                                          </Box>
                                          </Box>
                                </Box>  
                         </Box>
                       
               
              </Box>








            </Box>
          </Box>
        </Box>
      }

    </Grid>
  )
} export default AlphaAssociateLanding