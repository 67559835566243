import React, { useEffect, useState, useRef } from 'react';
import Colors from "./Colors";
import { Grid, Box, Avatar, Tab, Tabs, IconButton, InputBase, Rating, Checkbox, TextField } from "@mui/material";
import TypographyNormal from "./TypographyNormal";
import { IMG_URL } from '../Components/Constants';
import axios from "axios";
import { API_URL } from '../Components/Constants';
import TypographySubHeading from './TypographySubHeading';
import ButtonWhite from './ButtonWhite';
import ButtonBlue from './ButtonBlue';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { FRONTEND_URL } from '../Components/Constants';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TypographyError from './TypographyError';
import OTPInput from './OTPInput';
import TextfieldNormal from './TextfieldNormal';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LinearProgress from '@mui/material/LinearProgress';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AssociateResetPassword from './AssociatePassword';
import popupbg from '../Images/popup.png'
import whitelogo from '../Images/whitelogo.png'
import CircleIcon from '@mui/icons-material/Circle';
import logo from '../Images/logo.svg'
import share from '../Images/share.svg'
import rating from '../Images/rating.svg'
import message from '../Images/message.svg'



function ProfileComponent(props) {
    const { selectedSpId, setIsRatingAdd } = props
    console.log(selectedSpId)
    const ratingValue1 = 4.5;

    const serviceRef = useRef(null);
    const typeRef = useRef(null);
    const buttonRef = useRef(null);

    const serviceboxRef = useRef(null);
    const searchRef = useRef(null);

    const [serviceProviderDetails, setServiceProviderDetails] = useState([]);
    const [qrCodeUrl, setQrCodeUrl] = useState(null);
    const [serviceDetails, setServiceDetails] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [selectedTab, setSelectedTab] = useState(0);
    const [serviceTabName, setServiceTabName] = useState([]);
    const [selectedServiceData, setSelectedServiceData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isShare, setIsShare] = useState(false);
    const [isRateMe, setIsRateMe] = useState(false);
    const [ratingValue, setRatingValue] = useState(null);
    const [ratingValueError, setRatingValueError] = useState('');
    const [isMessage, setIsMessage] = useState(false);
    const [isotp, setIsotp] = useState(false);
    const [serviceOpen, setServiceOpen] = useState(false);
    const [selectedServices, setSelectedServices] = useState([]);
    const [serviceError, setServiceError] = useState('');
    const [isCheckedService, setIsCheckedService] = useState(false);
    const [selectedTypes, setSelectedTypes] = useState([]);
    const [typesGroupByService, setTypesGroupByService] = useState([]);
    const [typesError, setTypesError] = useState('');
    const [typeOpen, setTypeOpen] = useState(false);
    const [countryCode, setCountryCode] = useState("1");
    const [countryCodeError, setCountryCodeError] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [isTerm, setIsTerm] = useState(false);
    const [otp, setOtp] = useState(['', '', '', '']);
    const [otpError, setOtpError] = useState("");
    const [otpSuspendError, setOtpSuspendError] = useState("");
    const [isSuccessPopUp, setIsSuccessPopUp] = useState(false);
    const [clickCount, setClickCount] = useState(0);
    const [firstClickTime, setFirstClickTime] = useState(null);
    const [showServicePopUp, setShowServicePopUp] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isImagePopUp, setIsImagePopUp] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [disablePopUpAssossiate, setDisablePopUpAssossiate] = useState(false);
    const [isAcceptRejectClick, setIsAcceptRejectClick] = useState(false);
    const [otpAssossiate, setOtpAssossiate] = useState(['', '', '', '']);
    const [otpPopUpAssossiate, setOtpPopUpAssossiate] = useState(false);
    const [passwordAssossiate, setPasswordAssossiate] = useState(false);
    const [isOtpSuccess, setIsOtpSuccess] = useState(false);
    const [passwordError, setPasswordError] = useState("");
    const [isViewCard, setIsViewCard] = useState(false);
    const [termsDetails, setTermsDetails] = useState([]);
    const [isOtpComplete, setIsOtpComplete] = useState(false);

    const colors = ['#503259', '#4C50AE', '#FF7D6A', '#B07CC6', '#20C925'];

    function getAvatarColor(index) {
        return colors[index % colors.length];
    }

    function stringAvatar(name = '', index) {
        const initials = name[0]?.toUpperCase() || '';
        const bgColor = getAvatarColor(index);
        return {
            sx: {
                bgcolor: bgColor,
                width: '64px',
                height: '64px',
                fontSize: '56px',
                color: '#fff',
            },
            children: initials,
        };
    }

    const formatDateTime = (dateTimeString) => {
        const dateUTC = new Date(dateTimeString);

        // Check if dateUTC is valid
        if (isNaN(dateUTC.getTime())) {
            return 'Invalid Date'; // Return a default message for invalid dates
        }

        const timeOptions = {
            timeZone: 'America/Toronto',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        };

        const timeFormatter = new Intl.DateTimeFormat('en-CA', timeOptions);
        const formattedTime = timeFormatter.format(dateUTC);

        const dateInToronto = new Date(dateUTC.toLocaleString('en-US', { timeZone: 'America/Toronto' }));
        const day = String(dateInToronto.getDate()).padStart(2, '0');
       // const month = String(dateInToronto.getMonth() + 1).padStart(2, '0');
        const fullMonthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const month = fullMonthNames[dateInToronto.getMonth()];
        const year = dateInToronto.getFullYear();

        return `${month} ${day}, ${year}`;
    };



    let totalRatingSum     

    const calculateAverageRating = (ratingsData) => {
        if (!ratingsData || ratingsData.length === 0 || !ratingsData[0] || !ratingsData[0][0]) {           
            return 4;
        }
        const ratingCounts = ratingsData[0][0]; 
        totalRatingSum = 0;
        let totalRatingCount = 0;
    
        for (const [rating, count] of Object.entries(ratingCounts)) {
            totalRatingSum += rating * count;
            totalRatingCount += count;
        }
    
        const averageRating = totalRatingSum / totalRatingCount;
        const adjustedRating = averageRating < 4 ? 4 : averageRating;
        return adjustedRating % 1 === 0 ? adjustedRating.toFixed(0) : adjustedRating.toFixed(1);
    };




    const fetchData = async () => {
        try {
            const response = await axios.post(`${API_URL}/select_sp_details_with_spId`, {
                spId: selectedSpId
            });
            console.log(response.data.result.result.service_details[0].services_id)
            const serviceDetails = response.data.result.result.service_details;
             const serviceId = localStorage.getItem("serviceId") 
            ? parseInt(localStorage.getItem("serviceId")) 
            : response.data.result.result.service_details[0].services_id;
          

            setServiceProviderDetails(response.data.result.result);
            setServiceDetails(serviceDetails);
            setQrCodeUrl(response.data.result.qrCode)

            const tabNames = serviceDetails.map(item => item.professional_name);
            setServiceTabName(tabNames);

            const filteredServices = serviceDetails.filter(service => service.services_id === serviceId);
            setSelectedServiceData(filteredServices);

            const tabIndex = tabNames.findIndex(service => service === filteredServices[0].professional_name);
            setSelectedTab(tabIndex);

            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData();
    }, [selectedSpId, isRateMe]);


    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
        const selectedService = serviceDetails[newValue];
        setSelectedServiceData([selectedService]);
    };

    const handleShareClick = () => {
        setIsShare(true)
    }

    const handleCloseSharePopUp = () => {
        setIsShare(false)
    }

    const handleCopyButtonClick = async () => {
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(`${FRONTEND_URL}/sp-profile/${serviceProviderDetails.service_provider_code}`)
                .then(() => {
                    console.log('Link copied to clipboard');
                    setIsShare(false);
                })
                .catch((error) => {
                    console.error('Failed to copy:', error);
                    fallbackCopyTextToClipboard(`${FRONTEND_URL}/sp-profile/${serviceProviderDetails.service_provider_code}`);
                });

            try {
                const response = await axios.post(`${API_URL}/add_share_count`, {
                    spId: selectedSpId,
                });
            } catch (error) {
                console.error('Error fetching service providers:', error);
            }

        } else {
            setIsShare(false);
            console.warn('Clipboard API not supported, unable to copy text');
            fallbackCopyTextToClipboard(`${FRONTEND_URL}/sp-profile/${serviceProviderDetails.service_provider_code}`);
        }
    };

    const fallbackCopyTextToClipboard = (text) => {
        const textArea = document.createElement('textarea');
        textArea.value = text;

        // Avoid scrolling to the bottom of the page
        textArea.style.position = 'fixed';
        textArea.style.top = '0';
        textArea.style.left = '0';
        textArea.style.width = '2em';
        textArea.style.height = '2em';
        textArea.style.padding = '0';
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';
        textArea.style.background = 'transparent';

        document.body.appendChild(textArea);
        textArea.select();

        try {
            const successful = document.execCommand('copy');
            console.log('Fallback: Copying text command was ' + (successful ? 'successful' : 'unsuccessful'));
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
        }

        document.body.removeChild(textArea);
    };


    const handleRateMeClick = () => {
        setIsRateMe(true)
        setRatingValue(null)
        setRatingValueError('')
    }

    const handleCloseRateMePopUp = () => {
        setIsRateMe(false)
    }

    const handleRateSubmitButtonClick = async () => {

        if (ratingValue === null) {
            setRatingValueError('Please provide a rating.');
        }
        else {
            try {
                const response = await axios.post(`${API_URL}/insert_service_provider_rating`, {
                    ratingValue: ratingValue,
                    spId: selectedSpId,
                });
                console.log("response", response);
                if (response.data.success === true) {
                    setRatingValue(null)
                    setIsRateMe(false)
                    setIsRatingAdd(true)
                    try {
                        const responseaddrate = await axios.post(`${API_URL}/add_rate_count`, {
                            spId: selectedSpId,
                        });
                    } catch (error) {
                        console.error('Error fetching service providers:', error);
                    }

                }
                fetchData();
            }
            catch (error) {
                console.error('Error:', error);
            }

        }
    }

    const handleMessageClick = () => {
        setIsMessage(true)
        setSelectedServices([])
        setSelectedTypes([])
        setCountryCode("1")
        setPhoneNumber("")
        setServiceError("")
        setTypesError("")
        setCountryCodeError("")
        setPhoneNumberError("")
        setOtpSuspendError("")
        setSelectedServices((prevSelectedNames) => {
            if (selectedServiceData[0].services) {
                return [...prevSelectedNames, selectedServiceData[0].services];
            } else {
                return prevSelectedNames.filter((name) => name !== selectedServiceData[0].services);
            }
        });

    }

    useEffect(() => {
        const filteredTypes = serviceDetails
            .filter(serviceDetail => selectedServices.includes(serviceDetail.services))
            .reduce((acc, serviceDetail) => {
                acc[serviceDetail.services] = serviceDetail.type_details;
                return acc;
            }, {});
        setTypesGroupByService(filteredTypes);
    }, [selectedServices, serviceDetails]);


    const handleCloseMessagePopUp = () => {
        setIsMessage(false)
    }

    const handleMessageSendButtonClick = async () => {
        console.log(countryCode.trim())
        if (!selectedServices || selectedServices.length === 0) {
            setServiceError("Please choose your service");
        }
        else if (!selectedTypes || selectedTypes.length === 0) {
            setTypesError("Please choose your types of service");
        }
        else if (countryCode.trim() === '' || countryCode.trim() === null) {
            setCountryCodeError("Please add your country code");
        }
        else if (!phoneNumber) {
            setPhoneNumberError("Please add your phone number");
        }
        else if (phoneNumber.trim() !== "" && phoneNumber.length !== 10) {
            setPhoneNumberError("Phone number must be 10 digits");
        }
        else {
            try {
                const response = await axios.post(`${API_URL}/otp_creation`,
                    {
                        countryCode: countryCode,
                        phone: phoneNumber,
                    });
                if (response.data.success === true) {
                    setIsotp(true)
                    setOtpError('');
                    setOtp(['', '', '', ''])
                    setIsMessage(false)
                }

                else {
                    console.log("an error occured");

                }
            }
            catch (error) {
                console.error("Request failed:", error);

                if (error.response) {
                    console.error("Server responded with status code:", error.response.status);
                    console.error("Response data:", error.response.data);

                    if (error.response.status === 403 && error.response.data.code === 60410) {
                        setOtpSuspendError("The destination number is blocked for 24 hours due to repeated attempts.");
                    }
                    else {
                        setOtpSuspendError("The destination number is blocked for 24 hours due to repeated attempts.");
                    }
                } else if (error.request) {
                    console.error("No response received:", error.request);
                    setOtpSuspendError("No response from server. Please check your network connection.");
                } else {
                    console.error("Error setting up request:", error.message);
                    setOtpSuspendError("An unexpected error occurred. Please try again later.");
                }
            }
        }
    }

    const handleTermsAndconditionsButtonClick = () => {
        setIsTerm(true)
        setIsMessage(false)
    }

    const handleCloseTermPopUp = () => {
        setIsTerm(false)
        setIsMessage(true)
    }

    const handleCloseOTPPopUp = () => {
        setIsotp(false)
    }

    const handleCloseSuccessPopUp = () => {
        setIsSuccessPopUp(false)
    }

    const handleToggleService = (e) => {
        e.stopPropagation();
        setServiceOpen(!serviceOpen)
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (serviceRef.current && !serviceRef.current.contains(event.target)) {
                setServiceOpen(false); // Hide the box when clicking outside
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [serviceRef, setServiceOpen]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (typeRef.current && !typeRef.current.contains(event.target)) {
                setTypeOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [typeRef, setTypeOpen]);


    const handleServiceCheckboxChange = (event, servicesId) => {
        console.log(event.target.checked)
        const serviceName = event.target.value;
        setServiceError("")
        setSelectedServices((prevSelected) =>
            event.target.checked
                ? [...prevSelected, serviceName]
                : prevSelected.filter((service) => service !== serviceName)
        );
        if (!event.target.checked) {
            setSelectedTypes((prevSelectedTypes) =>
                prevSelectedTypes.filter((item) => item.service !== serviceName)
            );
        }
    };

    const handleTypeCheckboxChange = (event, typeId, type, types_id, service) => {
        console.log(service);
        setTypesError("");

        setSelectedTypes((prevSelected) =>
            event.target.checked
                ? [...prevSelected, { type: type, service: service }]
                : prevSelected.filter((item) => item.type !== type)
        );
    };



    const handleToggleType = (e) => {
        e.stopPropagation();
        setTypeOpen(!typeOpen)

    };

    const handleCountryCodeChange = (event) => {
        setCountryCode(event.target.value);
        setCountryCodeError("");
    };


    const handleKeyDownCountryCode = (event) => {
        const key = event.key;
        if (!/^\d$/.test(key) && key !== 'Backspace' && key !== 'Delete' && key !== 'ArrowLeft' && key !== 'ArrowRight') {
            event.preventDefault();
        }
    };

    const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value);
        setPhoneNumberError("");
    };

    const handleKeyDownPhone = (event) => {
        const key = event.key;
        if (!/^\d$/.test(key) && key !== 'Backspace' && key !== 'Delete' && key !== 'ArrowLeft' && key !== 'ArrowRight') {
            event.preventDefault();
        }
    };

    const handleChange = (event, index) => {
        setOtpError("")
        const value = event.target.value;
        if (/^[0-9]?$/.test(value)) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);
            if (value && index < 3) {
                const nextSibling = document.getElementById(`otp-input-${index + 1}`);
                if (nextSibling) {
                    nextSibling.focus();
                }
            }
        }
    };

    const handleKeyDown = (event, index) => {
        const { key } = event;
        setOtpError("")
        if (key === 'Backspace') {
            if (otp[index] === '') {
                const prevSibling = document.getElementById(`otp-input-${index - 1}`);
                if (prevSibling) {
                    prevSibling.focus();
                }
            } else {
                const newOtp = [...otp];
                newOtp[index] = '';
                setOtp(newOtp);
            }
        }

        if (key === 'ArrowLeft' && index > 0) {
            const prevSibling = document.getElementById(`otp-input-${index - 1}`);
            if (prevSibling) {
                prevSibling.focus();
            }
        }

        if (key === 'ArrowRight' && index < 3) {
            const nextSibling = document.getElementById(`otp-input-${index + 1}`);
            if (nextSibling) {
                nextSibling.focus();
            }
        }
    };

    const handlePaste = (event) => {
        setOtpError("")
        const pastedData = event.clipboardData.getData('Text').trim();
        if (/^\d{4}$/.test(pastedData)) {
            const newOtp = pastedData.split('');
            setOtp(newOtp);
            const lastInputField = document.getElementById(`otp-input-3`);
            if (lastInputField) {
                lastInputField.focus();
            }
        }
    };

    const handleClipboardInput = (event, index) => {
        setOtpError("");
        const value = event.target.value;

        if (value.length > 1) {
            const digits = value.split('');
            const newOtp = [...otp];

            digits.forEach((digit, i) => {
                if (index + i < 4) {
                    newOtp[index + i] = digit;
                }
            });
            setOtp(newOtp);

            const lastInputIndex = Math.min(index + digits.length - 1, 3);
            const lastInputField = document.getElementById(`otp-input-${lastInputIndex}`);
            if (lastInputField) {
                lastInputField.focus();
            }
        }
    };

    useEffect(() => {
        const allEmpty = otp.every((digit) => digit === '');
        if (allEmpty) {
            const firstInput = document.getElementById('otp-input-0');
            if (firstInput) {
                firstInput.focus();
            }
        }
    }, [otp]);

    const sentMessage = async () => {
        const formattedSelectedTypes = selectedTypes.map((item) => {
            const typeName = item.type.trim();
            const serviceName = item.service.trim();
            return `${typeName} (${serviceName})`;
        }).join(', ');
        setShowServicePopUp(formattedSelectedTypes)

        try {
            const response = await axios.post(`${API_URL}/sent_message`, {
                types: formattedSelectedTypes,
                user_phone_number: phoneNumber,
                countryCode: countryCode,
                location: localStorage.getItem("Location"),
                spId: localStorage.getItem("SelectedSP"),
            });
            console.log(response)
            if (response.data.success === true) {
                setIsSuccessPopUp(true);
                setIsotp(false);
                try {
                    const response = await axios.post(`${API_URL}/add_message_count`, {
                        spId: selectedSpId,
                    });
                } catch (error) {
                    console.error('Error fetching service providers:', error);
                }
            }

        } catch (error) {
            console.error('Error during message sending:', error.message);
        }
    }

    const handleOTPVerifyButtonClick = async () => {
        if (otp.some((digit) => digit.length === 0)) {
            setOtpError('All OTP fields must be filled.');
        } else {
            setOtpError('');
            try {
                const response = await axios.post(`${API_URL}/otp_verification`, {
                    countryCode: countryCode,
                    phone: phoneNumber,
                    otp: otp,
                });

                console.log("Verify response", response);

                if (response.data.success === true) {
                    console.log("OTP verification success");
                    sentMessage()
                } else {
                    setOtpError(response.data.message);
                }
            } catch (error) {
                if (error.response && error.response.status === 400) {
                    setOtpError('Invalid verification code. Please try again.');
                } else {
                    setOtpError('An unexpected error occurred. Please try again later.');
                }
                console.error('Error:', error.message);
            }
        }
    };

    const handleReSendOtpClick = async () => {

        setOtp(['', '', '', ''])
        setOtpError("")
        const now = new Date();

        console.log(firstClickTime)
        if (firstClickTime === null) {
            setFirstClickTime(now);
        }
        console.log(firstClickTime)
        const diffMinutes = (now - firstClickTime) / (1000 * 60);
        console.log("First Click Time:", firstClickTime);
        console.log("Now:", now);
        console.log("Difference in minutes:", diffMinutes);

        if (diffMinutes < 1) {
            console.log("diffMinutes")
            setClickCount(prevCount => prevCount + 1);
        } else {
            console.log("diffMinutes1")
            setClickCount(1);
            setFirstClickTime(now);
        }
        console.log(clickCount)
        if (clickCount === 2) {
            setOtpError('Warning: You have only two attempts left. Exceeding this limit may block OTP requests for 24 hours.');
            return;
        }
        else if (clickCount === 4) {
            setOtpError('You have clicked too many times. Please try again later.');
            return;
        }
        else {
            try {
                const response = await axios.post(`${API_URL}/otp_creation`,
                    {
                        countryCode: countryCode,
                        phone: phoneNumber,
                    });

                if (response.data.success === true) {
                    //  setOtpPopUp(true)
                    // setOpenQuote(false)
                }
                else {
                    console.log("an error occured");

                }
            }
            catch (error) {
                console.error("Request failed:", error);
                if (error.response) {
                    console.error("Server responded with status code:", error.response.status);
                    console.error("Response data:", error.response.data);
                } else if (error.request) {
                    console.error("No response received:", error.request);
                } else {
                    console.error("Error setting up request:", error.message);
                }
            }
        }

    }

    const handleImageClick = (image, index) => {
        setSelectedImage(IMG_URL + image);
        setCurrentImageIndex(index)
        setIsImagePopUp(true);
    };

    const handleImagePopUpClick = (image, index) => {
        setSelectedImage(IMG_URL + image);
        setCurrentImageIndex(index)       
    };
    

    const handleCloseImagePopUp = () => {
        setIsImagePopUp(false);
    };



    const handlePreviousImage = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === 0 ? selectedServiceData[0].image_details.length - 1 : prevIndex - 1
        );
    };

    const handleNextImage = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === selectedServiceData[0].image_details.length - 1 ? 0 : prevIndex + 1
        );
    };

    const handleDisableClick = async (phoneno, countryCodeno) => {
        setDisablePopUpAssossiate(true)
    }

    const handleCancelDisablePopup = () => {
        setDisablePopUpAssossiate(false)
    }

    const handleYesDisablePopup = async () => {

        try {
            const SpId = serviceProviderDetails.service_provider_id;

            const response = await axios.post(`${API_URL}/disable_assossiate_status`, { SpId });
            console.log("response message", response.data)
            if (response.data.success === true) {
                setDisablePopUpAssossiate(false)
                console.log("response message", response.data)
                window.location.href = '/'
                setIsAcceptRejectClick(true)
            }
            if (response.data.success === false) {
                setPasswordError(response.data.message)
            }

        }
        catch (error) {
            console.log('Error updating password. Please try again later.');
        }

    };

    const handleEnableClick = async (phoneno, countryCodeno, spid) => {
        console.log(phoneno, countryCodeno, spid)
        let AssosiatePasswordData = {
            phoneNumber: phoneno,
            countryCode: countryCodeno,
            spid: spid
        };
        localStorage.setItem('AssosiateSetPassword', JSON.stringify(AssosiatePasswordData));

        try {
            const otpresponse = await axios.post(`${API_URL}/otp_creation`,
                {
                    countryCode: countryCodeno,
                    phone: phoneno,
                });
            if (otpresponse.data.success === true) {
                setOtpPopUpAssossiate(true)
            }
        }
        catch (error) {
            console.error("Request failed:", error);
        }

    };

    const handleCloseVerifyOtpPopUp = () => {
        setOtpPopUpAssossiate(false)
    }

    useEffect(() => {
        console.log(isOtpSuccess)
        if (isOtpSuccess) {
            setOtpPopUpAssossiate(false)
            setPasswordAssossiate(true)
        }
    }, [isOtpSuccess]);

    const getStatusColor = (status) => {
        switch (status) {
            case 'Available':
                return {
                    iconColor: '#0DCA64',    // Green for Available icon
                    bgColor: '#C7FFC3'       // Light Green for Available background
                };
            case 'Busy':
                return {
                    iconColor: '#FFA800',    // Orange for Busy icon
                    bgColor: '#FFE8CC'       // Light Orange for Busy background
                };
            case 'Offline':
                return {
                    iconColor: '#EF2F22',    // Red for Offline icon
                    bgColor: '#FFB5A5'       // Light Red for Offline background
                };
            default:
                return {
                    iconColor: '#0DCA64',    // Default Green for icon
                    bgColor: '#C7FFC3'       // Default Light Green for background
                };
        }
    };

    const maskPhoneNumber = (phoneNumber) => {
        if (!phoneNumber || phoneNumber.length < 3) {
            return phoneNumber;
        }
        const visibleDigits = phoneNumber.slice(0, 3);
        const maskedDigits = 'X'.repeat(phoneNumber.length - 3);
        return `${visibleDigits}${maskedDigits}`;
    };

    const handleViewProfileClick = () => {
        setIsViewCard(true)
    }

    const handleCloseViewCardPopUp = () => {
        setIsViewCard(false)
    }

    useEffect(() => {
        const fetchTermseData = async () => {
            try {
                const response = await axios.post(`${API_URL}/select_terms`, {
                    title: 'message'
                });
                console.log(response.data.result)
                setTermsDetails(response.data.result);
            } catch (error) {
                console.error('Error fetching data:', error);
                setTermsDetails('Failed to load Terms and condition. Please try again later.');
            }
        };
        fetchTermseData();

    }, []);

    const isAnyPopupOpen = isShare || isRateMe || isMessage || isTerm || isotp || isSuccessPopUp || isImagePopUp || disablePopUpAssossiate || otpPopUpAssossiate || passwordAssossiate || isViewCard;

    useEffect(() => {
        if (isAnyPopupOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
       
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isAnyPopupOpen]);


    return (
        <Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px' }}>
            <Box sx={{
                width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex',
                flexDirection: 'row', paddingTop: '4.5%', paddingBottom: '5%'
            }}>

                {loading ? (
                    <Box sx={{ width: { xs: '70%', md: '30%' }, textAlign: 'center', mt: 2, paddingTop: '10%' }}>

                        <TypographySubHeading sx={{ mt: 2 }}>
                            Loading...
                        </TypographySubHeading>
                    </Box>
                ) : (
                    selectedServiceData.length > 0 ? (
                        <Box sx={{
                            width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex',
                            flexDirection: 'column', border: { xs: '0px solid #E6E6E6', md: '1px solid  #E6E6E6' }, 
                            borderColor: Colors.grey_30, gap: '16px', borderRadius:'4px'
                        }}>
                            <Box sx={{
                                width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex',
                                flexDirection: 'column', background: { xs: 'linear-gradient(to bottom, #E6E6E6 60px, white 5%)', md: 'linear-gradient(to bottom, #E6E6E6 120px, white 10%)' }, gap: '16px',
                            }}>
                                <Box sx={{
                                    width: '100%', height: '94%', justifyContent: 'center', alignItems: 'center', display: 'flex',
                                    flexDirection: 'column', gap: { xs: '8px', md: '24px' }, paddingBottom: '3%',
                                }}>
                                    <Box sx={{
                                        width: '100%', height: 'fit-content', justifyContent: 'center', paddingTop: '3%',
                                        alignItems: 'center', display: 'flex', flexDirection: 'column', paddingBottom: { xs: '16px', md: '0px' },
                                        border: { xs: '1px solid #E6E6E6', md: '0px solid' }, borderRadius: '4px',
                                    }}>
                                        <Box sx={{
                                            width: '100%', height: '100%', justifyContent: 'left', alignItems: 'flex-start', display: 'flex',
                                            flexDirection: 'row', gap:{ xs: '8px', md: '32px' }, maxWidth: { xs: '90%', md: '95%' },
                                        }}>
                                            <Box sx={{
                                                height: '100%', width: { xs: '70px', md: '140px' }, justifyContent: 'center', alignItems: 'flex-start',
                                                display: 'flex', flexDirection: 'row',
                                            }}>
                                                <Box sx={{ position: 'relative', display: 'inline-block', }}>
                                                    {console.log(IMG_URL + serviceProviderDetails.profile_image)}
                                                    {serviceProviderDetails.profile_image ? (
                                                        <Avatar alt="Profile"
                                                            src={IMG_URL + serviceProviderDetails.profile_image}
                                                            sx={{ width: { xs: '40px', md: '140px' }, height: { xs: '40px', md: '140px' } }} />
                                                    ) : (
                                                        <Avatar alt="Profile"
                                                            sx={{
                                                                ...stringAvatar(serviceProviderDetails.business_name, selectedIndex).sx,
                                                                width: { xs: '40px', md: '140px' }, height: { xs: '40px', md: '140px' }, fontSize: { xs: '28px', md: '58px' },
                                                            }}>
                                                            {stringAvatar(serviceProviderDetails.business_name, selectedIndex).children}
                                                        </Avatar>
                                                    )}
                                                    {console.log(serviceProviderDetails.rating_details)}
                                                    {serviceProviderDetails.rating_details.length > 0 &&
                                                        <Avatar sx={{
                                                            position: 'absolute', bottom: { xs: 0, md: 12 }, right: { xs: 0, md: 1 }, width: { xs: '16px', md: '36px' }, height: { xs: '16px', md: '36px' },
                                                            backgroundColor: Colors.primary_blue, color: 'white',
                                                        }} >
                                                            <TypographyNormal sx={{ color: Colors.white, fontSize: { xs: '8px', md: '14px' } }}>
                                                                {calculateAverageRating(serviceProviderDetails.rating_details)}
                                                            </TypographyNormal>
                                                        </Avatar>}
                                                </Box>

                                            </Box>
                                            <Box sx={{
                                                height: '100%', width: { xs: 'calc(100% - 60px)', md: 'calc(100% - 140px)' }, justifyContent: 'center',
                                                alignItems: 'left', display: 'flex', flexDirection: 'column', gap: '8px',
                                                marginTop: localStorage.getItem("SPCode") ? '-24px' : { xs: '0px', md: '16px' }
                                            }}>

                                                {localStorage.getItem("SPCode") &&
                                                    <Box sx={{
                                                        width: '100%', height: '100%', justifyContent: 'right', paddingTop: '16px',
                                                        alignItems: 'center', display: 'flex', flexDirection: 'row', gap: '16px',
                                                    }}>
                                                        <ButtonWhite onClick={() => handleDisableClick(serviceProviderDetails.phone_number, serviceProviderDetails.country_code)}
                                                            sx={{ width: 'fit-content', gap: '4px' }} disabled={isAcceptRejectClick}>Disable</ButtonWhite>
                                                        <ButtonBlue onClick={() => handleEnableClick(serviceProviderDetails.phone_number, serviceProviderDetails.country_code, serviceProviderDetails.service_provider_id)}

                                                            sx={{ width: 'fit-content', gap: { xs: '0px', md: '4px' } }} disabled={isAcceptRejectClick}>Enable</ButtonBlue>
                                                    </Box>}
                                                <Box sx={{
                                                    width: '100%', height: '100%', justifyContent: 'center', alignItems: 'left', display: 'flex',
                                                    flexDirection: 'column', gap: '4px',
                                                }}>
                                                    {localStorage.getItem("SPCode") ? (
                                                        <Box
                                                            sx={{
                                                                width: `${serviceProviderDetails.business_name.length * 10}px`, height: '16px',
                                                                justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'row',
                                                                background: Colors.black_60
                                                            }} />
                                                    ) : (<Box sx={{
                                                        display: 'flex',
                                                        width: '100%',
                                                        height: '100%',
                                                        flexDirection: serviceProviderDetails.business_name.length > '80%' ? 'column' : 'row',  // Switch between row/column based on name length
                                                        justifyContent: 'left',
                                                        alignItems: serviceProviderDetails.business_name.length > '80%' ? 'flex-start' : 'center',  // Align for long name or center for short name
                                                        gap: '16px'
                                                    }}>
                                                        <TypographySubHeading sx={{
                                                            whiteSpace: 'nowrap', fontSize:{xs:'16px', md:'24px'},
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            width: 'fit-content'  // Adjust width for long/short names
                                                        }}>
                                                            {serviceProviderDetails.business_name}
                                                        </TypographySubHeading>
                                                        <Box sx={{
                                                            display: 'flex', width: '49px', height: '16px', borderRadius: '16px',minWidth:'fit-content',
                                                            background: getStatusColor(serviceProviderDetails.profile_status).bgColor, justifyContent: 'flex-start',
                                                            alignItems: 'center', gap: '4px',paddingLeft:'3px'
                                                        }}>
                                                            <CircleIcon sx={{ fontSize: '8px',lineHeight:'10px', color: getStatusColor(serviceProviderDetails.profile_status).iconColor }}></CircleIcon>
                                                            <TypographyNormal sx={{ fontSize: '8px',lineHeight:'10px', }}>{serviceProviderDetails.profile_status}</TypographyNormal>
                                                        </Box></Box>
                                                    )}
                                                    <TypographyNormal sx={{
                                                        fontSize: { xs: '14px', md: '16px' },
                                                        color: Colors.black_80, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
                                                    }}> {serviceProviderDetails.city}, {serviceProviderDetails.province}, {serviceProviderDetails.country}</TypographyNormal>
                                                </Box>
                                            </Box>
                                        </Box>

                                        <Box sx={{
                                            width: '100%', height: '100%', justifyContent: 'left', alignItems: 'flex-start', display: 'flex',
                                            flexDirection: 'row', gap: { xs: '8px', md: '32px' }, maxWidth: { xs: '90%', md: '95%' },paddingTop: { xs: '5%', md: '4px' }
                                        }}>
                                            <Box sx={{
                                                height: '100%', width: { xs: '70px', md: '140px' },
                                                justifyContent: 'flex-end', alignItems: 'center',
                                                display: 'flex', flexDirection: 'column', 
                                            }}>
                                                <img src={qrCodeUrl} alt="Qr code" style={{ width: '60%', height: '60%' }} />
                                                <TypographyNormal sx={{ marginTop: 'auto' }}>{serviceProviderDetails.service_provider_code}</TypographyNormal>

                                            </Box>
                                            <Box sx={{
                                                height: '100%', width: 'calc(100% - 140px)', justifyContent: 'center',
                                                alignItems: 'left', display: 'flex', flexDirection: 'column', gap: '8px',
                                            }}>
                                                <Box sx={{
                                                    width: '100%', height: 'fit-content', justifyContent: 'space-between',
                                                    alignItems: 'flex-start', display: 'flex',
                                                    flexDirection: 'column', gap: '8px',
                                                    marginTop: localStorage.getItem("SPCode") ? '-24px' : { xs: '0px', md: '-36px' }
                                                }}>
                                                    <Box
                                                        sx={{
                                                            width: '100%', height: 'fit-content', justifyContent: 'flex-start',
                                                            alignItems: 'flex-start', display: 'flex', flexDirection: { xs: 'column', md: 'row' },
                                                            flexWrap: 'wrap', gap: {md:'8px', xs:'4px'}
                                                        }}>
                                                        {[
                                                            { label: 'Contact', value: serviceProviderDetails.contact_person }, 
                                                            { label: 'Website', value: serviceProviderDetails.website },                                                          
                                                            { label: 'Language', value: serviceProviderDetails.languages },
                                                            { label: 'Email id', value: serviceProviderDetails.email },                                                           
                                                            { label: 'Phone number', value: `+${serviceProviderDetails.country_code} ${maskPhoneNumber(serviceProviderDetails.phone_number)}` },
                                                        ]
                                                            .filter(item => item.value)
                                                            .map((item, index, arr) => (
                                                                <Box
                                                                    key={index}
                                                                    sx={{
                                                                        display: 'flex', alignItems: 'center', gap: {md:'12px', xs:'8px'}, flexDirection: 'row',
                                                                        width: arr.length === 1 ? '100%' : { xs: '70%', md: '48%' },
                                                                        justifyContent: 'flex-start',
                                                                    }}>
                                                                    <TypographyNormal sx={{ minWidth: '12ch', fontWeight: 500 }}>{item.label}</TypographyNormal>
                                                                    <TypographyNormal>:</TypographyNormal>
                                                                    {localStorage.getItem("SPCode") ? (
                                                                        <Box
                                                                            sx={{
                                                                                width: `${item.value.length * 10}px`,
                                                                                height: '16px',
                                                                                justifyContent: 'center',
                                                                                alignItems: 'center',
                                                                                display: 'flex',
                                                                                flexDirection: 'row',
                                                                                background: Colors.black_60,
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', width: '100%' ,minWidth:'100%',}}>
                                                                            <TypographyNormal
                                                                                sx={{
                                                                                    maxWidth:{ md: '98%', xs: '100%' },
                                                                                    whiteSpace: 'nowrap',
                                                                                    overflow: 'hidden',
                                                                                    textOverflow: 'ellipsis',
                                                                                }}
                                                                            >
                                                                                {item.value}
                                                                            </TypographyNormal>
                                                                            {item.label === 'Phone number' && (
                                                                                <ButtonWhite sx={{
                                                                                    width: { md: '84px', xs: '56px' },  
                                                                                    maxWidth: { md: '84px', xs: '56px'  },                  
                                                                                    minWidth: '50px', maxHeight: { md: '28px', xs: '24px' },                        // Ensures a minimum width if content is small
                                                                                    padding: { xs: '0px', md: '0px' },         // Adjust padding for responsiveness
                                                                                    display: 'flex', backgroundColor:'#ECF4FB',                          // Ensures content inside the button is flexible
                                                                                    justifyContent: 'center', border:'1px solid ##ECF4FB',
                                                                                    '&: hover':{border:'1px solid #5D5FEF',  backgroundColor:'#ECF4FB',}                 // Centers the button content
                                                                                }} onClick={handleViewProfileClick}>View</ButtonWhite>
                                                                            )}
                                                                        </Box>
                                                                    )}

                                                                </Box>
                                                            ))}
                                                    </Box>
                                                </Box>

                                                <Box sx={{
                                                    width: '100%', height: '100%', justifyContent: 'left', alignItems: 'left', display: 'flex',
                                                    flexDirection: 'row', gap: { xs: '4px', md: '16px' }, paddingTop: { xs: '8px', md: '16px' }
                                                }}>
                                                    {!localStorage.getItem("SPCode") && (
                                                        <>
                                                            <ButtonWhite sx={{ width: 'fit-content', gap: { xs: '2px', md: '4px' }, height:{ xs: '24px', md: '32px' } , padding: { xs: '0px 4px', md: '0px 16px' }, }}
                                                                onClick={handleShareClick}><img src={share} alt="Share Icon" />Share</ButtonWhite>
                                                            {localStorage.getItem("userToken") && (
                                                                <ButtonWhite sx={{ width: 'fit-content', gap: { xs: '2px', md: '4px' }, height: { xs: '24px', md: '32px' }, padding: { xs: '0px 4px', md: '0px 16px' }, alignItems: 'center'}}
                                                                    onClick={handleRateMeClick}><img src={rating} alt="Rate Icon" style={{marginBottom:'2.5px'}} />Rate me</ButtonWhite>)}
                                                            <ButtonBlue sx={{ width: 'fit-content', gap: { xs: '2px', md: '4px' }, height: { xs: '24px', md: '32px' }, padding: { xs: '0px 4px', md: '0px 16px' }, }}
                                                                onClick={handleMessageClick}><img src={message} alt="Message Icon" />Message</ButtonBlue>
                                                        </>)}
                                                </Box>
                                            </Box>

                                        </Box>
                                    </Box>

                                    <Box sx={{
                                        width: '100%', height: '66%', justifyContent: 'center', alignItems: 'center', display: 'flex',
                                        flexDirection: 'column', gap: '16px',
                                    }}>
                                        <Box sx={{
                                            width: '100%', height: 'fit-content', justifyContent: 'center', alignItems: 'center', display: 'flex',
                                            flexDirection: 'column', border: '1px solid', borderColor: Colors.grey_30, paddingTop: '2.5%',
                                            borderLeft: { xs: '1px solid #E6E6E6', md: '0px solid' }, borderRight: { xs: '1px solid #E6E6E6', md: '0px solid' },
                                            paddingBottom: '2.5%', borderRadius: { xs: '4px', md: '0px' }, maxWidth: { xs: '100%', md: '100%' }
                                        }}>
                                            <Box sx={{
                                                width: '100%', height: 'fit-content', justifyContent: 'center', alignItems: 'left', display: 'flex',
                                                flexDirection: 'column', maxWidth: { xs: '90%', md: '95%' }, gap: localStorage.getItem("SPCode") ? '8px' : '4px'
                                            }}>

                                                {localStorage.getItem("SPCode") ? (
                                                    <Box
                                                        sx={{
                                                            width: '20%', height: '16px', justifyContent: 'center',
                                                            alignItems: 'center', display: 'flex', flexDirection: 'row',
                                                            background: Colors.black_60
                                                        }} />
                                                ) : (
                                                    <TypographyNormal sx={{ fontSize: { xs: '14px', md: '18px' }, fontWeight: 500 }}>About</TypographyNormal>
                                                )}

                                                {localStorage.getItem("SPCode") ? (
                                                    <Box
                                                        sx={{
                                                            width: `${serviceProviderDetails.about_us.length * 10}px`, height: '16px', justifyContent: 'center',
                                                            alignItems: 'center', display: 'flex', flexDirection: 'row',
                                                            background: Colors.black_60
                                                        }} />
                                                ) : (
                                                    <TypographyNormal>{serviceProviderDetails.about_us}</TypographyNormal>
                                                )}

                                            </Box>
                                        </Box>
                                    </Box>

                                    <Box sx={{
                                        width: '100%', height: '95%', justifyContent: 'center', alignItems: 'center', display: 'flex',
                                        flexDirection: 'column', gap: '16px', borderRadius: '4px', paddingTop: {md:'1%', xs:'2.5%'}, paddingBottom: '2.5%',
                                        border: { xs: '1px solid #E6E6E6', md: '0px solid  #E6E6E6' },
                                    }}>
                                        <Box sx={{
                                            display: 'flex', flexDirection: 'row', width: '100%',
                                            maxWidth: { xs: '90%', md: '95%' }, overflowX: 'auto',
                                        }} >
                                            <Tabs value={selectedTab}
                                                onChange={handleTabChange}
                                                scrollButtons="auto"
                                                variant="scrollable"
                                                TabIndicatorProps={{ style: { display: 'none' } }}
                                                sx={{
                                                    gap: '0px', '& .MuiTab-root': {
                                                        padding: '0px',
                                                        minWidth: 'unset !important',
                                                    }
                                                }}>
                                                {serviceTabName.map((service, index) => (
                                                    <Tab
                                                        key={index}
                                                        label={
                                                            <TypographyNormal sx={{
                                                                fontFamily: 'Roboto', minWidth: '80px', color: selectedTab === index ? Colors.white : Colors.black_80,
                                                                cursor: 'pointer', width: 'fit-content', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center',
                                                                whiteSpace: 'nowrap', border: '1px solid', borderColor: selectedTab === index ? Colors.secondary_blue : Colors.grey_30,
                                                                padding: selectedTab === index ? '12px 12px 8px 12px' : '8px 12px', borderRadius: selectedTab === index ? '4px 4px 0px 0px' : '0px',
                                                                textTransform: 'none', backgroundColor: selectedTab === index ? Colors.secondary_blue : 'transparent', marginBottom: selectedTab === index ? '4px' : '0px',
                                                            }}>
                                                                {service}
                                                            </TypographyNormal>
                                                        }
                                                    />
                                                ))}
                                            </Tabs>


                                        </Box>

                                        <Box sx={{
                                            width: '100%', height: 'fit-content', justifyContent: 'center', alignItems: 'left', display: 'flex',
                                            flexDirection: 'column', gap: '16px', maxWidth: { xs: '90%', md: '95%' },
                                        }}>
                                            <Box sx={{
                                                width: '100%', height: 'fit-content', justifyContent: 'center', alignItems: 'left', display: 'flex',
                                                flexDirection: 'column', gap: '4px',
                                            }}>
                                                <TypographyNormal sx={{ fontSize: { xs: '14px', md: '16px' }, fontWeight: 500 }}>Type of services</TypographyNormal>
                                                {console.log(selectedServiceData)}
                                                {selectedServiceData[0].type_details && <TypographyNormal >{selectedServiceData[0].type_details.map(item => item.types).join(', ')}</TypographyNormal>}
                                            </Box>

                                            <Box sx={{
                                                width: '100%', height: 'fit-content',
                                                justifyContent: 'center', alignItems: 'left', display: 'flex',
                                                flexDirection: 'column', gap: '12px'
                                            }}>
                                                {[
                                                    { label: 'License No', value: selectedServiceData[0].license },
                                                    { label: 'Office address', value: selectedServiceData[0].office_address },
                                                    { label: 'Certifications', value: selectedServiceData[0].certifications },
                                                    { label: 'Franchise', value: selectedServiceData[0].franchise }
                                                ]
                                                    .filter(item => item.value) // Filter out items with no value
                                                    .reduce((rows, item, index) => {
                                                        // Push items into rows, grouping them in pairs
                                                        if (index % 2 === 0) rows.push([]);
                                                        rows[rows.length - 1].push(item);
                                                        return rows;
                                                    }, [])
                                                    .map((row, rowIndex) => (
                                                        <Box
                                                            key={rowIndex}
                                                            sx={{
                                                                width: '100%',
                                                                height: 'fit-content',
                                                                justifyContent: 'center',
                                                                alignItems: 'left',
                                                                display: 'flex',
                                                                flexDirection: { xs: 'column', md: 'row' },
                                                                gap: '8px'
                                                            }}>
                                                            {row.map((item, itemIndex) => (
                                                                <Box
                                                                    key={itemIndex}
                                                                    sx={{
                                                                        width: row.length === 1 ? '100%' : '50%', // Full width if only one item in the row
                                                                        height: 'fit-content',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'left',
                                                                        display: 'flex',
                                                                        flexDirection: 'column', gap: '4px'
                                                                    }}>
                                                                    <TypographyNormal sx={{ fontSize: { xs: '14px', md: '16px' }, fontWeight: 500 }}>{item.label}</TypographyNormal>
                                                                    <TypographyNormal>{item.value}</TypographyNormal>
                                                                </Box>
                                                            ))}
                                                        </Box>
                                                    ))}
                                            </Box>
                                            {selectedServiceData[0].image_details.length > 0 && (
                                                <Box sx={{
                                                    height: '100%', width: 'calc(100% )',  justifyContent: 'center',
                                                    alignItems: 'left', display: 'flex', flexDirection: 'column', gap: '8px',
                                                }}>
                                                    <TypographyNormal sx={{ fontSize: { xs: '14px', md: '16px' }, fontWeight: 600 }}>
                                                        Projects
                                                    </TypographyNormal>

                                                    {/* <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', flexWrap: 'wrap' }}>
                                                        {selectedServiceData[0].image_details.map((image, index) => (
                                                            <Box key={index} sx={{ width: { xs: '62px', md: '62px' }, height: '62px' }}>
                                                                {console.log(IMG_URL + image.image_name)}
                                                                {console.log(index)}
                                                                <img
                                                                    src={IMG_URL + image.image_name}
                                                                    alt="Projects"
                                                                    style={{
                                                                        width: '100%', height: '100%', objectFit: 'cover',
                                                                        borderRadius: '4px', border: '1px solid',
                                                                        borderColor: Colors.black_60, cursor: 'pointer'
                                                                    }}
                                                                    onClick={() => handleImageClick(image.image_name, index)}
                                                                />
                                                            </Box>
                                                        ))}
                                                    </Box> */}
                                                    <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        gap: '8px',
                                                        flexWrap: 'nowrap',  // Prevent wrapping to keep all items in a single row
                                                        overflowX: 'auto',   // Enable horizontal scroll if content overflows
                                                        paddingBottom: '8px', // Optional, adds space below the images for a better appearance
                                                        '&::-webkit-scrollbar': {
                                                        height: '4px', 
                                                        //display:'none'      // Set scrollbar height for horizontal scroll
                                                        },
                                                        '&::-webkit-scrollbar-thumb': {
                                                        backgroundColor: Colors.black_60,
                                                        borderRadius: '4px',
                                                        },
                                                        '&::-webkit-scrollbar-track': {
                                                        backgroundColor: Colors.white,
                                                        },
                                                    }}
                                                    >
                                                    {selectedServiceData[0].image_details.map((image, index) => (
                                                            <Box key={index} sx={{ width: { xs: '62px', md: '62px' }, height: '62px' }}>
                                                                {console.log(IMG_URL + image.image_name)}
                                                                {console.log(index)}
                                                                <img
                                                                    src={IMG_URL + image.image_name}
                                                                    alt="Projects"
                                                                    style={{
                                                                        width: '62px', height: '62px', objectFit: 'cover',
                                                                        borderRadius: '4px', border: '1px solid',
                                                                        borderColor: Colors.black_60, cursor: 'pointer'
                                                                    }}
                                                                    onClick={() => handleImageClick(image.image_name, index)}
                                                                />
                                                            </Box>
                                                        ))}
                                                    </Box>


                                                </Box>)}
                                            {console.log(serviceProviderDetails.joined_date)}
                                            <TypographyNormal sx={{ textAlign: 'center', width: '100%', paddingTop: '32px', fontSize:'8px' }}>Joined by {formatDateTime(serviceProviderDetails.joined_date)}</TypographyNormal>

                                        </Box>
                                    </Box>
                                </Box>
                            </Box>

                        </Box>
                    ) : (
                        <TypographyNormal>No data available</TypographyNormal>
                    )
                )}
            </Box>


            {isShare && <Box sx={{
                position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.6)',
                display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '2',

            }}>
                <Box sx={{
                    width: '100%', height: '100%', gap: '5px',
                    justifyContent: 'center', alignItems: 'center', position: 'fixed',
                    display: 'flex', flexDirection: 'column',
                }}>
                    <Box sx={{
                        width: { md: '35%', sm: '35%', xs: '85%' }, height: '36px',
                        justifyContent: 'right', alignItems: 'right', display: 'flex',
                    }}>
                        <IconButton onClick={handleCloseSharePopUp} sx={{
                            position: 'fixed', width: '36px', height: '36px',
                        }} >
                            <CloseOutlinedIcon sx={{
                                width: '36px', height: '36px', color: Colors.black_60, '&:hover': {
                                    width: '40px', height: '40px', color: Colors.black_60, backgroundColor: 'none',
                                    background: 'none'
                                },
                            }} ></CloseOutlinedIcon>
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            width: { md: '35%', sm: '35%', xs: '85%' }, height: 'fit-content',
                            backgroundColor: Colors.white, border: '1px solid',
                            borderColor: Colors.white, borderRadius: '4px ', boxShadow: 'none',
                            boxSizing: 'border-box', zIndex: '3', alignItems: 'center',
                        }}>

                        <Box sx={{
                            display: 'flex', flexDirection: 'column', width: '100%', gap: '16px',
                            justifyContent: 'center', alignItems: 'center', height: '100%',
                        }}>
                            <Box sx={{
                                display: 'flex', flexDirection: 'column', width: '100%', gap: '16px', paddingTop: '7%',
                                justifyContent: 'center', alignContent: 'center', height: '86%', maxWidth: '86%',
                                paddingBottom: '7%'
                            }}>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'column', width: '100%', gap: '8px',
                                    justifyContent: 'center', alignContent: 'center', height: '100%', alignItems: 'stretch',
                                }}>
                                    <TypographySubHeading sx={{ textAlign: 'left' }}>
                                        Social Share
                                    </TypographySubHeading>

                                    <TypographyNormal sx={{ textAlign: 'left', paddingTop: '8px' }}>
                                        Copy below link
                                    </TypographyNormal>

                                    <Box sx={{
                                        width: 'calc(100%-32px)', height: '28px', border: '1px solid', padding: '10px 16px 10px 16px',
                                        borderColor: Colors.black_60, borderRadius: '4px', display: 'flex', flexDirection: 'row',
                                    }}>
                                        <InputBase
                                            value={`${FRONTEND_URL}/sp-profile/${serviceProviderDetails.service_provider_code}`}
                                            style={{ flex: 1, border: 'none', outline: 'none', background: 'transparent' }}
                                            inputProps={{
                                                style: {
                                                    fontFamily: 'Roboto', fontSize: '16px',
                                                    '::placeholder': {
                                                        fontFamily: 'Roboto', fontSize: '16px',
                                                    },
                                                },
                                            }}
                                        />
                                    </Box>
                                    <Box sx={{
                                        width: 'calc(100%)', height: '100%', paddingTop: '8px',
                                        display: 'flex', flexDirection: 'row', justifyContent: 'right',
                                    }}
                                    >
                                        <ButtonBlue sx={{ width: 'fit-content', gap: '4px' }} onClick={handleCopyButtonClick}><ContentCopyIcon sx={{ fontSize: { xs: '12px', md: '18px' } }} />Copy link</ButtonBlue>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>}

            {isRateMe && <Box sx={{
                position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.6)',
                display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '2',

            }}>
                <Box sx={{
                    width: '100%', height: '100%', gap: '5px',
                    justifyContent: 'center', alignItems: 'center', position: 'fixed',
                    display: 'flex', flexDirection: 'column',
                }}>
                    <Box sx={{
                        width: { md: '35%', sm: '35%', xs: '85%' }, height: '36px',
                        justifyContent: 'right', alignItems: 'right', display: 'flex',
                    }}>
                        <IconButton onClick={handleCloseRateMePopUp} sx={{
                            position: 'fixed', width: '36px', height: '36px',
                        }} >
                            <CloseOutlinedIcon sx={{
                                width: '36px', height: '36px', color: Colors.black_60, '&:hover': {
                                    width: '40px', height: '40px', color: Colors.black_60, backgroundColor: 'none',
                                    background: 'none'
                                },
                            }} ></CloseOutlinedIcon>
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            width: { md: '35%', sm: '35%', xs: '85%' }, height: 'fit-content',
                            backgroundColor: Colors.white, border: '1px solid',
                            borderColor: Colors.white, borderRadius: '4px ', boxShadow: 'none',
                            boxSizing: 'border-box', zIndex: '3', alignItems: 'center',
                        }}>

                        <Box sx={{
                            display: 'flex', flexDirection: 'column', width: '100%', gap: '16px',
                            justifyContent: 'center', alignItems: 'center', height: '100%',
                        }}>
                            <Box sx={{
                                display: 'flex', flexDirection: 'column', width: '100%', gap: '16px', paddingTop: '10%',
                                justifyContent: 'center', alignItems: 'center', height: '80%', maxWidth: '86%',
                                paddingBottom: '10%'
                            }}>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'column', width: '100%', gap: '16px',
                                    justifyContent: 'center', alignItems: 'center', height: '100%',
                                }}>
                                    <TypographySubHeading >
                                        Add your rating here
                                    </TypographySubHeading>

                                    <Rating size="large" style={{ gap: '8px', fontSize: '32px' }}
                                        value={ratingValue}
                                        onChange={(event, newValue) => {
                                            if(newValue !== null){
                                            setRatingValue(newValue);
                                            }
                                            setRatingValueError('')
                                        }}
                                    />
                                    {ratingValueError && <TypographyError>{ratingValueError}</TypographyError>}
                                    <Box sx={{
                                        width: '100%', height: '100%', paddingTop: '8px', justifyContent: 'center', alignItems: 'center',
                                        display: 'flex', flexDirection: 'row'
                                    }}
                                    >
                                        <ButtonBlue sx={{ width: 'fit-content', gap: '4px' }} onClick={handleRateSubmitButtonClick}>Submit</ButtonBlue>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>}

            {isMessage && <Box sx={{
                position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.6)',
                display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '2',

            }}>
                <Box sx={{
                    width: '100%', height: '100%', gap: '5px',
                    justifyContent: 'center', alignItems: 'center', position: 'fixed',
                    display: 'flex', flexDirection: 'column',
                }}>
                    <Box sx={{
                        width: { md: '35%', sm: '35%', xs: '85%' }, height: '36px',
                        justifyContent: 'right', alignItems: 'right', display: 'flex',
                    }}>
                        <IconButton onClick={handleCloseMessagePopUp} sx={{
                            position: 'fixed', width: '36px', height: '36px',
                        }} >
                            <CloseOutlinedIcon sx={{
                                width: '36px', height: '36px', color: Colors.black_60, '&:hover': {
                                    width: '40px', height: '40px', color: Colors.black_60, backgroundColor: 'none',
                                    background: 'none'
                                },
                            }} ></CloseOutlinedIcon>
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            width: { md: '35%', sm: '35%', xs: '85%' }, height: 'fit-content',
                            backgroundColor: Colors.white, border: '1px solid',
                            borderColor: Colors.white, borderRadius: '4px ', boxShadow: 'none',
                            boxSizing: 'border-box', zIndex: '3', alignItems: 'center',
                        }}>

                        <Box sx={{
                            display: 'flex', flexDirection: 'column', width: '100%', gap: '16px',
                            justifyContent: 'center', alignItems: 'center', height: '100%',
                        }}>
                            <Box sx={{
                                display: 'flex', flexDirection: 'column', width: '100%', gap: '16px', paddingTop: '7%',
                                justifyContent: 'center', alignContent: 'center', height: '86%', maxWidth: '80%',
                                paddingBottom: '7%'
                            }}>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'column', width: '100%', gap: '20px',
                                    justifyContent: 'center', height: '100%', alignItems: 'left',
                                }}>
                                    <TypographySubHeading>
                                        Send a message
                                    </TypographySubHeading>
                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', width: '100%', position: "relative",
                                        justifyContent: 'center', height: '100%', alignItems: 'left',
                                    }}>
                                        <TypographyNormal>Select service you are looking for*</TypographyNormal>
                                        <TextfieldNormal value={selectedServices} sx={{ width:'100%',
                                            '.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root': {paddingRight:{xs:'0px', md:'0px'}}
                                        }}
                                            placeholder="Select service"
                                            fullWidth
                                            error={!!serviceError}
                                            InputProps={{
                                                readOnly: true,
                                                endAdornment: (
                                                    <IconButton onClick={handleToggleService}>
                                                        {serviceOpen ? <KeyboardArrowUpIcon sx={{ fontSize: {xs:  '20px', md: '28px' },
                                                         '&.css-78trlr-MuiButtonBase-root-MuiIconButton-root': {padding:0}}}/> :
                                                          <KeyboardArrowDownIcon sx={{ fontSize: {xs:  '20px', md: '28px' }, 
                                                           '&.css-78trlr-MuiButtonBase-root-MuiIconButton-root': {padding:0}}} />}
                                                    </IconButton>
                                                ),
                                            }}></TextfieldNormal>
                                        {serviceError && <TypographyError>{serviceError}</TypographyError>}
                                        {serviceOpen && (
                                            <Box sx={{
                                                width: '100%', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'left',
                                                alignItems: 'center', gap: '16px', position: 'relative', marginTop:{xs:'8px', md:'4px'},
                                            }}>

                                                <Box ref={serviceRef} sx={{
                                                    width: { xs: 'calc(100% - 32px)', md: 'calc(100% - 32px)' }, padding: '16px 16px 16px 16px', position: 'absolute', justifyContent: 'left',
                                                    borderRadius: '4px', gap: '8px', maxHeight: '150px', overflow: 'auto', top: '2px',
                                                    height: 'fit-content', boxShadow: ' 0px 4px 4px 0px #00000040', border: '1px solid', zIndex: 5,
                                                    borderColor: Colors.grey_30, background: Colors.white, alignItems: 'center',
                                                    '&::-webkit-scrollbar': {
                                                        width: '8px', height: '16px', borderRadius: '16px',
                                                    },
                                                    '&::-webkit-scrollbar-thumb': {
                                                        backgroundColor: Colors.grey_30, borderRadius: '16px', border: '2px solid white',
                                                    },
                                                    '&::-webkit-scrollbar-track': {
                                                        borderRadius: '16px', backgroundColor: Colors.white,
                                                    },

                                                }}>
                                                    {console.log(serviceDetails)}
                                                    {serviceDetails.map((result, index) => (

                                                        <Box key={index}
                                                            sx={{
                                                                width: '100%', display: 'flex', flexDirection: 'row', height: '32px',
                                                            }}>
                                                            <Box sx={{
                                                                width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center',
                                                                cursor: 'pointer', '&:hover': {
                                                                    backgroundColor: Colors.quinary,
                                                                },
                                                            }}
                                                                onClick={() => handleServiceCheckboxChange(
                                                                    { target: { checked: !serviceDetails.includes(result.services), value: result.services } },
                                                                    result.services_id
                                                                )}
                                                            >
                                                                <Checkbox size="small"
                                                                    onClick={(event) => event.stopPropagation()}
                                                                    value={result.services}
                                                                    checked={selectedServices.includes(result.services)}
                                                                    onChange={(event) => handleServiceCheckboxChange(event, result.services_id)}
                                                                    sx={{
                                                                        '&.Mui-checked': {
                                                                            color: '#0026CC',
                                                                        },
                                                                    }}
                                                                />
                                                                <TypographyNormal sx={{
                                                                    display: 'inline-block', whiteSpace: 'nowrap', overflow: 'hidden',
                                                                    textOverflow: 'ellipsis', maxWidth: '90%'
                                                                }}> {result.services}</TypographyNormal>

                                                            </Box>
                                                        </Box>
                                                    ))}


                                                </Box>
                                            </Box>)}

                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', position: 'relative',
                                         justifyContent: 'center', height: '100%', alignItems: 'left' , }}>
                                        <TypographyNormal>Select the type of service you are looking for*</TypographyNormal>
                                        <TextfieldNormal sx={{ width:'100%',
                                            '.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root': {paddingRight:{xs:'0px', md:'0px'}}}}
                                            value={selectedTypes.map(item => item.type).join(', ')} // Displaying the selected types
                                            placeholder="Select type of service"
                                            fullWidth
                                            InputProps={{
                                                readOnly: true,
                                                endAdornment: (
                                                    <IconButton ref={buttonRef} onClick={handleToggleType}>
                                                        {typeOpen ? <KeyboardArrowUpIcon sx={{ fontSize: {xs:  '20px', md: '28px' },
                                                         '&.css-78trlr-MuiButtonBase-root-MuiIconButton-root': {padding:0}}}/> :
                                                          <KeyboardArrowDownIcon sx={{ fontSize: {xs:  '20px', md: '28px' }, 
                                                           '&.css-78trlr-MuiButtonBase-root-MuiIconButton-root': {padding:0}}} />}
                                                    </IconButton>
                                                ),
                                            }}
                                            error={!!typesError}
                                        />
                                        {typesError && <TypographyError>{typesError}</TypographyError>}                                        
                                        {typeOpen && (
                                            <Box sx={{
                                                width: '100%', height: '100%', display: 'flex', flexDirection: 'row', marginTop:{xs:'8px', md:'4px'},
                                                justifyContent: 'left', alignItems: 'center', gap: '16px', position: 'relative'
                                            }}>
                                                <Box ref={typeRef} sx={{
                                                    width: { xs: 'calc(100% - 40px)', md: 'calc(100% - 40px)' }, padding: '20px 20px 4px 20px', position: 'absolute',
                                                    borderRadius: '4px', gap: '8px', maxHeight: '125px', overflow: 'auto', top: '2px',
                                                    height: 'fit-content', boxShadow: '0px 4px 4px 0px #00000040', border: '1px solid',
                                                    zIndex: 5,
                                                    borderColor: '#E0E0E0', background: '#FFFFFF',
                                                    '&::-webkit-scrollbar': {
                                                        width: '8px', height: '16px', borderRadius: '16px',
                                                    },
                                                    '&::-webkit-scrollbar-thumb': {
                                                        backgroundColor: '#E0E0E0', borderRadius: '16px', border: '2px solid white',
                                                    },
                                                    '&::-webkit-scrollbar-track': {
                                                        borderRadius: '16px', backgroundColor: '#FFFFFF',
                                                    }
                                                }}>
                                                    {Object.keys(typesGroupByService).map(service => (
                                                        <Box key={service} sx={{ display: 'flex', flexDirection: 'column', gap: '8px', paddingBottom: '16px' }}>
                                                            <TypographySubHeading sx={{ fontSize: '16px' }}>
                                                                {service}
                                                            </TypographySubHeading>
                                                            {typesGroupByService[service].map((typeDetail) => (
                                                                <Box key={typeDetail.types_id} sx={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                                                                 
                                                                    <Checkbox
                                                                        size='small'
                                                                        checked={selectedTypes.some(item => item.type === typeDetail.types && item.service === service)}
                                                                        sx={{ '&.Mui-checked': { color: '#0026CC' }, color: '#333333', padding: 0 }}
                                                                        onChange={(e) => handleTypeCheckboxChange(e, typeDetail.types_id, typeDetail.types, typeDetail.types_id, service)}
                                                                    />
                                                                    <TypographyNormal sx={{ display: 'inline-block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '90%' }}>
                                                                        {typeDetail.types}
                                                                    </TypographyNormal>
                                                                </Box>
                                                            ))}
                                                        </Box>
                                                    ))}
                                                </Box>
                                            </Box>
                                        )}
                                    </Box>

                                    <Box sx={{
                                        display: 'flex', flexDirection: 'row', width: '100%', gap: '8px',
                                        justifyContent: 'center', height: '100%', alignItems: 'left',
                                    }}>
                                        <Box sx={{
                                            display: 'flex', flexDirection: 'column', width: '35%',
                                            justifyContent: 'center', height: '100%', alignItems: 'left',
                                        }}>
                                            <TypographyNormal>Country code*</TypographyNormal>
                                            <TextfieldNormal placeholder="+1" onKeyDown={handleKeyDownCountryCode}
                                                inputProps={{
                                                    maxLength: 3,
                                                    inputMode: 'numeric',
                                                    pattern: '[0-9]*', 
                                                }}
                                                sx={{
                                                    fontSize: { md: '16px', sm: '16px', xs: '14px' }, 
                                                    '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
                                                        '-webkit-appearance': 'none',padding:{xs:'8px 12px', md: '10px 16px' },
                                                        margin: 0
                                                    },
                                                    '& input[type=number]': {
                                                        '-moz-appearance': 'textfield',padding:{xs:'8px 12px', md: '10px 16px' },
                                                    }
                                                }}
                                                value={countryCode}
                                                onChange={handleCountryCodeChange} error={!!countryCodeError}>
                                            </TextfieldNormal>
                                        </Box>
                                        <Box sx={{
                                            display: 'flex', flexDirection: 'column', width: '65%',
                                            justifyContent: 'center', height: '100%', alignItems: 'left',
                                        }}>
                                            <TypographyNormal>Your contact number*</TypographyNormal>
                                            <TextfieldNormal placeholder="Add phone number"
                                                onKeyDown={handleKeyDownPhone}
                                                inputProps={{
                                                    maxLength: 10,
                                                    inputMode: 'numeric',
                                                    pattern: '[0-9]*'
                                                }}
                                                value={phoneNumber}
                                                sx={{                                                    
                                                    '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
                                                        '-webkit-appearance': 'none',
                                                       
                                                    },
                                                    '& input[type=number]': {
                                                        '-moz-appearance': 'textfield'
                                                    }
                                                }}
                                                onChange={handlePhoneNumberChange} error={!!phoneNumberError}>
                                            </TextfieldNormal>
                                        </Box>
                                    </Box>
                                    {countryCodeError && <TypographyError sx={{ width: '100%', marginTop: '-20px' }}>{countryCodeError}</TypographyError>}
                                    {phoneNumberError && <TypographyError sx={{ width: '100%', paddingLeft: '32%', marginTop: '-20px' }}>{phoneNumberError}</TypographyError>}
                                    <TypographyNormal sx={{ fontSize: '12px' }}>
                                        By clicking the send button, you agree to our
                                        <TypographyNormal
                                            component="span"
                                            sx={{ color: Colors.blue_6, display: 'inline', marginLeft: '8px', fontSize: '12px', cursor: 'pointer' }}
                                            onClick={handleTermsAndconditionsButtonClick}>
                                            Terms and conditions.
                                        </TypographyNormal>
                                    </TypographyNormal>
                                    {otpSuspendError && <TypographyError>{otpSuspendError}</TypographyError>}
                                    <Box sx={{
                                        width: '100%', height: '100%', paddingTop: '8px', justifyContent: 'right', alignItems: 'center',
                                        display: 'flex', flexDirection: 'row'
                                    }}>
                                        <ButtonBlue sx={{ width: '40%', gap: '4px' }} onClick={handleMessageSendButtonClick}>Send</ButtonBlue>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>}

            {isTerm && <Box sx={{
                position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.6)',
                display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '2',

            }}>
                <Box sx={{
                    width: '100%', height: '100%', gap: '5px',
                    justifyContent: 'center', alignItems: 'center', position: 'fixed',
                    display: 'flex', flexDirection: 'column',
                }}>
                    <Box sx={{
                        width: { md: '75%', sm: '75%', xs: '85%' }, height: '36px',
                        justifyContent: 'right', alignItems: 'right', display: 'flex',
                    }}>
                        <IconButton onClick={handleCloseTermPopUp} sx={{
                            position: 'fixed', width: '36px', height: '36px',
                        }} >
                            <CloseOutlinedIcon sx={{
                                width: '36px', height: '36px', color: Colors.black_60, '&:hover': {
                                    width: '40px', height: '40px', color: Colors.black_60, backgroundColor: 'none',
                                    background: 'none'
                                },
                            }} ></CloseOutlinedIcon>
                        </IconButton>
                    </Box>
                    <Box sx={{
                        width: { md: '75%', sm: '75%', xs: '85%' }, height: '90%',
                        backgroundColor: Colors.white, border: '1px solid',
                        borderColor: Colors.white, borderRadius: '4px ', boxShadow: 'none',
                        zIndex: '3', alignItems: 'center',
                    }}>


                        <Box sx={{
                            display: 'flex', flexDirection: 'row', width: '100%',
                            justifyContent: 'center', alignContent: 'center', height: '100%'
                        }}>

                            <Box sx={{
                                display: { xs: 'none', md: 'flex' }, flexDirection: 'column', width: '50%', gap: '8px',
                                justifyContent: 'center', height: '100%', alignItems: 'center',
                                backgroundImage: `url(${popupbg})`, backgroundRepeat: 'no-repeat',
                                backgroundSize: '100% 100%',
                            }}>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'column', width: '100%', gap: '12px',
                                    justifyContent: 'center', maxWidth: '75%', alignItems: 'left',
                                }}>
                                    <TypographyNormal sx={{ fontSize: '16px', color: Colors.white_80 }}>Welcome</TypographyNormal>
                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', width: '224px', height: '32px',
                                        justifyContent: 'center', alignItems: 'left',
                                    }}>
                                        <img src={whitelogo} alt="Logo" style={{ width: '100%', height: '100%', }} />
                                    </Box>
                                    <TypographyNormal sx={{ color: Colors.white_80 }}>Find the help you need, right in your neighborhood.</TypographyNormal>
                                </Box>
                            </Box>
                            <Box sx={{
                                display: 'flex', flexDirection: 'column', width: { xs: '100%', md: '50%' }, gap: '8px',
                                justifyContent: 'flex-start', height: '100%', alignItems: 'center', overflowX: 'auto',                               

                                '&::-webkit-scrollbar': {
                                    width: '11px', height: '8px', borderRadius: '16px',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: Colors.grey_30, borderRadius: '16px', border: '2px solid white',
                                },
                                '&::-webkit-scrollbar-track': {
                                    borderRadius: '16px', backgroundColor: Colors.white,
                                },
                            }}>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'column', width: '100%', gap: '12px',
                                    height: '100%', justifyContent: 'flex-start', maxWidth: '75%', alignItems: 'left',
                                    paddingTop: '5%',
                                }}>
                                    <div dangerouslySetInnerHTML={{ __html: termsDetails[0].content }} />
                                </Box>
                            </Box>
                        </Box>

                    </Box>
                </Box>
            </Box>}

            {isotp && <Box sx={{
                position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.6)',
                display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '2',

            }}>
                <Box sx={{
                    width: '100%', height: '100%', gap: '5px',
                    justifyContent: 'center', alignItems: 'center', position: 'fixed',
                    display: 'flex', flexDirection: 'column',
                }}>
                    <Box sx={{
                        width: { md: '35%', sm: '35%', xs: '85%' }, height: '36px',
                        justifyContent: 'right', alignItems: 'right', display: 'flex',
                    }}>
                        <IconButton onClick={handleCloseOTPPopUp} sx={{
                            position: 'fixed', width: '36px', height: '36px',
                        }} >
                            <CloseOutlinedIcon sx={{
                                width: '36px', height: '36px', color: Colors.black_60, '&:hover': {
                                    width: '40px', height: '40px', color: Colors.black_60, backgroundColor: 'none',
                                    background: 'none'
                                },
                            }} ></CloseOutlinedIcon>
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            width: { md: '35%', sm: '35%', xs: '85%' }, height: 'fit-content',
                            backgroundColor: Colors.white, border: '1px solid',
                            borderColor: Colors.white, borderRadius: '4px ', boxShadow: 'none',
                            boxSizing: 'border-box', zIndex: '3', alignItems: 'center',
                        }}>

                        <Box sx={{
                            display: 'flex', flexDirection: 'column', width: '100%', gap: { xs: '8px', md: '16px' },
                            justifyContent: 'center', alignItems: 'center', height: '100%',
                        }}>
                            <Box sx={{
                                display: 'flex', flexDirection: 'column', width: '100%', gap: { xs: '8px', md: '16px' }, paddingTop: '7%',
                                justifyContent: 'center', alignContent: 'center', height: '86%', maxWidth: '86%',
                                paddingBottom: '7%'
                            }}>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'column', width: '100%', gap: { xs: '4px', md: '8px' },
                                    justifyContent: 'center', height: '100%', alignItems: 'center',
                                }}>
                                    <TypographySubHeading sx={{fontSize:{xs:  '14px', md: '18px' }}}>
                                        OTP Verification
                                    </TypographySubHeading>

                                    <TypographyNormal sx={{ paddingTop: { xs: '4px', md: '8px' }, textAlign: 'center' }}>
                                        We have sent a one-time password to your mobile number.
                                    </TypographyNormal>
                                    <Box sx={{
                                        width: '100%', height: '100%', paddingTop: '8px', alignItems: 'center', maxWidth: '80%',
                                        display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: { xs: '8px', md: '12px' }
                                    }}>
                                        <TypographyNormal sx={{ color: Colors.black_90 }}>
                                            Enter your OTP here
                                        </TypographyNormal>
                                        <Box sx={{ display: 'flex', gap: '8px', justifyContent: 'center', alignItems: 'center' }}
                                            onPaste={handlePaste} >
                                            {otp.map((value, index) => (
                                                <TextField
                                                    key={index}
                                                    id={`otp-input-${index}`}
                                                    value={value}
                                                    onChange={(e) => handleChange(e, index)}
                                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                                    onInput={(e) => handleClipboardInput(e, index)}
                                                    inputProps={{
                                                        maxLength: 1,
                                                        style: { textAlign: 'center' }
                                                    }}
                                                    sx={{
                                                        width: '48px', height: '56px', borderRadius: '4px', background: Colors.grey_30,
                                                        '& .MuiOutlinedInput-root': {
                                                            border: '1px solid', borderColor: otpError ? Colors.red : Colors.grey_30,
                                                            '& fieldset': {
                                                                border: '1px solid', borderColor: otpError ? Colors.red : Colors.grey_30
                                                            },
                                                            '& .MuiInputBase-input::placeholder': {
                                                                fontFamily: 'Roboto', fontSize: '14px', fontWeight: 400, color: Colors.black_70,
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                border: '1px solid', borderColor: otpError ? Colors.red : Colors.black_80
                                                            },

                                                        },

                                                    }}
                                                />
                                            ))}
                                        </Box>

                                        {otpError && <TypographyError>{otpError}</TypographyError>}
                                        <TypographyNormal >
                                            Don't receive the OTP?{' '}
                                            <TypographyNormal onClick={handleReSendOtpClick}
                                                component="span"
                                                sx={{ color: Colors.blue_6, display: 'inline', marginLeft: '8px', cursor: 'pointer' }}
                                            >
                                                Resend OTP
                                            </TypographyNormal>
                                        </TypographyNormal>
                                        <ButtonBlue sx={{ width: '120%', gap: '4px', }} onClick={handleOTPVerifyButtonClick}>Verify </ButtonBlue>

                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>}

            {isSuccessPopUp && <Box sx={{
                position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.6)',
                display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '2',

            }}>
                <Box sx={{
                    width: '100%', height: '100%', gap: '5px',
                    justifyContent: 'center', alignItems: 'center', position: 'fixed',
                    display: 'flex', flexDirection: 'column',
                }}>
                    <Box sx={{
                        width: { md: '35%', sm: '35%', xs: '85%' }, height: '36px',
                        justifyContent: 'right', alignItems: 'right', display: 'flex',
                    }}>
                        <IconButton onClick={handleCloseSuccessPopUp} sx={{
                            position: 'fixed', width: '36px', height: '36px',
                        }} >
                            <CloseOutlinedIcon sx={{
                                width: '36px', height: '36px', color: Colors.black_60, '&:hover': {
                                    width: '40px', height: '40px', color: Colors.black_60, backgroundColor: 'none',
                                    background: 'none'
                                },
                            }} ></CloseOutlinedIcon>
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            width: { md: '35%', sm: '35%', xs: '85%' }, height: 'fit-content',
                            backgroundColor: Colors.white, border: '1px solid',
                            borderColor: Colors.white, borderRadius: '4px ', boxShadow: 'none',
                            boxSizing: 'border-box', zIndex: '3', alignItems: 'center',
                        }}>

                        <Box sx={{
                            display: 'flex', flexDirection: 'column', width: '100%', gap: '16px',
                            justifyContent: 'center', alignItems: 'center', height: '100%',
                        }}>
                            <Box sx={{
                                display: 'flex', flexDirection: 'column', width: '100%', gap: { xs: '8px', md: '16px' }, paddingTop: '10%',
                                justifyContent: 'center', alignContent: 'center', height: '80%', maxWidth: '86%',
                                paddingBottom: '10%'
                            }}>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'column', width: '100%', gap: { xs: '8px', md: '16px' },
                                    justifyContent: 'center', alignContent: 'center', height: '100%', alignItems: 'center',
                                }}>
                                    <CheckCircleOutlineIcon sx={{ color: '#00A91B', fontSize: '38px' }}></CheckCircleOutlineIcon>

                                    <TypographySubHeading sx={{ textAlign: 'center', paddingTop: '8px', fontSize: {xs:  '14px', md: '18px' }}}>
                                        Quotation Request submitted successfully!
                                    </TypographySubHeading>
                                    <TypographyNormal>Thank you for your quote request for {showServicePopUp}. You've provided the phone number: {phoneNumber}.</TypographyNormal>
                                    <ButtonBlue onClick={handleCloseSuccessPopUp} sx={{ marginTop: '16px',height:{ xs: '36px', md:  '38px' }}}>Search more services</ButtonBlue>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>}

            {isImagePopUp && <Box sx={{
                position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.6)',
                display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '2',

            }}>
                <Box sx={{
                    width: '100%', height: '100%', gap: '5px',
                    justifyContent: 'center', alignItems: 'center', position: 'fixed',
                    display: 'flex', flexDirection: 'column',
                }}>
                    <Box sx={{
                        width: { md: '35%', sm: '35%', xs: '85%' }, height: '36px',
                        justifyContent: 'right', alignItems: 'right', display: 'flex',
                    }}>
                        <IconButton onClick={handleCloseImagePopUp} sx={{
                            position: 'fixed', width: '36px', height: '36px', zIndex: 50
                        }} >
                            <CloseOutlinedIcon sx={{
                                width: '36px', height: '36px', color: Colors.black_60, '&:hover': {
                                    width: '40px', height: '40px', color: Colors.black_60, backgroundColor: 'none',
                                    background: 'none'
                                },
                            }} ></CloseOutlinedIcon>
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            width: { md: '35%', sm: '35%', xs: '85%' }, height: { md: '50%', sm: '50%', xs: '50%' },
                            backgroundColor: Colors.white, border: '1px solid',
                            borderColor: Colors.white, borderRadius: '4px ', boxShadow: 'none',
                            boxSizing: 'border-box', zIndex: '3', alignItems: 'center',
                        }}>

                        <Box sx={{
                            display: 'flex', flexDirection: 'column', width: '100%', gap: '16px',
                            justifyContent: 'center', alignItems: 'center', height: '100%',paddingBottom:'4%'
                        }}>
                            <Box sx={{
                                display: 'flex', flexDirection: 'row', width: '100%', paddingTop: '14%',
                                justifyContent: 'center', alignContent: 'center', height: '33%', maxWidth: '98%',
                                paddingBottom: '10%',
                            }}>

                                <IconButton
                                    onClick={handlePreviousImage}
                                    sx={{ color: Colors.primary_blue, width: 'fit-content', background: 'none', '&: hover': { background: 'none', } }}>
                                    <ArrowBackIosIcon />
                                </IconButton>


                                {selectedServiceData[0].image_details[currentImageIndex] && (
                                    <img
                                        src={IMG_URL + selectedServiceData[0].image_details[currentImageIndex].image_name}
                                        alt="Selected_image"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain',
                                            borderRadius: '4px',                                          
                                            
                                        }} 
                                    />
                                )}



                                <IconButton
                                    onClick={handleNextImage}
                                    sx={{ color: Colors.primary_blue, width: 'fit-content', background: 'none', '&: hover': { background: 'none', } }}>
                                    <ArrowForwardIosIcon />
                                </IconButton>

                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: {md:'8px', xs:'4px'}, flexWrap: 'wrap',maxWidth:'90%', 
                                justifyContent:'left' ,alignItems:'center', width:'100%',height:'13%', }}>
                                {selectedServiceData[0].image_details.map((image, index) => (
                                    <Box key={index} sx={{ width: { xs: '9%', md: '10%' }, height: '100%',}}>
                                        {console.log(IMG_URL + image.image_name)}
                                        {console.log(index)}
                                        <img
                                            src={IMG_URL + image.image_name}
                                            alt="Projects"
                                            style={{
                                                width: '100%', height: '100%', objectFit: 'cover',
                                                borderRadius: '4px', border: '1px solid',
                                                borderColor:currentImageIndex === index ? Colors.primary_blue : Colors.black_60, cursor: 'pointer'
                                            }} onClick={() => handleImagePopUpClick(image.image_name, index)}
                                        />
                                    </Box>
                                ))}
                            </Box>

                        </Box>
                    </Box>
                </Box>
            </Box>}

            {disablePopUpAssossiate && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.6)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 2,
                    }}
                >
                    <Box sx={{
                        width: '100%', height: '100%', gap: '5px',
                        justifyContent: 'center', alignItems: 'center', position: 'fixed',
                        display: 'flex', flexDirection: 'column',
                    }}>
                        <Box sx={{
                            width: { md: '375px', sm: '380px', xs: '85%' }, height: '36px',
                            justifyContent: 'right', alignItems: 'right', display: 'flex',
                        }}>
                            <IconButton onClick={handleCancelDisablePopup} sx={{
                                width: '36px', height: '36px',
                            }} >
                                <CloseOutlinedIcon sx={{
                                    width: '36px', height: '36px', color: Colors.black_60, '&:hover': {
                                        width: '40px', height: '40px', color: Colors.black_60, backgroundColor: 'none',
                                        background: 'none'
                                    },
                                }} >
                                </CloseOutlinedIcon>
                            </IconButton>
                        </Box>
                        <Box
                            sx={{

                                width: { md: '375px', sm: '380px', xs: '85%' },
                                height: 'fit-content',
                                backgroundColor: '#fff',
                                border: '1px solid #E6E6E6',
                                borderRadius: '4px ',
                                boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.3)',
                                boxSizing: 'border-box',
                                zIndex: 3,
                                padding: '35px',
                                alignItems: 'center',
                                overflow: 'auto',
                                '&::-webkit-scrollbar': {
                                    width: '12px',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: '#7f7f7f',
                                    borderRadius: '28px',
                                },
                                '&::-webkit-scrollbar-track': {
                                    backgroundColor: '#e0e0e0',
                                    borderRadius: '28px',
                                },
                            }}
                        >

                            <Box sx={{
                                display: 'flex', flexDirection: 'column', width: '100%', gap: '16px', alignItems: 'center',
                                justifyContent: 'center', alignContent: 'center', height: '100%'
                            }}>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
                                    alignContent: 'center', width: '100%', gap: '16px'
                                }}>

                                    <TypographyNormal sx={{ fontSize: '18px', color: '#000000', fontWeight: 600 }}>
                                        Reject</TypographyNormal>

                                    <TypographyNormal sx={{ fontSize: '16px', color: Colors.black_70 }}>
                                        If you reject, you won't be able to use your profile. Are you sure you want to proceed?</TypographyNormal>

                                </Box>

                                <Box sx={{
                                    display: 'flex', flexDirection: 'row', gap: '16px', marginTop: '16px', justifyContent: 'center',
                                    alignContent: 'center', alignItems: 'center', width: '100%'
                                }}>

                                    <ButtonWhite onClick={handleCancelDisablePopup}>No</ButtonWhite>
                                    <ButtonBlue onClick={handleYesDisablePopup}>Yes</ButtonBlue>

                                </Box>


                            </Box>


                        </Box>
                    </Box>
                </Box>)}

            {otpPopUpAssossiate && <Box sx={{
                position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.6)',
                display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '2',

            }}>
                <Box sx={{
                    width: '100%', height: '100%', gap: '5px',
                    justifyContent: 'center', alignItems: 'center', position: 'fixed',
                    display: 'flex', flexDirection: 'column',
                }}>
                    <Box sx={{
                        width: { md: '75%', sm: '75%', xs: '85%' }, height: '36px',
                        justifyContent: 'right', alignItems: 'right', display: 'flex',
                    }}>
                        <IconButton onClick={handleCloseVerifyOtpPopUp} sx={{
                            position: 'fixed', width: '36px', height: '36px',
                        }} >
                            <CloseOutlinedIcon sx={{
                                width: '36px', height: '36px', color: Colors.black_60, '&:hover': {
                                    width: '40px', height: '40px', color: Colors.black_60, backgroundColor: 'none',
                                    background: 'none'
                                },
                            }} ></CloseOutlinedIcon>
                        </IconButton>
                    </Box>
                    <Box sx={{
                        width: { md: '75%', sm: '75%', xs: '85%' }, height: 'fit-content',
                        backgroundColor: Colors.white, border: '1px solid',
                        borderColor: Colors.white, borderRadius: '4px ', boxShadow: 'none',
                        zIndex: '3', alignItems: 'center',
                    }}>


                        <Box sx={{
                            display: 'flex', flexDirection: 'row', width: '100%',
                            justifyContent: 'center', alignContent: 'center', height: '100%'
                        }}>

                            <Box sx={{
                                display: { xs: 'none', md: 'flex' }, flexDirection: 'column', width: '50%', gap: '8px',
                                justifyContent: 'center', height: '100%', alignItems: 'center',
                                backgroundImage: `url(${popupbg})`, backgroundRepeat: 'no-repeat',
                                backgroundSize: '100% 100%',
                            }}>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'column', width: '100%', gap: '12px',
                                    justifyContent: 'center', maxWidth: '75%', alignItems: 'left',
                                }}>
                                    <TypographyNormal sx={{ fontSize: '16px', color: Colors.white_80 }}>Welcome</TypographyNormal>
                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', width: '224px', height: '32px',
                                        justifyContent: 'center', alignItems: 'left',
                                    }}>
                                        <img src={whitelogo} alt="Logo" style={{ width: '100%', height: '100%', }} />
                                    </Box>
                                    <TypographyNormal sx={{ color: Colors.white_80 }}>Find the help you need, right in your neighborhood.</TypographyNormal>
                                </Box>
                            </Box>
                            <Box sx={{
                                display: 'flex', flexDirection: 'column', width: { xs: '100%', md: '50%' }, gap: '8px',
                                justifyContent: 'center', height: '100%', alignItems: 'center',
                            }}>
                                <Box sx={{
                                    display: 'flex', flexDirection: 'column', width: '100%', gap: '12px',
                                    paddingTop: '25%', paddingBottom: '25%',
                                    height: '100%', justifyContent: 'center', maxWidth: '75%', alignItems: 'left',
                                }}>

                                    <OTPInput phoneNumber={serviceProviderDetails.phone_number} countryCode={serviceProviderDetails.country_code} setIsOtpSuccess={setIsOtpSuccess}></OTPInput>


                                </Box>
                            </Box>
                        </Box>

                    </Box>
                </Box>
            </Box>}

            {passwordAssossiate && (
                <AssociateResetPassword passwordAssossiate={passwordAssossiate} setPasswordAssossiate={setPasswordAssossiate} />
            )}

            {isViewCard && <Box sx={{
                position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.6)',
                display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '2',

            }}>
                <Box sx={{
                    width: '100%', height: '100%', gap: '5px',
                    justifyContent: 'center', alignItems: 'center', position: 'fixed',
                    display: 'flex', flexDirection: 'column',
                }}>
                    <Box sx={{
                        width: { md: '40%', sm: '40%', xs: '85%' }, height: '36px',
                        justifyContent: 'right', alignItems: 'right', display: 'flex',
                    }}>
                        <IconButton onClick={handleCloseViewCardPopUp} sx={{
                            position: 'fixed', width: '36px', height: '36px',
                        }} >
                            <CloseOutlinedIcon sx={{
                                width: '36px', height: '36px', color: Colors.black_60, '&:hover': {
                                    width: '40px', height: '40px', color: Colors.black_60, backgroundColor: 'none',
                                    background: 'none'
                                },
                            }} ></CloseOutlinedIcon>
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            width: { md: '40%', sm: '40%', xs: '85%' }, height: 'fit-content',
                            backgroundColor: Colors.white, border: '1px solid',
                            borderColor: Colors.white, borderRadius: '4px ', boxShadow: 'none',
                            zIndex: '3', alignItems: 'center',
                        }}>
                        <Box sx={{
                            width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: '4px ',
                            flexDirection: 'column', border: { xs: '0px solid #E6E6E6', md: '1px solid  #E6E6E6' }, borderColor: Colors.grey_30, gap: '16px'
                        }}>
                            <Box sx={{
                                width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex', borderRadius: '4px ', 
                                flexDirection: 'column', background: { xs: 'linear-gradient(to bottom, #E6E6E6 110px, white 8%)', md: 'linear-gradient(to bottom, #E6E6E6 140px, white 10%)' }, gap: '16px',
                            }}>
                                <Box sx={{
                                    width: '100%', height: '94%', justifyContent: 'center', alignItems: 'center', display: 'flex',
                                    flexDirection: 'column', gap: { xs: '8px', md: '24px' }, paddingBottom: '5%',
                                }}>
                                    <Box sx={{
                                        width: '100%', height: 'fit-content', justifyContent: 'center', 
                                        alignItems: 'center', display: 'flex', flexDirection: 'column', paddingBottom: { xs: '16px', md: '0px' },
                                        borderRadius: '4px',
                                    }}>
                                <Box sx={{
                                width: '100%', height: 'fit-content', justifyContent: 'flex-start', alignItems: 'center', display: 'flex', borderRadius: '4px ',
                               paddingTop:'5%',  flexDirection: 'column', background: { xs: 'linear-gradient(to bottom, #E6E6E6 70%, transparent 70%)', md: 'linear-gradient(to bottom, #E6E6E6 84%, transparent 84%)' }, 
                               gap: '16px',
                            }}>

                                        <Box sx={{
                                            width: '100%', height: '100%', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'flex',
                                            flexDirection: 'column',  maxWidth: { xs: '90%', md: '92%' },
                                        }}>
                                            <Box sx={{
                                                    display: 'flex', flexDirection: 'row', width: '100%', height: '24px', justifyContent: 'right',
                                                    alignItems: 'center', 
                                                }}>
                                                    <img src={logo} alt="Logo"
                                                     style={{ width:  'fit-content' , height: '24px',}} />
                                                </Box>
                                                <Box sx={{
                                            width: '100%', height: '100%', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'flex',
                                            flexDirection: 'row', gap:  { xs: '12px', md: '32px'}, 
                                        }}>
                                            <Box sx={{
                                                height: '100%', width: { xs: '90px', md: '140px' }, justifyContent: 'center', alignItems: 'flex-start',
                                                display: 'flex', flexDirection: 'row', marginTop: { xs: '0%', md: '-5%' }
                                            }}>
                                                <Box sx={{ position: 'relative', display: 'inline-block', }}>
                                                    {console.log(serviceProviderDetails.rating_details)}
                                                    {serviceProviderDetails.profile_image ? (
                                                        <Avatar alt="Profile"
                                                            src={IMG_URL + serviceProviderDetails.profile_image}
                                                            sx={{ width: { xs: '80px', md: '140px' }, height: { xs: '80px', md: '140px' } }} />
                                                    ) : (
                                                        <Avatar  {...stringAvatar(serviceProviderDetails.business_name)}
                                                            sx={{ width: { xs: '80px', md: '140px' }, height: { xs: '80px', md: '140px' }, fontSize: { xs: '32px', md: '58px' }, bgcolor: '#4C50AE' }} />

                                                    )}
                                                    {serviceProviderDetails.rating_details.length > 0 &&
                                                        <Avatar sx={{
                                                            position: 'absolute', bottom: { xs: 0, md: 12 }, right: { xs: 0, md: 1 }, width: { xs: '24px', md: '36px' }, height: { xs: '24px', md: '36px' },
                                                            backgroundColor: Colors.primary_blue, color: 'white',
                                                        }} >
                                                            <TypographyNormal sx={{ color: Colors.white, fontSize: { xs: '12px', md: '14px' } }}>
                                                                {calculateAverageRating(serviceProviderDetails.rating_details)}
                                                            </TypographyNormal>
                                                        </Avatar>}
                                                </Box>

                                            </Box>
                                            <Box sx={{
                                                height: '100%', width: { xs: 'calc(100% - 90px)', md: 'calc(100% - 140px)' }, justifyContent: 'flex-start',
                                                alignItems: 'flex-start', display: 'flex', flexDirection: 'column', gap: '8px',
                                            }}>
                                               

                                                <Box sx={{
                                                    width: '100%', height: '100%', justifyContent: 'flex-start', alignItems: 'left', display: 'flex',
                                                    flexDirection: 'column', gap: { xs: '4px', md: '0px' },paddingTop:'2%',  maxWidth: '97%'
                                                }}>

                                                    <TypographySubHeading sx={{
                                                        fontSize: { xs: '14px', md:  '24px' }, fontWeight: 500, whiteSpace: 'nowrap', overflow: 'hidden',
                                                        textOverflow: 'ellipsis', maxWidth: { xs: '98%', md:  '95%' } 
                                                    }}>{serviceProviderDetails.business_name}</TypographySubHeading>
                                                    <TypographyNormal sx={{
                                                        fontSize: { xs: '12px', md: '16px' }, lineHeight: { xs: '14px', md: '24px' },
                                                        color: Colors.black_80, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth:{ xs: '98%', md:  '95%' } 
                                                    }}> {serviceTabName.map(detail => detail).join(', ')}</TypographyNormal>
                                                    <TypographyNormal sx={{
                                                        fontSize: { xs: '12px', md: '16px' }, lineHeight: { xs: '14px', md: '24px' },
                                                        color: Colors.black_80, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: { xs: '98%', md:  '95%' }  
                                                    }}> {serviceProviderDetails.city}, {serviceProviderDetails.province}, {serviceProviderDetails.country}</TypographyNormal>
                                                </Box>


                                            </Box>
                                            </Box>
                                        </Box>
                                        </Box>
                                        <Box sx={{
                                            width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center',  display: 'flex',
                                            flexDirection: 'row', gap: { xs: '12px', md: '32px'}, maxWidth: { xs: '90%', md: '90%' },
                                        }}>
                                            <Box sx={{ height: '100%', width: { xs: '90px', md: '140px' }, justifyContent:'center',
                                            display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                                                <Box
                                                    component="img"
                                                    src={qrCodeUrl}
                                                    alt="qrcode"
                                                    sx={{ width: '60%', hight:'60%'}} // Ensure image scales with the width
                                                />
                                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <TypographyNormal
                                                        sx={{
                                                            width: '100%', // Ensure text has the same width as the image
                                                            textAlign: 'center', // Center the text
                                                            overflow: 'hidden', // Hide overflowing text
                                                            textOverflow: 'ellipsis', // Add ellipsis if text overflows
                                                            whiteSpace: 'nowrap', // Prevent text from wrapping
                                                            letterSpacing: '0.1em' // Add space between each letter
                                                        }}
                                                    >
                                                        {serviceProviderDetails.service_provider_code}
                                                    </TypographyNormal>
                                                </Box>
                                            </Box>


                                            <Box sx={{
                                                height: '100%', width:{ xs: 'calc(100% - 90px)', md: 'calc(100% - 140px)' }, justifyContent: 'center',
                                                alignItems: 'left', display: 'flex', flexDirection: 'column', gap: '8px',
                                            }}>
                                                <Box sx={{
                                                    width: '100%', height: 'fit-content', justifyContent: 'space-between',
                                                    alignItems: 'flex-start', display: 'flex',
                                                    flexDirection: 'column', gap: '8px',
                                                    marginTop: { xs: '-0%', md: '-0%' }
                                                }}>
                                                    <Box
                                                        sx={{
                                                            width: '100%', height: 'fit-content', justifyContent: 'flex-start',
                                                            alignItems: 'flex-start', display: 'flex', flexDirection: { xs: 'column', md: 'column' },
                                                            flexWrap: 'wrap', gap: '6px'
                                                        }}>
                                                        {[
                                                            { label: 'Contact person', value: serviceProviderDetails.contact_person },
                                                            { label: 'Phone number', value: `+${serviceProviderDetails.country_code} ${serviceProviderDetails.phone_number}` },
                                                            { label: 'Email id', value: serviceProviderDetails.email },
                                                            { label: 'Website', value: serviceProviderDetails.website },
                                                            { label: 'Language', value: serviceProviderDetails.languages },

                                                        ]
                                                            .filter(item => item.value)
                                                            .map((item, index, arr) => (
                                                                <Box
                                                                    key={index}
                                                                    sx={{
                                                                        display: 'flex', alignItems: 'center', gap: { xs: '4px', md: '12px' }, flexDirection: 'row',
                                                                        width: arr.length === 1 ? '100%' : { xs: '100%', md: '100%' },
                                                                        justifyContent: 'flex-start'
                                                                    }}>
                                                                    <TypographyNormal sx={{ minWidth: '12ch', fontWeight: 600, fontSize: { xs: '12px', md: '14px' } }}>{item.label}</TypographyNormal>
                                                                    <TypographyNormal>:</TypographyNormal>
                                                                    <TypographyNormal
                                                                        sx={{ maxWidth: { xs: '98%', md: '95%' }, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: { xs: '12px', md: '14px' } }}>
                                                                        {item.value}
                                                                    </TypographyNormal>
                                                                </Box>
                                                            ))}
                                                    </Box>
                                                </Box>


                                            </Box>

                                        </Box>
                                    </Box>




                                </Box>
                            </Box>

                        </Box>


                    </Box>
                </Box>
            </Box>}



        </Grid>
    )
}

export default ProfileComponent;

