
import React, { useEffect, useState} from 'react';
import { Grid, Box, IconButton , } from "@mui/material";
import TypographyNormal from "../Components/TypographyNormal";
import Colors from '../Components/Colors';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import popupbg from '../Images/popup.png'
import whitelogo from '../Images/whitelogo.svg'
import TextfieldNormal from '../Components/TextfieldNormal';
import ButtonBlue from '../Components/ButtonBlue';
import TypographyError from '../Components/TypographyError';
import axios from "axios";
import { API_URL } from '../Components/Constants';




function SpForgotPh(props){

    const {setSpPopupType, spPopupType} = props
   
    const [countryCode, setCountryCode] = useState("1");
    const [countryCodeError, setCountryCodeError] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState("");   
    const [signinError, setSigninError] = useState("");
    const [forgotPasswordData, setForgotPasswordData] = useState({      
        countryCode: "1",
        phoneNumber: "",
    });


   useEffect(() => { 
            
        if(localStorage.getItem("forgotspph")) {
        setCountryCode(localStorage.getItem("forgotspcountry"))
        setPhoneNumber(localStorage.getItem("forgotspph"))  
        setForgotPasswordData((prevData) => ({
            ...prevData,
            countryCode: localStorage.getItem("forgotspcountry"),
        }));  
        setForgotPasswordData((prevData) => ({
            ...prevData,
            phoneNumber: localStorage.getItem("forgotspph"),
        }));   
       
    } 
    }, [localStorage.getItem("forgotspph"), localStorage.getItem("forgotspcountry")]);


    const handleCountryCodeChange = (event) => {
        setCountryCode(event.target.value);       
        setCountryCodeError(""); 
        setForgotPasswordData((prevData) => ({
            ...prevData,
            countryCode: event.target.value,
        })); 
        setSigninError("")      
    };

    const handleKeyDownCountryCode = (event) => {
        const key = event.key;
        if (!/^\d$/.test(key) && key !== 'Backspace' && key !== 'Delete' && key !== 'ArrowLeft' && key !== 'ArrowRight') {
            event.preventDefault();
        }
    };

    const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value);       
        setPhoneNumberError("");
        setSigninError("")  
        setForgotPasswordData((prevData) => ({
            ...prevData,
            phoneNumber: event.target.value,
        }));
          
    };

    const handleKeyDownPhone = (event) => {
        const key = event.key;
        if (!/^\d$/.test(key) && key !== 'Backspace' && key !== 'Delete' && key !== 'ArrowLeft' && key !== 'ArrowRight') {
            event.preventDefault();
        }
    };

    const handleCloseSignUpPopUp = () => {
        setSpPopupType('signin');     
    }
    
    const handleSendOtpClick = async () => {   
        if (countryCode.trim() === "") {
            setCountryCodeError("Please enter your country code");
        }            
        else if (phoneNumber.trim() === "") {
            setPhoneNumberError("Please enter your phone number");
        }
        else if (phoneNumber.trim() !== "" && phoneNumber.length !== 10) {
            setPhoneNumberError('Phone number must be 10 digits');
        }        
       
        else {
         try {
                const existresponse = await axios.post(`${API_URL}/check_phone_number_exist`,
                    {
                        countryCode: countryCode,
                        phone: phoneNumber,
                    }); 
                    console.log(existresponse.data)                
                if (existresponse.data.success === false && existresponse.data.userType === 'service_provider') {      
                    try {
                        const response = await axios.post(`${API_URL}/otp_creation`,
                            {
                                countryCode: countryCode,
                                phone: phoneNumber,
                            });                  
                        if (response.data.success === true) {
                            try {
                                const responsedata = await axios.post(`${API_URL}/select_sp_details_with_phno`,
                                    {
                                        countryCode: countryCode,
                                        phoneNumber: phoneNumber,
                                    }); 
                                    if (responsedata.data.success === true) {
                                        console.log(responsedata)
                                        console.log(responsedata.data.result.token)
                                        localStorage.setItem("SpForgotToken",responsedata.data.result.token)
                                    }
                                 }
                                 catch (error) {
                                    console.error("Request failed:", error);
                                }

                            setSpPopupType('forgototp');
                            localStorage.setItem('spforgotpassword', JSON.stringify(forgotPasswordData));
                        }
                    
                        else {
                            console.log("an error occured");

                        }
                    }
                    catch (error) {
                        console.error("Request failed:", error);
                    }
                }
                else{
                    setSigninError("We couldn't find an account with this phone number.")
                }
             }
            catch (error) {
                console.error("Request failed:", error);
            }
        }
};




return(
<Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px' }}>

        {spPopupType === 'Spforgotph' && <Box sx={{
            position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.6)',
            display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1500',

        }}>
            <Box sx={{
                width: '100%', height: '100%', gap: '5px',
                justifyContent: 'center', alignItems: 'center', position: 'fixed',
                display: 'flex', flexDirection: 'column',
            }}>
                <Box sx={{
                    width: { md: '75%', sm: '75%', xs: '85%' }, height: '36px',
                    justifyContent: 'right', alignItems: 'right', display: 'flex',
                }}>
                        <IconButton onClick={handleCloseSignUpPopUp} sx={{
                    position: 'fixed', width: '36px', height: '36px',}} >
                        <CloseOutlinedIcon sx={{ width: '36px', height: '36px', color: Colors.black_60, '&:hover': {
                        width: '40px', height: '40px', color: Colors.black_60, backgroundColor: 'none',
                        background:'none'},}} ></CloseOutlinedIcon>
                    </IconButton>
                </Box>
                <Box sx={{width: { md: '75%', sm: '75%', xs: '85%' },height: 'fit-content',
                        borderRadius: '4px ',boxShadow: 'none',
                        zIndex: '3', alignItems: 'center', 
                    }}>

                 
                            <Box sx={{
                                display: 'flex', flexDirection: 'row', width: '100%', 
                                justifyContent: 'center', alignContent: 'center', height: '100%'}}>
                                    
                                    <Box sx={{
                                        display: {xs: 'none', md:'flex' }, flexDirection: 'column', width: '45%', gap: '8px',
                                        justifyContent: 'center',  height: '100%', alignItems: 'center', borderRadius: '4px 0px 0px 4px',
                                        backgroundImage: `url(${popupbg})`, backgroundRepeat: 'no-repeat', border:'1px solid', 
                                        backgroundSize: '100% 100%',backgroundPosition: 'center',borderColor: Colors.primary_blue,}}>
                                              <Box sx={{
                                                display: 'flex', flexDirection: 'column', width: '100%', gap: '12px',
                                                justifyContent: 'center', maxWidth:'75%',alignItems: 'left', }}>
                                                <TypographyNormal sx={{fontSize:'16px', color: Colors.white_80}}>Welcome</TypographyNormal>
                                                <Box sx={{
                                                display: 'flex', flexDirection: 'row',  width: '200px', height: '32px',alignContent:'flex-start',
                                                justifyContent: 'flex-start',alignItems: 'flex-start', }}>
                                                <img src={whitelogo} alt="logo" style={{width: '100%', height: '100%', }}/>
                                                </Box>
                                                <TypographyNormal sx={{ color: Colors.white_80}}>Find the help you need, right in your neighborhood.</TypographyNormal>
                                             </Box>
                                    </Box> 
                                  


                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', width: {xs: '100%', md:'55%' }, gap: '8px',
                                        justifyContent: 'center',  height: '100%', alignItems: 'center', backgroundColor: Colors.white, border: '1px solid',
                                        borderColor: Colors.white,}}>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '8px',paddingTop:{xs: '30%', md:'20%' },
                                            height:'100%', justifyContent: 'center', maxWidth:'75%',alignItems: 'center',paddingBottom:{xs: '30%', md:'20%' },}}>

                                                    <Box sx={{ gap: '8px' ,  display: 'flex', flexDirection: 'column', justifyContent: 'center',
                                                        alignItems: 'left', width:'100%'}}>
                                                        <TypographyNormal sx={{ fontSize: '24px', color: Colors.black_80, }}>Password recovery</TypographyNormal>
                                                        <TypographyNormal sx={{ paddingTop:{xs:  '8px', md: '16px' },}} >Enter your registered mobile number below to receive an OTP for password reset.</TypographyNormal>
                                                    </Box>
                                                    <Box sx={{ gap: {xs:  '8px', md: '16px' }, display: 'flex', flexDirection: 'column', 
                                                    paddingTop:{xs:  '8px', md: '16px' }, width:'100%'}}>
                                                    
                                                        
                                                        <Box sx={{ gap: {xs:  '8px', md: '24px' }, display: 'flex', flexDirection: 'column', width:'100%'}}>
                                                            <Box sx={{ gap: {xs:  '8px', md: '16px' }, display: 'flex', flexDirection: 'row', width:'100%'}}>
                                                                <Box sx={{ gap: '8px', width:'30%',flexDirection: 'column',  }}>
                                                                    <TypographyNormal sx={{ fontSize:{xs:  '10px', md: '14px' }, color: Colors.black_80, }}>Country code*</TypographyNormal>
                                                                    <TextfieldNormal placeholder="+1" onKeyDown={handleKeyDownCountryCode}
                                                                                    inputProps={{
                                                                                        maxLength: 3,
                                                                                        inputMode: 'numeric', 
                                                                                        pattern: '[0-9]*' 
                                                                                    }}
                                                                                    sx={{
                                                                                        fontSize: { md: '16px', sm: '16px', xs: '14px' },
                                                                                        '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
                                                                                            '-webkit-appearance': 'none',
                                                                                            margin: 0
                                                                                        },
                                                                                        '& input[type=number]': {
                                                                                            '-moz-appearance': 'textfield'
                                                                                        }
                                                                                    }}
                                                                                    value={countryCode}
                                                                                    onChange={handleCountryCodeChange} error={!!countryCodeError}>
                                                                                    </TextfieldNormal> 
                                                                </Box>
                                                                <Box sx={{ gap: '8px', width:'70%',flexDirection: 'column',  }}>
                                                                <TypographyNormal sx={{ fontSize: {xs:  '10px', md: '14px' }, color: Colors.black_80, }}>Phone number*</TypographyNormal>
                                                                <TextfieldNormal placeholder="Add phone number"
                                                                                    onKeyDown={handleKeyDownPhone}
                                                                                    inputProps={{
                                                                                        maxLength: 10,
                                                                                        inputMode: 'numeric', 
                                                                                        pattern: '[0-9]*' 
                                                                                    }}
                                                                                    value={phoneNumber}
                                                                                    sx={{
                                                                                        '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
                                                                                            '-webkit-appearance': 'none',
                                                                                            margin: 0
                                                                                        },
                                                                                        '& input[type=number]': {
                                                                                            '-moz-appearance': 'textfield'
                                                                                        }
                                                                                    }}
                                                                                    onChange={handlePhoneNumberChange} error={!!phoneNumberError}>                                                       
                                                                                    </TextfieldNormal>
                                                                </Box>
                                                            </Box>
                                                            {countryCodeError && !phoneNumberError && <TypographyError sx={{width:'100%',  marginTop:'-16px'}}>{countryCodeError}</TypographyError>}
                                                            {phoneNumberError && !countryCodeError && <TypographyError sx={{width:'100%', paddingLeft:'33%', marginTop:'-16px'}}>{phoneNumberError}</TypographyError>}
                                                            {phoneNumberError && countryCodeError && <TypographyError sx={{width:'100%',  marginTop:'-16px'}}>{countryCodeError} and {phoneNumberError}</TypographyError>}
                                                        </Box>
                                                     

                                                        {signinError && <TypographyError>{signinError}</TypographyError>}
                                                            <Box sx={{ width:'100%',flexDirection: 'column',justifyContent:'center', display:'flex',
                                                            alignItems:'center', alignContent:'center', gap:'8px' , paddingTop:{xs:  '8px', md: '16px' },  }}>
                                                                <ButtonBlue onClick={handleSendOtpClick}>Send OTP</ButtonBlue>
                                                               
                                                            </Box>

                                                    
                                                    </Box>

                                            </Box>
                                    </Box>
                            </Box>
                    
                </Box>
            </Box>
        </Box>}
</Grid>
)
}
export default SpForgotPh;