
import React, { useEffect, useState, useRef } from 'react';
import { Grid, Box, Avatar, Button, IconButton , InputAdornment} from "@mui/material";
import TypographySubHeading from "../Components/TypographySubHeading";
import TypographyHeading  from "../Components/TypographyHeading";
import TypographyNormal from "../Components/TypographyNormal";
import Colors from '../Components/Colors';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import popupbg from '../Images/popup.png'
import whitelogo from '../Images/whitelogo.svg'
import TextfieldNormal from '../Components/TextfieldNormal';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ButtonBlue from '../Components/ButtonBlue';
import ButtonWhite from '../Components/ButtonWhite';
import axios from "axios";
import { API_URL } from '../Components/Constants';
import '../App.css';


function UserTermPopUp(props){

    const {setPopupType, popupType, termsDetails} = props
   
    
    const handleCloseTermsPopUp = () => {
        setPopupType(null)     
    }

    const handleAcceptButtonClick = () => {
        setPopupType('signup') 
        localStorage.setItem("AcceptOrDecline",'Accept')     
    }

    const handleDeclineButtonClick = () => {
        setPopupType('signup')  
        localStorage.setItem("AcceptOrDecline",'Decline')     
    }


  


return(
<Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px' }}>

        {popupType === 'terms' && <Box sx={{
            position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.6)',
            display: 'flex',alignItems: 'center',justifyContent: 'center',zIndex: '1500',

        }}>
            <Box sx={{
                width: '100%', height: '100%', gap: '5px',
                justifyContent: 'center', alignItems: 'center', position: 'fixed',
                display: 'flex', flexDirection: 'column',
            }}>
                <Box sx={{
                    width: { md: '75%', sm: '75%', xs: '85%' }, height: '36px',
                    justifyContent: 'right', alignItems: 'right', display: 'flex',
                }}>
                        <IconButton onClick={handleCloseTermsPopUp} sx={{
                    position: 'fixed', width: '36px', height: '36px',}} >
                        <CloseOutlinedIcon sx={{ width: '36px', height: '36px', color: Colors.black_60, '&:hover': {
                        width: '40px', height: '40px', color: Colors.black_60, backgroundColor: 'none',
                        background:'none'},}} ></CloseOutlinedIcon>
                    </IconButton>
                </Box>
                <Box sx={{width: { md: '75%', sm: '75%', xs: '85%' },height: '90%', 
                        backgroundColor: Colors.white, border: '1px solid',
                        borderColor: Colors.white, borderRadius: '4px ',boxShadow: 'none',
                        zIndex: '3', alignItems: 'center',
                    }}>

                 
                            <Box sx={{
                                display: 'flex', flexDirection: 'row', width: '100%', 
                                justifyContent: 'center', alignContent: 'center', height: '100%'}}>
                                    
                                    <Box sx={{
                                        display: {xs: 'none', md:'flex' }, flexDirection: 'column', width: '45%', gap: '8px',
                                        justifyContent: 'center',  height: '100%', alignItems: 'center', borderRadius: '4px 0px 0px 4px',
                                        backgroundImage: `url(${popupbg})`, backgroundRepeat: 'no-repeat', border:'1px solid', 
                                        backgroundSize: '100% 100%',backgroundPosition: 'center',borderColor: Colors.primary_blue,}}>
                                              <Box sx={{
                                                display: 'flex', flexDirection: 'column', width: '100%', gap: '12px',
                                                justifyContent: 'center', maxWidth:'75%',alignItems: 'left', }}>
                                                <TypographyNormal sx={{fontSize:'16px', color: Colors.white_80}}>Welcome</TypographyNormal>
                                                <Box sx={{
                                                display: 'flex', flexDirection: 'row',  width: '200px', height: '32px',alignContent:'flex-start',
                                                justifyContent: 'flex-start',alignItems: 'flex-start', }}>
                                                <img src={whitelogo} alt="logo" style={{width: '100%', height: '100%', }}/>
                                                </Box>
                                                <TypographyNormal sx={{ color: Colors.white_80}}>Find the help you need, right in your neighborhood.</TypographyNormal>
                                             </Box>
                                    </Box> 
                                  


                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', width: {xs: '100%', md:'55%' }, gap: '8px',
                                        justifyContent: 'center',  height: '100%', alignItems: 'center', backgroundColor: Colors.white, border: '1px solid',
                                        borderColor: Colors.white, overflowX: 'auto',
                                        
                                        '&::-webkit-scrollbar': {
                                            width: '11px', height: '8px', borderRadius: '16px',
                                          },
                                          '&::-webkit-scrollbar-thumb': {
                                            backgroundColor: Colors.grey_30, borderRadius: '16px', border: '2px solid white',
                                          },
                                          '&::-webkit-scrollbar-track': {
                                            borderRadius: '16px', backgroundColor: Colors.white, 
                                          },}}>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '12px',                                           
                                            height:'100%', justifyContent: 'flex-start', maxWidth:'75%',alignItems: 'left',
                                            paddingTop:'5%',}}>
                                               
                                           
                                           <div className="terms-content" dangerouslySetInnerHTML={{ __html:  termsDetails[0].content }} />
                                           
                                            <Box sx={{ width:'100%',flexDirection: 'row',justifyContent:'right', display:'flex',
                                                            alignItems:'right', paddingBottom:'10%', height:'90%' }}>
                                                <Box sx={{ width:'70%',flexDirection: 'row',justifyContent:'center', display:'flex',
                                                    alignItems:'right',  gap:{xs:  '8px', md: '16px' } }}>                                            
                                                    <ButtonWhite onClick={handleDeclineButtonClick}>Decline</ButtonWhite>
                                                    <ButtonBlue onClick={handleAcceptButtonClick}>Accept</ButtonBlue>
                                                </Box>
                                            </Box>



                                            </Box>
                                    </Box>
                            </Box>
                    
                </Box>
            </Box>
        </Box>}

</Grid>
)
}
export default UserTermPopUp;