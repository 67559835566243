import React, { useEffect, useState, useRef } from 'react';
import { Grid, Box, Avatar, TextField, Link, IconButton } from "@mui/material";
import Header from '../Components/Header';
import TypographySubHeading from "../Components/TypographySubHeading";
import TypographyHeading  from "../Components/TypographyHeading";
import TypographyNormal from "../Components/TypographyNormal";
import Footer from "../Components/Footer";
import MailIcon from '@mui/icons-material/Mail';
import Colors from '../Components/Colors';
import axios from "axios";
import { API_URL } from '../Components/Constants';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ButtonBlue from '../Components/ButtonBlue';
import TextfieldNormal from '../Components/TextfieldNormal';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import TypographyError from '../Components/TypographyError';
import popupbg from '../Images/popup.png'
import whitelogo from '../Images/whitelogo.png'
import { Helmet } from 'react-helmet-async';
import logo from '../Images/logo.svg'



function ContactUS(){
    
    const [nickName, setNickName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phoneNo, setPhoneNo] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [isSuccessMessage, setIsSuccessMessage] = useState(false)
    const [nickNameError, setNickNameError] = useState('');
    const [messageError, setMessageError] = useState('');
    const [phoneError, setPhoneError] = useState("");
    const [termsDetails, setTermsDetails] = useState([]);
  
  
    const handleNickNameChange = (event) => {
      setNickName(event.target.value);
      setNickNameError('')
    };
  
    const handleLastNameChange = (event) => {
      setLastName(event.target.value);
    };
  
    const handlePhoneNoChange = (event) => {
      setPhoneNo(event.target.value);
      setPhoneError('')
    };
  
    const handleEmailChange = (event) => {
      setEmail(event.target.value);
    };
  
    const handleMessageChange = (event) => {
      setMessage(event.target.value);
      setMessageError('')
    };
  
   
    const handleFormSubmit = async (event) => {
      event.preventDefault();
      setNickNameError('');
      setPhoneError('');
      setMessageError('');
    let isValid = true;
    if (!nickName) {
      setNickNameError('Nick name is required');
      isValid = false;
    }
    else if (nickName.length > 60) {
      setNickNameError('Nick name is too long.');
      isValid = false;
    }
    const phoneRegex = /^\d{10,12}$/;
    if (!phoneNo) {
      setPhoneError('Phone number is required');
      isValid = false;
    } else if (!phoneRegex.test(phoneNo)) {
      setPhoneError('Phone number must be 10 to 12 digits');
      isValid = false;
    }
  
    if (!message) {
      setMessageError('Message is required');
      isValid = false;
    } 
    else if (message.length > 250) {
      setMessageError('Message is too long.');
      isValid = false;
    } 
    
      if (isValid) {
        try {
          const response = await axios.post(`${API_URL}/contact_form`, {
            nickName: nickName,
            phoneNo: phoneNo,
            message: message,
          }); 
  
          if (response.status === 200) {
            setNickName('');
            setNickNameError('')
            setPhoneNo('');
            setPhoneError('');
            setMessage('');
            setMessageError('');
            setIsSuccessMessage(true);
          }
        } catch (error) {
          console.error("There was an error submitting the form:", error);
        }
      }
    };
  
  
    useEffect(() => {
      if (isSuccessMessage) {
        const timer = setTimeout(() => {
          setIsSuccessMessage(false);
        }, 8000); // 30 seconds      
        return () => clearTimeout(timer);
      }
    }, [isSuccessMessage]);
  
    const handleKeyDownPhone = (event) => {
      const key = event.key;
      if (!/^\d$/.test(key) && key !== 'Backspace' && key !== 'Delete' && key !== 'ArrowLeft' && key !== 'ArrowRight') {
        event.preventDefault();
      }
    };
  
    const [isTermPopUp, setIsTermPopUp] = useState(false);
  
    const handleTermsAndCondtionClick = () => {
      setIsTermPopUp(true)
  
    };
  
    const handleCloseTerm = () => {
      setIsTermPopUp(false)
  
    };

    useEffect(() => {         
      const fetchTermseData = async () => {            
          try { 
              const response = await axios.post(`${API_URL}/select_terms`, {                    
                  title: 'contactus'
              });  
              console.log(response.data.result)
              setTermsDetails(response.data.result);               
          } catch (error) {
              console.error('Error fetching data:', error);
              setTermsDetails('Failed to load Terms and condition. Please try again later.');
          }
      };
      fetchTermseData(); 

  }, []);
  

    return(
        <Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px' }}>

                    <Helmet>
                       <title>Renovation, House Cleaning & Professional Services</title>
                       <meta name="description" content="BroHandy connects you with experts for renovation, house cleaning, furniture assembly, home repairs, and more. Hire professional service providers today!" />
                       <link rel="canonical" href="https://brohandy.com/contact-us" />
                       <meta property="og:title" content="Local Service Providers for Home Services | BroHandy" />
                       <meta property="og:description" content="BroHandy connects you with experts for renovation, house cleaning, furniture assembly, home repairs, and more. Hire professional service providers today!" />
                       <meta property="og:image" content={logo} />
                       <meta property="og:url" content="https://brohandy.com/contact-us" />
                       <meta property="og:type" content="website" />
                       <meta property="og:site_name" content="BroHandy" />
                       <script type="application/ld+json">
                           {`
                           {
                               "@context": "http://schema.org",
                               "@type": "WebPage",
                               "name": "Contact Us - BroHandy",
                               "url": "https://brohandy.com/contact-us",
                               "description": "BroHandy connects you with experts for renovation, house cleaning, furniture assembly, home repairs, and more. Hire professional service providers today!",
                               "mainEntityOfPage": "https://brohandy.com/contact-us",
                               "potentialAction": {
                               "@type": "ContactAction",
                               "target": "contact@brohandy.com",
                               "query-input": "required name=email"
                               }
                           }
                           `}
                       </script>
                   </Helmet>
                   <h1 style={{ position: 'absolute', left: '-999999px' }}>Professional Services</h1>
       
       
       
        <Header currentPage={"Contact us"}></Header>



        <Box sx={{
            width: '100%', height: { xs: '95%', md: '97.5%' },justifyContent: 'center', alignItems: 'center', display:  'flex', 
            flexDirection: 'column',gap:'24px', paddingTop:{ xs: '5%', md: '2.5%' }}}>
                <TypographySubHeading sx={{fontSize: { xs: '16px', md: '28px' }, fontWeight:600, lineHeight:{ xs: '24px', md: '36px' }, color: Colors.dark_purple}}>Contact us</TypographySubHeading>
                <Box sx={{width: '100%', height: '95%', justifyContent: 'center', alignItems: 'center', display:  'flex', 
                      flexDirection: 'column',maxWidth:'92.5%',gap:'20px', paddingBottom:'5%'}}>
                         <TypographySubHeading component="h3" sx={{fontSize:{ xs: '24px', md: '24px' } , fontWeight:600, lineHeight:{ xs: '30px', md: '28px' } }}>We're Here for You</TypographySubHeading>
                    <Box sx={{width: '100%', height: '100%', justifyContent: 'center', alignItems: 'left', display:  'flex', 
                             flexDirection: 'column',gap:'16px'}}>
                       
                        <TypographyNormal component="h4" sx={{lineHeight:'24px'}}>Thank you for your interest in connecting with us! We value your feedback, questions, and any inquiries you may have. Please use the form below to provide your details and message. If you prefer, you can also reach us directly through the contact information provided. We are dedicated to ensuring that your experience with us is positive and fulfilling, and we appreciate your patience as we strive to offer you the best service. Whether you have a question about our products, need support with an order, or simply want to share your thoughts, we are here to help. Thank you for choosing to engage with us – we look forward to hearing from you!</TypographyNormal>
                    </Box>  

                    <Box sx={{width: '100%', height: '100%', justifyContent: 'center', alignItems: 'left', display:  'flex', 
                             flexDirection: 'column',gap:'8px'}}>
                        <TypographyNormal component="h5" sx={{lineHeight:'24px'}}>If you have any questions about this privacy statement or our treatment of the information you provide us, please write to us by email at </TypographyNormal>
                        <TypographyNormal 
                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left', cursor: 'pointer', 
                            color: Colors.blue_6, fontSize:'16px' }}>
                        <MailIcon sx={{ color: Colors.black_70, fontSize:'16px'  }} />                       
                        <a href="mailto:contact@brohandy.com" style={{ textDecoration: 'none', color: 'inherit', paddingLeft:'4px' }}>
                            contact@brohandy.com
                        </a>
                        </TypographyNormal>

                      </Box> 

                     

            {!isSuccessMessage &&
            <Box sx={{width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', display:  'flex', 
                flexDirection: 'column',gap:'8px', border:'1px solid', borderColor: Colors.black_60, borderRadius:'4px',}}>
             
              <Box sx={{width: '100%', height: '95%', justifyContent: 'center', alignItems: 'center',
                             display:  'flex', maxWidth: { xs:'90%', md:'95%' } , flexDirection: 'column',gap:'8px',
                             paddingTop:{ xs:'5%', md:'2.5%' }, paddingBottom: { xs:'5%', md:'2.5%' } , }}>
                <Box sx={{
                  width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',
                  gap: '16px', flexDirection: 'column', }}>

                  <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column' }, width: '100%', justifyContent: 'center', alignItems: 'stretch', gap: '16px' }}>
                    <Box sx={{ gap: '10px', display: 'flex', flexDirection: 'column', width: '100%', flex:1 }}>
                      <TypographyNormal sx={{ fontSize: '14px', color: Colors.black_70 }}>Nick name</TypographyNormal>
                      <TextfieldNormal
                        variant="outlined"
                        value={nickName}
                        placeholder="Enter your Nick name"
                        error={!!nickNameError}
                        onChange={handleNickNameChange} />
                         {nickNameError && <TypographyError >{nickNameError}</TypographyError>}
                    </Box>
                    <Box sx={{ gap: '10px', display: 'flex', flexDirection: 'column', width: '100%',flex:1 }}>
                      <TypographyNormal sx={{ fontSize: '14px', color: Colors.black_70 }}>Phone number</TypographyNormal>
                      <TextfieldNormal
                        variant="outlined"
                        value={phoneNo}
                        placeholder="Enter your phone number"
                        onChange={handlePhoneNoChange}
                        onKeyDown={handleKeyDownPhone}
                        inputProps={{ maxLength: 12 }}
                        error={!!phoneError} />
                        {phoneError && <TypographyError >{phoneError}</TypographyError>}
                    </Box>
                    
                  </Box>                 

                  <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center', gap: '16px' }}>
                    <Box sx={{ gap: '10px', display: 'flex', flexDirection: 'column', width: '100%' }}>
                      <TypographyNormal sx={{ fontSize: '14px', color: Colors.black_70 }}>Message</TypographyNormal>

                      <TextField variant="outlined" multiline rows={3} placeholder="Type your queries"
                        value={message}
                        onChange={handleMessageChange}
                        error={!!messageError}
                        sx={{
                            height: '84px',
                            width: '100%',
                            padding:'0px !important',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                border: '1px solid',
                                borderColor: messageError ? Colors.red : Colors.black_60,
                                transition: 'border-color 0.3s ease',
                              },
                              '&:hover fieldset': {
                                borderColor: messageError ? Colors.red : Colors.black_80, 
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: messageError ? Colors.red : Colors.black_80,
                              },
                              '& .MuiInputBase-input::placeholder': {
                                fontFamily: 'Roboto',lineHeight:'16px',
                                fontSize: {xs:  '12px', md: '14px' },
                                fontWeight: 400,
                                color: Colors.black_70,
                                padding:{xs:'0px !important', md: '0px 0px !important' },
                              },
                              '& .MuiInputBase-input': { 
                                padding:{xs:'0px !important', md: '0px 0px !important' },                                                               
                                fontSize: {xs:  '12px', md: '14px' }, lineHeight:'18px',
                                fontFamily: 'Roboto',
                                fontWeight: 400,
                                color: Colors.black_80,
                              },
                              '&.css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root':{
                                padding:{xs:'8px 12px', md: '10px 16px' }, 
                              }
                            },                           
                          }}
                      />

                       {messageError && <TypographyError>{messageError}</TypographyError>}
                    </Box>
                  </Box>

                  <Box sx={{
                  width: '100%', height: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center',
                  gap: '16px', flexDirection: { xs:'column', md: 'row' } ,
                }}>
                  <TypographyNormal component="h6" sx={{ fontSize: '14px', textAlign: 'left', width: '100%' }}>
                    By clicking the submit button, you agree to our{' '}
                    <Link onClick={handleTermsAndCondtionClick} style={{ textDecoration: 'none', color: Colors.link, cursor:'pointer' }}>
                      Terms and conditions
                    </Link>.
                  </TypographyNormal>

                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                    <ButtonBlue onClick={handleFormSubmit} variant="contained" type="submit" sx={{ height: { md: '40px', xs: '38px' }, width: { md: '25%', xs: '47%' } }}>
                      Submit
                    </ButtonBlue>
                    </Box>
                  </Box>

                </Box>
              </Box>             

            </Box>}
            
          {isSuccessMessage &&
            <Box sx={{
              width: '100%', height: '420px', display: 'flex', justifyContent: 'center',
              alignItems: 'flex-start', flexDirection: 'row', gap: '8px',
            }}>
              <Box sx={{
                width: '100%',  height: {xs:'180px',md:'74px',sm:'180px'}, display: 'flex', justifyContent:{ xs:'center', md: 'left' } ,
                alignItems: 'center', flexDirection: { xs:'column', md: 'row' }, gap: '8px', background: Colors.white_80,
                borderRadius: '4px', padding:{ xs:'16px', md: '0px 16px' }
              }}>
                <CheckCircleIcon style={{ color: '#0BEA14', backgroundColor: '#FFFFFF', borderRadius: '50%', }} />
                <TypographyNormal component="h6" sx={{
                  fontFamily: 'Roboto', fontWeight: 400, fontSize: '14px', color: Colors.black_80, textAlign:{ xs:'center', md: 'left' } ,
                }}>
                  Your message has been sent successfully. We appreciate you reaching out to us. Our team will get back to you as soon as possible.
                </TypographyNormal>
              </Box>
            </Box>}  
               </Box>
       
        </Box>

        <Footer currentPageFooter={"Contact us"}></Footer>

        {isTermPopUp && (
           <Box sx={{
            position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.6)',
            display: 'flex',alignItems: 'center',justifyContent: 'center',zIndex: '2',

        }}>
            <Box sx={{
                width: '100%', height: '100%', gap: '5px',
                justifyContent: 'center', alignItems: 'center', position: 'fixed',
                display: 'flex', flexDirection: 'column',
            }}>
                <Box sx={{
                    width: { md: '75%', sm: '75%', xs: '85%' }, height: '36px',
                    justifyContent: 'right', alignItems: 'right', display: 'flex',
                }}>
                     <IconButton onClick={handleCloseTerm} sx={{
                    position: 'fixed', width: '36px', height: '36px',}} >
                        <CloseOutlinedIcon sx={{ width: '36px', height: '36px', color: Colors.black_60, '&:hover': {
                        width: '40px', height: '40px', color: Colors.black_60, backgroundColor: 'none',
                        background:'none'},}} ></CloseOutlinedIcon>
                    </IconButton>
                </Box>
                <Box sx={{width: { md: '75%', sm: '75%', xs: '85%' },height: '90%', 
                        backgroundColor: Colors.white, border: '1px solid',
                        borderColor: Colors.white, borderRadius: '4px ',boxShadow: 'none',
                        zIndex: '3', alignItems: 'center', 
                    }}>

                 
                            <Box sx={{
                                display: 'flex', flexDirection: 'row', width: '100%', 
                                justifyContent: 'center', alignContent: 'center', height: '100%'}}>
                                    
                                    <Box sx={{
                                        display:  {xs: 'none', md:'flex' }, flexDirection: 'column', width: '50%', gap: '8px',
                                        justifyContent: 'center',  height: '100%', alignItems: 'center',
                                        backgroundImage: `url(${popupbg})`, backgroundRepeat: 'no-repeat',
                                        backgroundSize: '100% 100%',}}>
                                              <Box sx={{
                                                display: 'flex', flexDirection: 'column', width: '100%', gap: '12px',
                                                justifyContent: 'center', maxWidth:'75%',alignItems: 'left', }}>
                                                <TypographyNormal sx={{fontSize:'16px', color: Colors.white_80}}>Welcome</TypographyNormal>
                                                <Box sx={{
                                                display: 'flex', flexDirection: 'column',  width: '224px', height: '32px',
                                                justifyContent: 'center',alignItems: 'left', }}>
                                                <img src={whitelogo} alt="logo" style={{width: '100%', height: '100%', }}/>
                                                </Box>
                                                <TypographyNormal sx={{ color: Colors.white_80}}>Find the help you need, right in your neighborhood.</TypographyNormal>
                                             </Box>
                                    </Box>
                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', width: {xs: '100%', md:'50%' }, gap: '8px',
                                        justifyContent: 'flex-start',  height: '100%', alignItems: 'center',overflowX: 'auto',
                                        
                                        '&::-webkit-scrollbar': {
                                            width: '11px', height: '8px', borderRadius: '16px',
                                          },
                                          '&::-webkit-scrollbar-thumb': {
                                            backgroundColor: Colors.grey_30, borderRadius: '16px', border: '2px solid white',
                                          },
                                          '&::-webkit-scrollbar-track': {
                                            borderRadius: '16px', backgroundColor: Colors.white, 
                                          },}}>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '12px',                                           
                                            height:'100%', justifyContent: 'flex-start', maxWidth:'75%',alignItems: 'left',
                                            paddingTop:'5%',}}>
                                           <div dangerouslySetInnerHTML={{ __html:  termsDetails[0].content }} />
                                            </Box>
                                    </Box>
                            </Box>
                    
                </Box>
            </Box>
        </Box>)}


        </Grid>
    )
}
export default ContactUS;