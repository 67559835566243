
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import UserProtectedRoute from './Components/UserProtectedRoute';
import SpProtectedRoute from './Components/SpProtectedRoute';
import AssociateProtectedRoute from './Components/AssociateProtectRoute';
import OperationTeamProtectedRoute from './Components/OperationTeamProtectedRoute';

import Home from './Pages/Home';
import SearchResultSpList from './Pages/SearchResultSpList';
import AboutUs from './Pages/AboutUs';
import ContactUS from './Pages/ContactUs';
import Blog from './Pages/Blog';
import Disclaimer from './Pages/Disclaimer';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import TermsOfUse from './Pages/TermsOfUse';
import SpProfile from './Pages/SpProfile';
import UserEditProfile from './Pages/EditUserProfile';
import SpLanding from './Pages/SpLanding';
import AddSpProfile from './Pages/AddSpProfile';
import AddSpLocation from './Pages/AddSpLocation';
import AddSpService from './Pages/AddSpService';
import AssociateSignIn from './Pages/AssociateSignIn';
import AssociateLanding from './Pages/AssociateLanding';
import AssociateService from './Pages/AssociateService';
import AssociateProfile from './Pages/AssociateProfile';
import AssociateLocation from './Pages/AssociateLocation';
import AssociateSupportTeam from './Pages/SupportTeam';
import SpDisableAccount from './Pages/SpDisableAccount';
import SpDeleteAccount from './Pages/SpDeleteAccount';
import UserDeleteAccount from './Pages/UserDeleteAccount';
import OperationTeamLogin from './Pages/OperationTeamLogin';
import OperationTeamLanding from './Pages/OperationTeamLanding';
import AdminSignIn from './Pages/AdminSignIn';
import AdminLanding from './Pages/AdminLanding';
import AssignSupportTeam from './Pages/AssignSupportTeam';
import SpViewMessage from './Pages/SpViewMessage';
import BlogDetail from './Pages/BlogDetail';

import { HelmetProvider } from 'react-helmet-async';




function App() {
  return (
    <div style={{ height: '100%' }}>
      <HelmetProvider>
      <BrowserRouter>
        <Routes>
        <Route  path="/" element={localStorage.getItem("spToken") ? <SpProtectedRoute element={<SpLanding />} /> : <Home />}/>

          <Route path="/home" element={<Home/>} /> 
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/search-result-splist" element={<SearchResultSpList/>} /> 
          <Route path="/about-us" element={<AboutUs/>} /> 
          <Route path="/contact-us" element={<ContactUS/>} />
          <Route path="/blog" element={<Blog/>} /> 
          <Route path="/blog/:blogTitle" element={<BlogDetail />} />

          <Route path="/disclaimer" element={<Disclaimer/>} /> 
          <Route path="/privacy-policy" element={<PrivacyPolicy/>} />  
          <Route path="/terms-and-conditions" element={<TermsOfUse/>} /> 
          <Route path="/sp-profile/:spCode" element={<SpProfile />} />  

          <Route path="/user-edit-profile" element={<UserProtectedRoute element={<UserEditProfile />} />} />
          <Route path="/user-delete-account" element={<UserProtectedRoute element={<UserDeleteAccount />} />} />

          <Route path="/sp-landing" element={<SpProtectedRoute element={<SpLanding />} />} />
          <Route path="/service-provider-profile" element={<SpProtectedRoute element={<AddSpProfile />} />} />
          <Route path="/service-provider-service" element={<SpProtectedRoute element={<AddSpService />} />} />
          <Route path="/service-provider-location" element={<SpProtectedRoute element={<AddSpLocation />} />} />
          <Route path="/sp-disable-account" element={<SpProtectedRoute element={<SpDisableAccount />} />} />
          <Route path="/sp-delete-account" element={<SpProtectedRoute element={<SpDeleteAccount />} />} />
          <Route path="/view-message" element={<SpProtectedRoute element={<SpViewMessage />} />} />
          
          
          <Route path="/associate-login" element={<AssociateSignIn/>} />
          <Route path="/associate-landing" element={<AssociateProtectedRoute element={<AssociateLanding />} />} />
          <Route path="/associate-profile" element={<AssociateProtectedRoute element={<AssociateProfile />} />} />
          <Route path="/associate-service" element={<AssociateProtectedRoute element={<AssociateService />} />} />
          <Route path="/associate-location" element={<AssociateProtectedRoute element={<AssociateLocation />} />} />
          
          <Route path="/admin-login" element={<AdminSignIn/>} />
          <Route path="/admin-landing" element={<AdminLanding/>} />

          <Route path="/associate-support-team" element={<AssociateProtectedRoute element={<AssociateSupportTeam />} />} />
          <Route path="/assign-support-team" element={<AssociateProtectedRoute element={<AssignSupportTeam />} />} />

          <Route path="/operation-team-login"  element={<OperationTeamLogin />} />
          <Route path="/operation-team-landing" element={<OperationTeamProtectedRoute element={<OperationTeamLanding />} />} />
        </Routes>
      </BrowserRouter>
      </HelmetProvider>
    </div>
  );
}

export default App;


