
import React, { useEffect, useState, useRef } from 'react';
import { Grid, Box, Avatar, Button, IconButton , InputAdornment} from "@mui/material";
import TypographySubHeading from "../Components/TypographySubHeading";
import TypographyHeading  from "../Components/TypographyHeading";
import TypographyNormal from "../Components/TypographyNormal";
import Colors from '../Components/Colors';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import popupbg from '../Images/popup.png'
import whitelogo from '../Images/whitelogo.svg'
import TextfieldNormal from '../Components/TextfieldNormal';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ButtonBlue from '../Components/ButtonBlue';
import TypographyError from '../Components/TypographyError';
import UserTermPopUp from '../Pages/UserTermPopUp';
import axios from "axios";
import { API_URL } from '../Components/Constants';
import passwordvisible from '../Images/passwordvisible.svg'
import passwordhide from '../Images/passwordhide.svg'




function UserSignUp(props){

    const {setPopupType, popupType} = props


    const [nickName, setNickName] = useState("");
    const [nickNameError, setNickNameError] = useState("");
    const [countryCode, setCountryCode] = useState("1");
    const [countryCodeError, setCountryCodeError] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState("");
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [zipCode, setZipCode] = useState("");
    const [zipCodeError, setZipCodeError] = useState("");
    const [signupError, setSignupError] = useState("");

    const [signUpData, setSignUpData] = useState({
        nickName: "",
        countryCode: "1",
        phoneNumber: "",
        password: "", 
        zipCode: ""       
    });


    const handleNickNameChange = (event) => {
        setNickName(event.target.value);
        setNickNameError("");
        setSignUpData((prevData) => ({
            ...prevData,
            nickName: event.target.value,
        }));
    };

    const handleCountryCodeChange = (event) => {
        setCountryCode(event.target.value);
        setCountryCodeError("");
        setSignUpData((prevData) => ({
            ...prevData,
            countryCode: event.target.value,
        }));
    };

    const handleKeyDownCountryCode = (event) => {
        const key = event.key;
        if (!/^\d$/.test(key) && key !== 'Backspace' && key !== 'Delete' && key !== 'ArrowLeft' && key !== 'ArrowRight') {
            event.preventDefault();
        }
    };

    const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value);
        setPhoneNumberError("");
        setSignUpData((prevData) => ({
            ...prevData,
            phoneNumber: event.target.value,
        }));
    };

    const handleKeyDownPhone = (event) => {
        const key = event.key;
        if (!/^\d$/.test(key) && key !== 'Backspace' && key !== 'Delete' && key !== 'ArrowLeft' && key !== 'ArrowRight') {
            event.preventDefault();
        }
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        setPasswordError("");
        setSignUpData((prevData) => ({
            ...prevData,
            password: event.target.value,
        }));
    };

    const handleZipCodeChange = (event) => {
        setZipCode(event.target.value);
        setZipCodeError("");
        setSignUpData((prevData) => ({
            ...prevData,
            zipCode: event.target.value,
        }));
    };

    const handleTermsAndconditionsButtonClick = () => {
        setPopupType('terms');
        localStorage.setItem('signUpData', JSON.stringify(signUpData));
    }

    const handleSignInButtonClick = () => {
        setPopupType('signin');       
    }

    const handleSignUpClick = async () => {       
                 
            if (nickName.trim() === "") {
                setNickNameError("Please enter your name");
            }
            else if (nickName.length > 60) {
                setNickNameError("Nick name is too long.");
            } 
            else if (countryCode.trim() === "") {
                setCountryCodeError("Please enter your country code");
            }            
            else if (phoneNumber.trim() === "") {
                setPhoneNumberError("Please enter your phone number");
            }
            else if (phoneNumber.trim() !== "" && phoneNumber.length !== 10) {
                setPhoneNumberError('Phone number must be 10 digits');
            }
            else if (password.trim() === "") {
                setPasswordError("Please create a password");
            }
            else if (password.length > 64) {
                setPasswordError("Password is too long.");
            }
            else if (password.length < 4) {
                setPasswordError("Password is too short.");
            }
            else if (zipCode.trim() === "") {
                setZipCodeError("Please enter your zip code");
            }
            else if (zipCode.trim() !== "" && zipCode.length !== 6) {
                setZipCodeError('zip code must be 6 digits');
            }
           
        else {

          
            try {
                const response = await axios.post(`${API_URL}/check_phone_number_exist`,
                    {
                        countryCode: countryCode,
                        phone: phoneNumber,
                    }); 
                    console.log(response.data.error)                
                if (response.data.success === true) {
                    try {
                     const response = await axios.post(`${API_URL}/otp_creation`,
                    {
                        countryCode: countryCode,
                        phone: phoneNumber,
                    });                  
                        if (response.data.success === true) {
                            setPopupType('otp');
                            localStorage.setItem('signUpData', JSON.stringify(signUpData));
                        }               
                    }
                    catch (error) {
                        // Handle errors from the OTP creation API call
                        console.error("OTP creation request failed:", error);
            
                        if (error.response && error.response.data) {
                            // API returned a response with an error message
                            setSignupError(error.response.data.message || 'OTP creation failed.');
                        } else {
                            // Network or other unknown error
                            setSignupError('An error occurred while creating the OTP. Please try again.');
                        }
                    }
                }
                else{
                    setSignupError(response.data.error)
                }
               
            }
            catch (error) {
                // Handle errors from the phone number check API call
                console.error("Phone number check request failed:", error);
            
                if (error.response && error.response.data) {
                    // API returned a response with an error message
                    setSignupError(error.response.data.message || 'Phone number check failed.');
                } else {
                    // Network or other unknown error
                    setSignupError('An error occurred while checking the phone number. Please try again.');
                }
            }
        
         }
    };


    useEffect(() => {
        if (localStorage.getItem("AcceptOrDecline")) {           
           if(localStorage.getItem("AcceptOrDecline") === 'Decline'){
            setSignUpData({
                nickName: "",
                countryCode: "1",
                phoneNumber: "",
                password: "",
                zipCode: ""
              });             
           }
           else{
            const savedData = JSON.parse(localStorage.getItem('signUpData'));
            if (savedData) {
              setSignUpData(savedData);
              console.log(savedData)
              setNickName(savedData.nickName)
              setCountryCode(savedData.countryCode)
              setPhoneNumber(savedData.phoneNumber)
              setPassword(savedData.password)
              setZipCode(savedData.zipCode)
            }
           }
        }
    }, []);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleCloseSignUpPopUp = () => {
        setPopupType(null);     
    }



return(
<Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px' }}>

        {popupType === 'signup' && <Box sx={{
            position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.6)',
            display: 'flex',alignItems: 'center',justifyContent: 'center',zIndex: '1500',

        }}>
            <Box sx={{
                width: '100%', height: '100%', gap: '5px',
                justifyContent: 'center', alignItems: 'center', position: 'fixed',
                display: 'flex', flexDirection: 'column',
            }}>
                <Box sx={{
                    width: { md: '75%', sm: '75%', xs: '85%' }, height: '36px',
                    justifyContent: 'right', alignItems: 'right', display: 'flex',
                }}>
                        <IconButton onClick={handleCloseSignUpPopUp} sx={{
                    position: 'fixed', width: '36px', height: '36px',}} >
                        <CloseOutlinedIcon sx={{ width: '36px', height: '36px', color: Colors.black_60, '&:hover': {
                        width: '40px', height: '40px', color: Colors.black_60, backgroundColor: 'none',
                        background:'none'},}} ></CloseOutlinedIcon>
                    </IconButton>
                </Box>
                <Box sx={{width: { md: '75%', sm: '75%', xs: '85%' },height: 'fit-content',
                        backgroundColor: Colors.white, border: '1px solid',
                        borderColor: Colors.white, borderRadius: '4px ',boxShadow: 'none',
                        zIndex: '3', alignItems: 'center', 
                    }}>

                 
                            <Box sx={{
                                display: 'flex', flexDirection: 'row', width: '100%', 
                                justifyContent: 'center', alignContent: 'center', height: '100%'}}>
                                    
                                    <Box sx={{
                                        display: {xs: 'none', md:'flex' }, flexDirection: 'column', width: '45%', gap: '8px',
                                        justifyContent: 'center',  height: '100%', alignItems: 'center', borderRadius: '4px 0px 0px 4px',
                                        backgroundImage: `url(${popupbg})`, backgroundRepeat: 'no-repeat', border:'1px solid', 
                                        backgroundSize: '100% 100%',backgroundPosition: 'center',borderColor: Colors.primary_blue,}}>
                                              <Box sx={{
                                                display: 'flex', flexDirection: 'column', width: '100%', gap: '12px',
                                                justifyContent: 'center', maxWidth:'75%',alignItems: 'left', }}>
                                                <TypographyNormal sx={{fontSize:'16px', color: Colors.white_80}}>Welcome</TypographyNormal>
                                                <Box sx={{
                                                display: 'flex', flexDirection: 'row',  width: '200px', height: '32px',alignContent:'flex-start',
                                                justifyContent: 'flex-start',alignItems: 'flex-start', }}>
                                                <img src={whitelogo} alt="logo" style={{width: '100%', height: '100%', }}/>
                                                </Box>
                                                <TypographyNormal sx={{ color: Colors.white_80}}>Find the help you need, right in your neighborhood.</TypographyNormal>
                                             </Box>
                                    </Box> 
                                  


                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', width: {xs: '100%', md:'55%' }, gap: '8px',
                                        justifyContent: 'center',  height: '100%', alignItems: 'center', backgroundColor: Colors.white, border: '1px solid',
                                        borderColor: Colors.white,}}>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '8px',paddingTop:'10%',
                                            height:'100%', justifyContent: 'center', maxWidth:'75%',alignItems: 'center',paddingBottom:'10%'}}>

                                                    <Box sx={{ gap: '8px' ,  display: 'flex', flexDirection: 'column', justifyContent: 'center',
                                                        alignItems: 'left', width:'100%'}}>
                                                        <TypographyNormal sx={{ fontSize: '24px', color: Colors.black_80, }}>Create an account</TypographyNormal>
                                                        <TypographyNormal >Welcome to Brohandy</TypographyNormal>
                                                    </Box>
                                                    <Box sx={{ gap: {xs:  '8px', md: '16px' }, display: 'flex', flexDirection: 'column', paddingTop:{xs:  '8px', md: '16px' }, width:'100%'}}>
                                                    
                                                        <Box sx={{ gap: '8px', width:'100%',flexDirection: 'column',  }}>
                                                            <TypographyNormal sx={{ fontSize: {xs:  '10px', md: '14px' }, color: Colors.black_80, }}>Nick name*</TypographyNormal>
                                                            <TextfieldNormal placeholder="Enter your nick name"
                                                                value={nickName}
                                                                onChange={handleNickNameChange} error={!!nickNameError}></TextfieldNormal>
                                                            {nickNameError && <TypographyError>{nickNameError}</TypographyError>}
                                                        </Box>

                                                        <Box sx={{ gap: '24px', display: 'flex', flexDirection: 'column', width:'100%'}}>
                                                            <Box sx={{ gap:{xs:  '8px', md: '16px' }, display: 'flex', flexDirection: 'row', width:'100%'}}>
                                                                <Box sx={{ gap: '8px', width:'30%',flexDirection: 'column',  }}>
                                                                    <TypographyNormal sx={{ fontSize: {xs:  '10px', md: '14px' }, color: Colors.black_80, }}>Country code*</TypographyNormal>
                                                                    <TextfieldNormal placeholder="+1" onKeyDown={handleKeyDownCountryCode}
                                                                                    inputProps={{
                                                                                        maxLength: 3,
                                                                                        inputMode: 'numeric', 
                                                                                        pattern: '[0-9]*' 
                                                                                    }}
                                                                                    sx={{
                                                                                        fontSize: { md: '16px', sm: '16px', xs: '14px' },
                                                                                        '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
                                                                                            '-webkit-appearance': 'none',
                                                                                            margin: 0
                                                                                        },
                                                                                        '& input[type=number]': {
                                                                                            '-moz-appearance': 'textfield'
                                                                                        }
                                                                                    }}
                                                                                    value={countryCode}
                                                                                    onChange={handleCountryCodeChange} error={!!countryCodeError}>
                                                                                    </TextfieldNormal> 
                                                                </Box>
                                                                <Box sx={{ gap: '8px', width:'70%',flexDirection: 'column',  }}>
                                                                <TypographyNormal sx={{ fontSize: {xs:  '10px', md: '14px' }, color: Colors.black_80, }}>Phone number*</TypographyNormal>
                                                                <TextfieldNormal placeholder="Add phone number"
                                                                                    onKeyDown={handleKeyDownPhone}
                                                                                    inputProps={{
                                                                                        maxLength: 10,
                                                                                        inputMode: 'numeric', 
                                                                                        pattern: '[0-9]*' 
                                                                                    }}
                                                                                    value={phoneNumber}
                                                                                    sx={{
                                                                                        '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
                                                                                            '-webkit-appearance': 'none',
                                                                                            margin: 0
                                                                                        },
                                                                                        '& input[type=number]': {
                                                                                            '-moz-appearance': 'textfield'
                                                                                        }
                                                                                    }}
                                                                                    onChange={handlePhoneNumberChange} error={!!phoneNumberError}>                                                       
                                                                                    </TextfieldNormal>
                                                                </Box>
                                                            </Box>
                                                            {countryCodeError &&<TypographyError sx={{width:'100%',  marginTop:'-20px'}}>{countryCodeError}</TypographyError>}
                                                            {phoneNumberError &&<TypographyError sx={{width:'100%', paddingLeft:'33%', marginTop:'-20px'}}>{phoneNumberError}</TypographyError>}
                                                        </Box>

                                                        <Box sx={{ gap: '8px', width:'100%',flexDirection: 'column',  }}>
                                                            <TypographyNormal sx={{ fontSize: {xs:  '10px', md: '14px' }, color: Colors.black_80, }}>Password*</TypographyNormal>
                                                            <TextfieldNormal placeholder="Enter your password"
                                                               type={showPassword ? 'text' : 'password'}
                                                                value={password}
                                                                onChange={handlePasswordChange} error={!!passwordError}
                                                                InputProps={{
                                                                    endAdornment: password &&(
                                                                        <InputAdornment position="end">
                                                                            <IconButton
                                                                                onClick={togglePasswordVisibility}
                                                                                edge="end"
                                                                                aria-label="toggle password visibility"
                                                                                color="primary">
                                                                                {showPassword ? (
                                                                                    <img src={passwordvisible} alt="password Icon" />
                                                                                ) : (
                                                                                    <img src={passwordhide} alt="password Icon" />
                                                                                )}
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}></TextfieldNormal>
                                                            {passwordError && <TypographyError>{passwordError}</TypographyError>}
                                                        </Box>

                                                        <Box sx={{ gap: '8px', width:'100%',flexDirection: 'column',  }}>
                                                            <TypographyNormal sx={{ fontSize: {xs:  '10px', md: '14px' }, color: Colors.black_80, }}>Zip code*</TypographyNormal>
                                                            <TextfieldNormal placeholder="Enter your zip code"
                                                                value={zipCode} inputProps={{ maxLength: 6 }} 
                                                                onChange={handleZipCodeChange} error={!!zipCodeError}></TextfieldNormal>
                                                            {zipCodeError && <TypographyError>{zipCodeError}</TypographyError>}
                                                        </Box>

                                                        <Box sx={{ gap: '8px', width:'100%',flexDirection: 'column',paddingTop:{xs:  '8px', md: '12px' }  }}>
                                                        <TypographyNormal sx={{fontSize:{xs:  '10px', md: '12px' }}}>
                                                        By clicking the Sign up button, you agree to our
                                                                <TypographyNormal
                                                                component="span"
                                                                sx={{ color: Colors.blue_6, display: 'inline', marginLeft: {xs:  '4px', md: '8px' }, fontSize:{xs:  '10px', md: '12px' }, cursor:'pointer' }}
                                                                onClick={handleTermsAndconditionsButtonClick}>
                                                                Terms and conditions.
                                                                </TypographyNormal>
                                                        </TypographyNormal>
                                                        </Box> 

                                                        {signupError && <TypographyError>{signupError}</TypographyError>}
                                                            <Box sx={{ width:'100%',flexDirection: 'column',justifyContent:'center', display:'flex',
                                                            alignItems:'center', alignContent:'center', gap:'8px'  }}>
                                                                <ButtonBlue onClick={handleSignUpClick}>Sign up</ButtonBlue>
                                                                <TypographyNormal sx={{fontSize:{xs:  '12px', md: '16px' }}}>
                                                                Already have an account?
                                                                    <TypographyNormal
                                                                    component="span"
                                                                    sx={{ color: Colors.blue_6, display: 'inline', marginLeft: {xs:  '4px', md: '8px' }, fontSize:{xs:  '12px', md: '16px' }, cursor:'pointer' }}
                                                                    onClick={handleSignInButtonClick}>
                                                                    Sign in
                                                                    </TypographyNormal>
                                                                </TypographyNormal>
                                                            </Box>

                                                    
                                                    </Box>

                                            </Box>
                                    </Box>
                            </Box>
                    
                </Box>
            </Box>
        </Box>}
</Grid>
)
}
export default UserSignUp;