import React, { useEffect, useState, useRef } from 'react';
import { Grid, Box, Avatar, Button } from "@mui/material";
import Header from '../Components/Header';
import TypographySubHeading from "../Components/TypographySubHeading";
import TypographyHeading  from "../Components/TypographyHeading";
import TypographyNormal from "../Components/TypographyNormal";
import Footer from "../Components/Footer";
import Colors from '../Components/Colors';
import { Helmet } from 'react-helmet-async';
import logo from '../Images/logo.svg'

function AboutUs(){
    
    return(
        <Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px' }}>

            <Helmet>
                <title>Local Service Providers for Home Services | BroHandy</title>
                <meta name="description" content="your trusted platform for local service providers. Find expert home services, including plumbing, electrical, and repairs, all in one place." />
                <link rel="canonical" href="https://brohandy.com/about-us" />
                <meta property="og:title" content="Local Service Providers for Home Services | BroHandy" />
                <meta property="og:description" content="your trusted platform for local service providers. Find expert home services, including plumbing, electrical, and repairs, all in one place." />
                <meta property="og:image" content={logo} />
                <meta property="og:url" content="https://brohandy.com/about-us" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="BroHandy" />
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "http://schema.org",
                        "@type": "WebPage",
                        "name": "About Us - BroHandy",
                        "url": "https://brohandy.com/about-us",
                        "description": "your trusted platform for local service providers. Find expert home services, including plumbing, electrical, and repairs, all in one place.",
                        "mainEntityOfPage": "https://brohandy.com/about-us",
                        "potentialAction": {
                        "@type": "ContactAction",
                        "target": "contact@brohandy.com",
                        "query-input": "required name=email"
                        }
                    }
                    `}
                </script>
            </Helmet>
            <h1 style={{ position: 'absolute', left: '-999999px' }}>Local Service Providers</h1>



       
        <Header currentPage={"About us"}></Header>

        <Box sx={{
            width: '100%', height: { xs: '95%', md: '97.5%' },justifyContent: 'center', alignItems: 'center', display:  'flex', 
            flexDirection: 'column',gap:'16px', paddingTop:{ xs: '5%', md: '2.5%' }}}>
                <TypographySubHeading sx={{fontSize:{ xs: '16px', md: '28px' } , fontWeight:600, lineHeight:{ xs: '24px', md: '36px' }, color: Colors.dark_purple}}>About us</TypographySubHeading>
                <Box sx={{width: '100%', height: '95%', justifyContent: 'center', alignItems: 'center', display:  'flex', 
                      flexDirection: 'column',maxWidth:'92.5%',gap:'20px', paddingBottom:'5%'}}>
                         <TypographySubHeading component="h2" sx={{fontSize:{ xs: '24px', md: '24px' } , fontWeight:600, lineHeight:{ xs: '30px', md: '28px' } }}>Discover excellence in every local service you need!</TypographySubHeading>
                        <Box sx={{width: '100%', height: '100%', justifyContent: 'center', alignItems: 'left', display:  'flex', 
                             flexDirection: 'column',gap:'16px'}}>                       
                        <TypographyNormal component="h3" sx={{lineHeight:'24px'}}>Welcome to Bro Handy, your  trusted  community marketplace for finding and advertising local services. at bro handy , we understand the importance of convenience and reliability when it comes to hiring service providers. That's why we’ve created a user friendly platform that connects the public with a diverse array of local professionals, from plumbers and electricians to tutors and personal trainers.</TypographyNormal>
                      </Box>

                      <Box sx={{width: '100%', height: '100%', justifyContent: 'center', alignItems: 'left', display:  'flex', 
                             flexDirection: 'column',gap:'8px'}}>
                        <TypographySubHeading component="h2">Our mission</TypographySubHeading>
                        <TypographyNormal component="h3" sx={{lineHeight:'24px', fontSize: {md:'16px' , xs:'14px'}, fontWeight:500}}>Our mission is simple: <TypographyNormal sx={{lineHeight:'24px',  display: 'inline' }}>to empower consumers with the information they need to make informed hiring decisions and to enable service providers to grow their business by reaching a broader clientele . we strive to operate with a commitment to realiability and trust, ensuring that all data on our platform is presented with the utmost accuracy and responsibility.</TypographyNormal></TypographyNormal>                       
                      </Box>

                      <Box sx={{width: '100%', height: '100%', justifyContent: 'center', alignItems: 'left', display:  'flex', 
                             flexDirection: 'column',gap:'8px'}}>
                        <TypographySubHeading component="h2">Our users</TypographySubHeading>
                        <TypographyNormal component="h3" sx={{lineHeight:'24px', fontSize:{md:'18px' , xs:'16px'}, fontWeight:500}}>Bro Handy serves two main types of users:</TypographyNormal>
                        <TypographyNormal component="h3" sx={{lineHeight:'24px', fontSize: {md:'16px' , xs:'14px'}, fontWeight:500}}>Public Users: <TypographyNormal sx={{lineHeight:'24px',  display: 'inline' }}>Individuals looking for services can browse provider profiles, compare offerings, and contact providers directly through our platform. No personal data is required to search for.</TypographyNormal></TypographyNormal>
                        <TypographyNormal component="h3" sx={{lineHeight:'24px', fontSize: {md:'16px' , xs:'14px'}, fontWeight:500}}>Service providers: <TypographyNormal sx={{lineHeight:'24px',  display: 'inline' }}>Professionals and businesses can upload their profiles, showcasing their skills, services, and contact information to attract new clients.</TypographyNormal></TypographyNormal>
                     </Box>

                     <Box sx={{width: '100%', height: '100%', justifyContent: 'center', alignItems: 'left', display:  'flex', 
                             flexDirection: 'column',gap:'8px'}}>
                        <TypographySubHeading component="h2">Our Commitment</TypographySubHeading>
                        <TypographyNormal component="h3" sx={{lineHeight:'24px'}}>We operate on a best-effort basis to keep our services available and reliable at all times. At Bro Handy, we believe in transparency and accountability. We take extensive measures to ensure the accuracy and reliability of the data provided on our platform, though we remind users to conduct their own diligence. service providers.</TypographyNormal>
                      </Box>

                      <Box sx={{width: '100%', height: '100%', justifyContent: 'center', alignItems: 'left', display:  'flex', 
                             flexDirection: 'column',gap:'8px'}}>                        
                        <TypographyNormal component="h4" sx={{lineHeight:'24px'}}>Join us at Bro Handy – where your next service provider is just a few clicks away!</TypographyNormal>
                      </Box>
        </Box>
       
        </Box>



        <Footer currentPageFooter={"About us"}></Footer>
        </Grid>
    )
}
export default AboutUs;