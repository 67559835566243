import React, { useEffect, useState, useRef } from 'react';
import { Grid, Box, Avatar, Button,  IconButton,  } from "@mui/material";
import UserHeader from "../Components/UserHeader";
import TypographySubHeading from "../Components/TypographySubHeading";
import TypographyHeading  from "../Components/TypographyHeading";
import TypographyNormal from "../Components/TypographyNormal";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import axios from "axios";
import { API_URL } from '../Components/Constants';
import Colors from '../Components/Colors';
import { IMG_URL } from '../Components/Constants';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ProfileComponent from '../Components/ProfileComponent';
import Footer from "../Components/Footer";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ButtonBlue from '../Components/ButtonBlue';
import popupbg from '../Images/popup.png'
import whitelogo from '../Images/whitelogo.svg'
import Oops from '../Images/oops.svg'
import LinearProgress from '@mui/material/LinearProgress';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import HeaderSecondPage from '../Components/HeaderSecondPage';




function SearchResultSpList(){
    const [selectedType, setSelectedType] = useState(null);
    const [type, setType] = useState([]);
    const [serviceProviders, setServiceProviders] = useState([]);
    const [filterDropDown, setFilterDropDown] = useState(false);
    const [selectedSpId, setSelectedSpId] = useState(null);
    const [isSpClick, setIsSpClick] = useState(false);
    const [loading, setLoading] = useState(true);
    const [professionName, setProfessionName] = useState(null);
    const [filteredServiceProviders, setFilterdServiceProviders] = useState([]);
    const [isSpOpen, setIsSpOpen] = useState(false);
    const [isRatingAdd, setIsRatingAdd] = useState(false);
    const [termsDetails, setTermsDetails] = useState([]);
    const [isTerm, setIsTerm] = useState(false); 
   

    const dropDownRef = useRef(null);

    const colors = ['#503259', '#4C50AE', '#FF7D6A', '#B07CC6', '#20C925'];

    function getAvatarColor(index) {
    return colors[index % colors.length];
    }

    function stringAvatar(name = '', index) {
    const initials = name[0]?.toUpperCase() || '';
    const bgColor = getAvatarColor(index);
    return {
        sx: {
        bgcolor: bgColor,
        width: '64px',
        height: '64px',
        fontSize: '56px',
        color: '#fff',
        },
        children: initials,
    };
    }

    let totalRatingSum
     

    const calculateAverageRating = (ratingsData) => {
        if (!ratingsData || ratingsData.length === 0 || !ratingsData[0] || !ratingsData[0][0]) {           
            return 4;
        }
        const ratingCounts = ratingsData[0][0]; 
        totalRatingSum = 0;
        let totalRatingCount = 0;
    
        for (const [rating, count] of Object.entries(ratingCounts)) {
            totalRatingSum += rating * count;
            totalRatingCount += count;
        }
    
        const averageRating = totalRatingSum / totalRatingCount;
        const adjustedRating = averageRating < 4 ? 4 : averageRating;
        return adjustedRating % 1 === 0 ? adjustedRating.toFixed(0) : adjustedRating.toFixed(1);
    };
    

    useEffect(() => {
        function handleClickOutside(event) {
          if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
            setFilterDropDown(false); // Hide the box when clicking outside
          }
        }
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [dropDownRef, setFilterDropDown]);

    useEffect(() => {
        if (localStorage.getItem("service")) {
            fethSpWithService();
        } else if (localStorage.getItem("Type")) {
            fethSpWithTypes();
        } else if (localStorage.getItem("SPName")) {
            fethSpWithSpName();
        } else if (localStorage.getItem("SPCode")) {
            fethSpWithSpCode();
        }
    }, []);
    
    const fethSpWithService = async () => {
        setLoading(true); 
        try {  
            console.log(localStorage.getItem("serviceId"))         
            const response = await axios.post(`${API_URL}/select_sp_with_service`, {
                serviceId: localStorage.getItem("serviceId"),
            });            
            console.log(response.data.result.ServiceResult[0].professional_name); 
            setServiceProviders(response.data.result.SpResult);
            setFilterdServiceProviders(response.data.result.SpResult)
            setType(response.data.result.ServiceResult); 
            setProfessionName(response.data.result.ServiceResult[0].professional_name)                  
        } catch (error) {
            console.error('Error fetching service providers:', error);
        } finally {
            setLoading(false); 
        }
    };
    
    const fethSpWithTypes = async () => {
        setLoading(true); 
        try {  
            console.log(localStorage.getItem("serviceId"))         
            const response = await axios.post(`${API_URL}/select_sp_with_types`, {
                serviceId: localStorage.getItem("serviceId"),
                typeId: localStorage.getItem("TypeId")
            }); 
            console.log(response.data.result.ServiceResult[0].professional_name); 
            setServiceProviders(response.data.result.SpResult);
            setFilterdServiceProviders(response.data.result.SpResult)
            setType(response.data.result.ServiceResult);  
            setProfessionName(response.data.result.ServiceResult[0].professional_name)         
        } catch (error) {
            console.error('Error fetching service providers by type:', error);
        } finally {
            setLoading(false); // End loading
        }
    };
    
    const fethSpWithSpName = async () => {
        setLoading(true); // Start loading
        try {  
            console.log(localStorage.getItem("serviceId"))         
            const response = await axios.post(`${API_URL}/select_sp_with_spname`, {
                serviceId: localStorage.getItem("serviceId"),
                spId: localStorage.getItem("SPId")
            }); 
            console.log(response.data.result.SpResult[0].service_details[0].professional_name); 
            setServiceProviders(response.data.result.SpResult);
            setFilterdServiceProviders(response.data.result.SpResult)
            setType(response.data.result.ServiceResult); 
            setProfessionName(response.data.result.SpResult[0].service_details[0].professional_name)            
        } catch (error) {
            console.error('Error fetching service providers by SP Name:', error);
        } finally {
            setLoading(false); // End loading
        }
    };
    
    const fethSpWithSpCode = async () => {
        setLoading(true); 
        try {  
            console.log(localStorage.getItem("SPCode"))  
            console.log(localStorage.getItem("serviceId"))            
            const response = await axios.post(`${API_URL}/select_sp_with_spcode`, { 
                spcode: localStorage.getItem("SPCode"),
                serviceId: localStorage.getItem("serviceId")
            }); 
            console.log(response.data.result.SpResult[0].service_details[0].professional_name); 
            setServiceProviders(response.data.result.SpResult);
            setFilterdServiceProviders(response.data.result.SpResult)
            setType(response.data.result.ServiceResult);
            setProfessionName(response.data.result.SpResult[0].service_details[0].professional_name)               
        } catch (error) {
            console.error('Error fetching service providers by SP Code:', error);
        } finally {
            setLoading(false); 
        }
    };    

   const handleSpBoxClick  = async (id, index) => {
    localStorage.setItem("SelectedSP", id)
    localStorage.setItem("index", index)
   
    setIsSpClick(true)
    if(id === selectedSpId)
    {
    setIsSpOpen(!isSpOpen)
    }
    else{
      setIsSpOpen(true)  
    }
    setSelectedSpId(id);
    try {     
        const response = await axios.post(`${API_URL}/add_view_count`, {
            spId: id,
        });             
    } catch (error) {
        console.error('Error fetching service providers:', error);
    }    
    };

    const handleFilterDrpDownClick = (type, id) => {
        console.log(id)
        console.log(serviceProviders)
        setSelectedType(type); 
        setFilterDropDown(false);
        if(type === 'All'){
            setFilterdServiceProviders(serviceProviders)
        }
        else{
            const filteredProviders = serviceProviders.filter(provider => 
                provider.service_details.some(detail => 
                    detail.type_details.some(typeDetail => typeDetail.types_id === id)
                )  
            );
            setFilterdServiceProviders(filteredProviders);
            } 
            setIsSpClick(false)
    };

    const handleTermsAndconditionsButtonClick = () => {
        setIsTerm(true)
    }

    const handleCloseTermPopUp = () => {
        setIsTerm(false)
    }

    useEffect(() => {         
        const fetchTermseData = async () => {            
            try { 
                const response = await axios.post(`${API_URL}/select_terms`, {                    
                    title: 'signup'
                });  
                console.log(response.data.result)
                setTermsDetails(response.data.result);               
            } catch (error) {
                console.error('Error fetching data:', error);
                setTermsDetails('Failed to load Terms and condition. Please try again later.');
            }
        };
        fetchTermseData(); 

    }, []);

    const firstBoxRef = useRef(null);
    const [firstBoxHeight, setFirstBoxHeight] = useState(0);

    
    const updateHeight = () => {
        if (firstBoxRef.current) {
            setFirstBoxHeight(firstBoxRef.current.clientHeight);
            console.log("Updated height:", firstBoxRef.current.clientHeight);
        }
    };

    useEffect(() => {
        // Slight delay to capture correct initial height if any rendering delay exists
        setTimeout(() => {
            updateHeight();
        }, 0);

        window.addEventListener('resize', updateHeight);

        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, []);

    useEffect(() => {
        // Recalculate height if `selectedSpId` changes
        updateHeight();
    }, [selectedSpId]);
    

    
    return(
       <Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px',
        display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
       
        <HeaderSecondPage></HeaderSecondPage>
        <Box sx={{width: '100%', height: '100%',justifyContent: 'center', alignItems: 'center', display:  'flex', 
             flexDirection: 'column', flex: 1 }}>

                <Box sx={{width: '100%', height: '100%',justifyContent: 'center', alignItems: 'center', display:  'flex', 
                flexDirection: 'column',maxWidth:{xs:'94%', md:'97%'}, paddingTop:'2%', flex:1 }}>
                    
                    <Box sx={{width: '100%', height: '100%',justifyContent: 'center', alignItems: 'flex-start', display:  'flex', 
                    flexDirection: { xs: 'column', md: 'row' } ,gap:'16px' }}>

                    <Box sx={{width:{ xs: '100%', md: isSpClick ? '30%' : '40%' } , height: '100%', justifyContent: 'flex-start',
                     alignItems: 'flex-start', display:  'flex', flexDirection: 'column',gap:'16px',}}>

                      { professionName !== null &&  <Box sx={{width: '100%', height: '100%',justifyContent: 'space-between', alignItems: 'flex-start', display:  'flex', 
                        flexDirection: 'row', minWidth:{md:'92%', xs:'100%'},}}> 
                        {console.log(professionName)}                           
                            <TypographyNormal sx={{fontWeight:500, fontSize:{ xs: '14px', md:'20px' },  whiteSpace: 'nowrap',lineHeight:'28px',
                                         overflow: 'hidden', textOverflow: 'ellipsis', maxWidth:{md:'80%', xs:'100%'},}}>List of {professionName}</TypographyNormal>
                            <Box sx={{ position: 'relative', width: '45%', height: '100%', marginRight:{md:'4.3%', xs:'0%'}, }}>
                                <Box sx={{justifyContent: 'right', alignItems: 'flex-end', display: 'flex', flexDirection: 'row',
                                    gap: '4px',cursor: 'pointer',}}
                                    onClick={() => setFilterDropDown(!filterDropDown)}>
                                    <TypographyNormal sx={{ fontWeight: 400, fontSize: '16px',  whiteSpace: 'nowrap',lineHeight:'28px',
                                         overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: {md:'80%', xs:'100%'},  }}>
                                        {!selectedType ? 'Filter' : selectedType}
                                    </TypographyNormal>
                                    {filterDropDown ? (<ExpandLessIcon sx={{color: Colors.black_80,}}/>):(<ExpandMoreIcon sx={{color: Colors.black_80,}}/>)}
                                </Box>
                            {filterDropDown && (
                            <Box ref={dropDownRef}   sx={{position: 'absolute', top: '100%', width: '100%',padding:'8px' ,right:'0px',
                                borderRadius: '4px', marginTop: '4px',  backgroundColor: Colors.white_80,
                                zIndex: 1,boxShadow: '0px 4px 4px 0px #00000040', gap:'0px', display:'flex', flexDirection:'column'
                            }}>
                                <Box sx={{
                                    padding: '6px 10px', cursor: 'pointer', borderRadius: '4px',color: Colors.black_90,
                                    '&:hover': { backgroundColor: Colors.primary_blue ,  color: Colors.white},
                                    }}                                   
                                    onClick={() => {handleFilterDrpDownClick('All',0);}}>
                                    <TypographyNormal
                                    sx={{
                                        maxWidth: '98%', fontSize:'12px',  whiteSpace: 'nowrap',
                                        overflow: 'hidden', textOverflow: 'ellipsis', color: 'inherit'
                                    }}
                                    >
                                    All
                                    </TypographyNormal>
                                </Box>
                                {type.map((type, index) => (
                                <Box key={type.types_id}
                                    sx={{padding: '6px 10px', cursor: 'pointer', borderRadius: '4px',color: Colors.black_90,
                                    '&:hover': { backgroundColor: Colors.primary_blue,  color: Colors.white},
                                    }} onClick={() => {handleFilterDrpDownClick(type.types, type.types_id);}}>
                                    <TypographyNormal sx={{maxWidth:'98%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
                                     color: 'inherit',fontSize:'12px',}}>{type.types}</TypographyNormal>
                                </Box>
                                ))}
                            </Box>
                            )}
                        </Box>
                        </Box>}
                        <Box sx={{width: '100%', height: '100%',justifyContent: 'flex-start', alignItems: 'flex-start', display:  'flex',paddingRight:'4px', 
                        flexDirection: 'column',gap:'12px', overflowY: 'auto',overflowX: 'hidden',    maxHeight: { xs: '100%', md:'840px' },
                        borderRight:{ xs: '0px solid', md: '1px solid' }, borderColor:{ xs: Colors.grey_30, md: Colors.grey_30 } ,
                         minHeight: '840px', paddingBottom:{md: isSpClick ? 0 : '7%', xs:'5%'},
                        '&::-webkit-scrollbar': {
                          width: '12px', height: '8px', borderRadius: '16px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: Colors.black_60, borderRadius: '16px', border: '2px solid white',
                        },
                        '&::-webkit-scrollbar-track': {
                          borderRadius: '16px', backgroundColor: 'none', 
                        }, }}>                     

                        {loading ? (
                            <Box sx={{ width: '70%', textAlign: 'center', mt: 2 , paddingTop:'20%'}}>                            
                             <TypographySubHeading sx={{ mt: 2 }}>
                             Searching...                              
                            </TypographySubHeading>
                            </Box>
                        ) : filteredServiceProviders.length > 0 ? (
                            filteredServiceProviders.map((provider, index) => (
                                <>
                                <Box 
                                    key={index} 
                                    sx={{
                                        width: '100%', height: '97.5%', justifyContent: 'center', alignItems: 'center',
                                        display: 'flex', flexDirection: 'row', border: '1px solid', borderRadius: '4px', 
                                        borderColor: selectedSpId === provider.service_provider_id ? Colors.primary_blue : Colors.grey_30,
                                        maxWidth: {md:'95%', xs:'100%'}, paddingTop: '2.5%', paddingBottom: '2.5%', 
                                        '&:hover': {
                                            borderColor: Colors.primary_blue, 
                                            cursor: 'pointer'
                                        }
                                    }} onClick={() => handleSpBoxClick(provider.service_provider_id, index)}>
                                    <Box 
                                        sx={{
                                            width: '100%', height: '100%', justifyContent: 'center', maxWidth: '95%', 
                                            alignItems: 'flex-start', display: 'flex', flexDirection: 'row', gap:{ xs: '16px', md: '24px' } 
                                        }}>
                                        <Box 
                                            sx={{
                                                width: { xs: '40px', md:  '80px' }, height: '100%', justifyContent: 'center', alignItems: 'center', 
                                                display: 'flex', flexDirection: 'column', 
                                            }}>
                                            {provider.profile_image ? (
                                                <Avatar 
                                                    alt="profile" 
                                                    src={`${IMG_URL}${provider.profile_image}`} 
                                                    sx={{ width:{ xs: '40px', md: isSpClick ? '60px' : '80px' } , height:{ xs: '40px', md: isSpClick ? '60px' :'80px' }}}/>
                                            ) : (
                                                <Avatar 
                                                    alt="profile" 
                                                    sx={{
                                                        ...stringAvatar(provider.business_name, index).sx,
                                                        width: { xs: '40px', md: isSpClick ? '70px' : '80px' }, height: { xs: '40px', md: isSpClick ? '70px' : '80px' }, fontSize: { xs: '30px', md: '40px' }}}>
                                                    {stringAvatar(provider.business_name, index).children}
                                                </Avatar>
                                            )}
                                        </Box>
                                        <Box 
                                            sx={{
                                                width: { xs: 'calc(100% - 60px)', md:  'calc(100% - 104px)' } , height: '100%', justifyContent: 'center',  
                                                alignItems: 'left', display: 'flex', flexDirection: 'column', }}>
                                            <Box 
                                                sx={{
                                                    width: '100%', height: '100%', justifyContent: 'space-between', 
                                                    alignItems: 'center', display: 'flex', flexDirection: 'row'
                                                }}>
                                                    {localStorage.getItem("SPCode") ? (
                                                <Box 
                                                    sx={{ width: `${provider.business_name.length * 10}px`, height: '16px', justifyContent: 'center', 
                                                        alignItems: 'center', display: 'flex', flexDirection: 'row', 
                                                        background: Colors.black_60
                                                    }}/>
                                                    ) : (
                                                        <TypographyNormal sx={{ fontWeight: 700, fontSize:'16px' }}>
                                                            {provider.business_name}
                                                        </TypographyNormal>
                                                    )} 
                                                     <Box 
                                                sx={{
                                                    width: 'fit-content', height: 'fit-content', justifyContent: 'center', 
                                                    alignItems: 'center', display: 'flex', flexDirection: 'row'
                                                }}>                                               
                                                {selectedSpId === provider.service_provider_id && (
                                                    <>
                                                    <ChevronRightIcon sx={{display:{ xs: 'none', md: 'flex' },height:'20px' }} />                                                    
                                                    {isSpOpen ? (<KeyboardArrowUpIcon sx={{display:{ xs: 'flex', md: 'none' }}}/>) 
                                                    : (<KeyboardArrowDownIcon sx={{display:{ xs: 'flex', md: 'none' }}}/>)}
                                                    </>
                                                )}
                                                </Box>
                                            </Box>
                                            <TypographyNormal sx={{whiteSpace: 'nowrap',  overflow: 'hidden', paddingTop:'4px',
                                            textOverflow: 'ellipsis', maxWidth: '93%', color: Colors.black_70}}>
                                                {provider.service_details.map(serviceDetail => serviceDetail.services).join(', ')}
                                            </TypographyNormal>
                                            <TypographyNormal sx={{whiteSpace: 'nowrap',  overflow: 'hidden', 
                                            textOverflow: 'ellipsis', maxWidth: '93%', color: Colors.black_70}}>
                                                {provider.city}, {provider.province}, {provider.country}
                                            </TypographyNormal>

                                              {provider.rating_details.length > 0 && 
                                              <Box sx={{width: '42px', height: '24px', justifyContent:'center',alignItems:'center',
                                                    borderRadius: '16px', background: Colors.primary_blue, marginBottom:'4px',display:'flex', 
                                                    color: Colors.white, fontSize:'10px', fontWeight:400,fontFamily:'Roboto',marginTop:'4px',
                                                     '&: hover':{background: Colors.primary_blue, color: Colors.white,}}}>
                                               {calculateAverageRating(provider.rating_details)}
                                                </Box>}
                                          
                                                {localStorage.getItem("SPCode") ? (
                                                <Box 
                                                    sx={{ width: isSpClick ? `${provider.about_us.length * 6.5}px`: `${provider.about_us.length * 10}px`, height: '16px', justifyContent: 'center', 
                                                        alignItems: 'center', display: 'flex', flexDirection: 'row', 
                                                        background: Colors.black_60
                                                    }}/>
                                                    ) : (
                                                        <TypographyNormal sx={{whiteSpace: 'nowrap',  overflow: 'hidden',paddingTop:'4px', 
                                                            textOverflow: 'ellipsis', maxWidth: '93%', color: Colors.black_70}}>
                                                            {provider.about_us }
                                                        </TypographyNormal>
                                                    )} 
                                               
                                           
                                        </Box> 
                                    </Box>
                                </Box>
                                {localStorage.getItem("SPCode") && <Box sx={{ gap: '8px', width:'100%',flexDirection: 'column',paddingLeft: isSpClick ?'16px' :'32px'  }}>
                                                        <TypographyNormal sx={{fontSize:{xs:  '10px', md: '12px' }}}>
                                                        By clicking the Sign up button, you agree to our
                                                                <TypographyNormal
                                                                component="span"
                                                                sx={{ color: Colors.blue_6, display: 'inline', marginLeft: {xs:  '4px', md: '8px' }, fontSize:{xs:  '10px', md: '12px' }, cursor:'pointer' }}
                                                                onClick={handleTermsAndconditionsButtonClick}
                                                                >
                                                                Terms and conditions.
                                                                </TypographyNormal>
                                                        </TypographyNormal>
                                                        </Box> }

                       { selectedSpId === provider.service_provider_id && isSpOpen && 
                       <Box sx={{width: { xs: '100%', md: '60%' }, height: '100%',justifyContent: {md: 'center', xs:'flex-start'}, 
                       alignItems:{md: 'center', xs:'flex-start'},  display:  { xs: 'flex', md: 'none' }, flexDirection: 'row',
                       paddingLeft:{md:0, xs:'0.2%'} }}>
                        <Box sx={{width: '100%', height: '100%',justifyContent: 'center', alignItems: 'center', display:  'flex', 
                        flexDirection: 'row',maxWidth:{md:'97%', xs:'100%'} ,}}>
                            {isSpClick && selectedSpId && <ProfileComponent selectedSpId={selectedSpId}></ProfileComponent>}
                            {!isSpClick &&  <Box sx={{width: '100%', height: '50vh',justifyContent: 'center', alignItems: 'center',
                            display:  'flex', flexDirection: 'row', background: Colors.grey_20, marginTop:'6%'}}>
                                <TypographyHeading>ADD</TypographyHeading>
                        </Box>}
                        </Box>
                        </Box>}
                        </>                     
                          ))
                        ) : (
                            <Box sx={{width: '100%', height: '100%',justifyContent: 'center', alignItems: 'center', display:  'flex', 
                                flexDirection: 'column', paddingTop:{ xs: '20%', md:  '10%' }, gap:'12px' , }}>
                                   <Box sx={{width: '100%', height: '100%',justifyContent: 'center', alignItems: 'center', display:  'flex', 
                                flexDirection: 'column',maxWidth:'80%',}}>
                                     <img src={Oops} alt="oops" style={{ width: '50%', height: '50%' }} />
                                    <TypographySubHeading sx={{fontWeight:400,}}> Oops! </TypographySubHeading>
                                    <TypographyNormal sx={{ textAlign:'center', lineHeight:'24px' }}>
                                    We couldn't find any service providers in this category.
                                    </TypographyNormal>
                                    </Box>
                            </Box>
                        )}

                        </Box>
                        
                    </Box>                    
                    <Box ref={firstBoxRef} sx={{width: { xs: '100%', md: isSpClick ? '70%' : '60%' }, height: 'auto',justifyContent: 'center', alignItems: 'flex-start', display:  { xs: 'none', md: 'flex' }, 
                       flexDirection: 'row'}}>                        
                        <Box sx={{width: '100%', height: '100%',justifyContent: 'center', alignItems: 'flex-start', display:  'flex', 
                          flexDirection: 'row',maxWidth:isSpClick ?'97%':'95%', }}>
                           {isSpClick && selectedSpId &&
                           
                            <ProfileComponent selectedSpId={selectedSpId} setIsRatingAdd={setIsRatingAdd}></ProfileComponent>}
                           {!isSpClick &&  <Box sx={{width: '100%', height: '50vh',justifyContent: 'center', alignItems: 'center',
                            display:  'flex', flexDirection: 'row', background: Colors.grey_20, marginTop:'5.3%'}}>
                                <TypographyHeading>ADD</TypographyHeading>
                           </Box>}
                       </Box>
                    </Box>

                    </Box>
                </Box>
        </Box>
        <Box sx={{display: 'flex', width: '100%', mt: 'auto'}}>
        <Footer></Footer>
        </Box>
        {isTerm && <Box sx={{
                            position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.6)',
                            display: 'flex',alignItems: 'center',justifyContent: 'center',zIndex: '2',

                        }}>
                            <Box sx={{
                                width: '100%', height: '100%', gap: '5px',
                                justifyContent: 'center', alignItems: 'center', position: 'fixed',
                                display: 'flex', flexDirection: 'column',
                            }}>
                                <Box sx={{
                                    width: { md: '75%', sm: '75%', xs: '85%' }, height: '36px',
                                    justifyContent: 'right', alignItems: 'right', display: 'flex',
                                }}>
                                     <IconButton onClick={handleCloseTermPopUp} sx={{
                                    position: 'fixed', width: '36px', height: '36px',}} >
                                        <CloseOutlinedIcon sx={{ width: '36px', height: '36px', color: Colors.black_60, '&:hover': {
                                        width: '40px', height: '40px', color: Colors.black_60, backgroundColor: 'none',
                                        background:'none'},}} ></CloseOutlinedIcon>
                                    </IconButton>
                                </Box>
                                <Box sx={{width: { md: '75%', sm: '75%', xs: '85%' },height: '90%', 
                        backgroundColor: Colors.white, border: '1px solid',
                        borderColor: Colors.white, borderRadius: '4px ',boxShadow: 'none',
                        zIndex: '3', alignItems: 'center', 
                    }}>

                 
                            <Box sx={{
                                display: 'flex', flexDirection: 'row', width: '100%', 
                                justifyContent: 'center', alignContent: 'center', height: '100%'}}>
                                    
                                    <Box sx={{
                                        display:  {xs: 'none', md:'flex' }, flexDirection: 'column', width: '50%', gap: '8px',
                                        justifyContent: 'center',  height: '100%', alignItems: 'center',
                                        backgroundImage: `url(${popupbg})`, backgroundRepeat: 'no-repeat',
                                        backgroundSize: '100% 100%',}}>
                                              <Box sx={{
                                                display: 'flex', flexDirection: 'column', width: '100%', gap: '12px',
                                                justifyContent: 'center', maxWidth:'75%',alignItems: 'left', }}>
                                                <TypographyNormal sx={{fontSize:'16px', color: Colors.white_80}}>Welcome</TypographyNormal>
                                                <Box sx={{
                                                display: 'flex', flexDirection: 'column',  width: '224px', height: '32px',
                                                justifyContent: 'center',alignItems: 'left', }}>
                                                <img src={whitelogo} alt="logo" style={{width: '100%', height: '100%', }}/>
                                                </Box>
                                                <TypographyNormal sx={{ color: Colors.white_80}}>Find the help you need, right in your neighborhood.</TypographyNormal>
                                             </Box>
                                    </Box>
                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', width: {xs: '100%', md:'50%' }, gap: '8px',
                                        justifyContent: 'flex-start',  height: '100%', alignItems: 'center',overflowX: 'auto',
                                        
                                        '&::-webkit-scrollbar': {
                                            width: '11px', height: '8px', borderRadius: '16px',
                                          },
                                          '&::-webkit-scrollbar-thumb': {
                                            backgroundColor: Colors.grey_30, borderRadius: '16px', border: '2px solid white',
                                          },
                                          '&::-webkit-scrollbar-track': {
                                            borderRadius: '16px', backgroundColor: Colors.white, 
                                          },}}>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '12px',                                           
                                            height:'90%', justifyContent: 'flex-start', maxWidth:'75%',alignItems: 'left',
                                            paddingTop:'5%', marginBottom:'5%'}}>
                                               
                                           
                                           <div dangerouslySetInnerHTML={{ __html:  termsDetails[0].content }} />
                                           
                                            



                                            </Box>
                                    </Box>
                            </Box>
                    
                </Box>
                            </Box>
                </Box>}              


       </Grid>
    )
}

export default SearchResultSpList;

