import { Box, Button, MenuItem, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { API_URL } from './Constants';
import TypographyNormal from './TypographyNormal';
import TextfieldNormal from './TextfieldNormal';
import { TableSortLabel } from '@mui/material';

function AdminUsers({ userDetails, setUserDetails, updateTrigger }) {
    console.log("userDetails",userDetails);

    const token = localStorage.getItem('adminToken');
    // const [sPDetails, setSPDetails] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredUserDetails, setFilteredUserDetails] = useState([]);
    const [editId, setEditId] = useState(null);
    const [newSpName, setNewSpName] = useState('');
    const [newCountryCode, setNewCountryCode] = useState('');
    const [newPhoneNumber, setNewPhoneNumber] = useState('');
    const [newPostalCode, setNewPostalCode] = useState('');
    const [page, setPage] = useState(0); // State for current page
    const [rowsPerPage, setRowsPerPage] = useState(5); // State for rows per page
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    useEffect(() => {     
        setFilteredUserDetails(userDetails);
    }, [userDetails]); 
    useEffect(() => {
        // Code to fetch or update data goes here
        console.log('Admin user component updated');
    }, [updateTrigger]);


    const handleStatusToggle = async (user) => {
        console.log(user);
        const newStatus = user.active_status === 'Active' ? 'Inactive' : 'Active';
        try {
            const response = await axios.post(`${API_URL}/admin_update_user_status`, {
                token,
                user_id: user.user_id,
                status: newStatus
            });
            console.log(response.data)
            if (response.data.success) {
                console.log("service", user)
                setUserDetails((prevDetails) =>
                    prevDetails.map((srv) =>
                        srv.user_id === user.user_id
                            ? { ...srv, active_status: newStatus }
                            : srv
                    )
                );
                console.log("reached", userDetails);

            } else {
                console.error('Error:', response.data.error);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    };

    const handleSort = (columnKey) => {
        let direction = 'asc';
        if (sortConfig.key === columnKey && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key: columnKey, direction });

        const sortedData = [...filteredUserDetails].sort((a, b) => {
            if (columnKey === 'user_name') {
                if (a.user_name < b.user_name) return direction === 'asc' ? -1 : 1;
                if (a.user_name > b.user_name) return direction === 'asc' ? 1 : -1;
                return 0;
            } else if (columnKey === 'joined_date') {
                const dateA = new Date(a.joined_date);
                const dateB = new Date(b.joined_date);
                return direction === 'asc' ? dateA - dateB : dateB - dateA;
            }
            return 0;
        });

        setFilteredUserDetails(sortedData);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const formatDateTime = (dateTimeString) => {
        const dateUTC = new Date(dateTimeString);
        const timeOptions = {
            timeZone: 'America/Toronto',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        };

        const timeFormatter = new Intl.DateTimeFormat('en-CA', timeOptions);
        const formattedTime = timeFormatter.format(dateUTC);

        const dateInToronto = new Date(dateUTC.toLocaleString('en-US', { timeZone: 'America/Toronto' }));
        const day = String(dateInToronto.getDate()).padStart(2, '0');
        const month = String(dateInToronto.getMonth() + 1).padStart(2, '0');
        const year = dateInToronto.getFullYear();

        return `${day}-${month}-${year} ${formattedTime}`;
    };


    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const filterData = (value) => {
        setSearchQuery(value); // Update the search query state
        const filteredData = filteredUserDetails.filter(sp =>
            sp.user_name.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredUserDetails(filteredData);
        setPage(0); // Reset page to 0 when filter changes
    };




    return (
        <Box sx={{ mt: 4, width: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <TypographyNormal variant="h6">User Management</TypographyNormal>
                <TextfieldNormal               
                placeholder="Search User Name"
                    value={searchQuery}
                    onChange={(e) => filterData(e.target.value)} 
                />
            </Box>
            <TableContainer component={Paper} sx={{ maxWidth: '95%', overflowX: 'auto', }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ minWidth: 150 }}>User ID</TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sortConfig.key === 'user_name'}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort('user_name')}
                                >
                                    User Name
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sx={{ minWidth: 150 }}>Country code</TableCell>
                            <TableCell sx={{ minWidth: 150 }}>Phone number</TableCell>
                            <TableCell sx={{ minWidth: 150 }}>Zip code</TableCell>
                            <TableCell sx={{ minWidth: 150 }}>Joined date</TableCell>
                            <TableCell sx={{ minWidth: 100 }}>Status</TableCell>
                            {/* <TableCell sx={{ minWidth: 150 }}>Rating Value</TableCell>
                            <TableCell sx={{ minWidth: 150 }}>Rating Comment</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredUserDetails && filteredUserDetails.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                            <TableRow key={row.user_id}>
                                <TableCell>{row.user_id}</TableCell>
                                <TableCell>
                                    {editId === row.user_id ? (
                                        <TextfieldNormal
                                            value={newSpName}
                                            onChange={(e) => setNewSpName(e.target.value)}
                                        />
                                    ) : (
                                        row.user_name
                                    )}
                                </TableCell>
                                <TableCell>
                                    {editId === row.service_provider_id ? (
                                        <TextfieldNormal
                                            value={newCountryCode}
                                            onChange={(e) => setNewCountryCode(e.target.value)}
                                        />
                                    ) : (
                                        row.country_code
                                    )}
                                </TableCell>
                                <TableCell>
                                    {editId === row.service_provider_id ? (
                                        <TextfieldNormal
                                            value={newPhoneNumber}
                                            onChange={(e) => setNewPhoneNumber(e.target.value)}
                                        />
                                    ) : (
                                        row.phone_number
                                    )}
                                </TableCell>
                                <TableCell>
                                    {editId === row.service_provider_id ? (
                                        <TextfieldNormal
                                            value={newPostalCode}
                                            onChange={(e) => setNewPostalCode(e.target.value)}
                                        />
                                    ) : (
                                        row.zip_code
                                    )}
                                </TableCell>

                                {/* <TableCell>{row.joined_date}</TableCell> */}
                                <TableCell> <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <span>{formatDateTime(row.joined_date).split(' ')[0]}</span>
                                    <span>
                                        {formatDateTime(row.joined_date).split(' ')[1]} {formatDateTime(row.joined_date).split(' ')[2]}
                                    </span>
                                </Box></TableCell>
                                <TableCell>
                                    <Switch
                                        checked={row.active_status === 'Active'}
                                        onChange={() => handleStatusToggle(row)}

                                    />
                                </TableCell>
                                {/* <TableCell>Action</TableCell> */}
                                {/* <TableCell sx={{ display: 'flex', gap: '8px' }}>
                                    {editId === row.service_provider_id ? (
                                        <>
                                            <Button variant="contained" color="primary" onClick={() => handleSaveClick(row)}>Save</Button>
                                            <Button variant="contained" color="secondary" onClick={handleCancelClick}>Cancel</Button>
                                        </>
                                    ) : (
                                        <>
                                            <Button variant="contained" color="primary"
                                                sx={{ height: '30px' }} onClick={() => handleEditClick(row)}>Edit</Button>
                                            <Button variant="contained" color="error"
                                                sx={{ height: '30px',width:'fit-content' }} onClick={() => handleDeleteClick(row.service_provider_id)}>Complete purge</Button>
                                        </>
                                    )}
                                </TableCell> */}

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredUserDetails && filteredUserDetails.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
    );
}

export default AdminUsers;
