
import React, { useEffect, useState, useRef } from 'react';
import { Grid, Box, Avatar, Button, IconButton , InputAdornment, Checkbox} from "@mui/material";
import TypographySubHeading from "../Components/TypographySubHeading";
import TypographyHeading  from "../Components/TypographyHeading";
import TypographyNormal from "../Components/TypographyNormal";
import Colors from '../Components/Colors';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import popupbg from '../Images/popup.png'
import whitelogo from '../Images/whitelogo.png'
import TextfieldNormal from '../Components/TextfieldNormal';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ButtonBlue from '../Components/ButtonBlue';
import TypographyError from '../Components/TypographyError';
import UserTermPopUp from '../Pages/UserTermPopUp';
import axios from "axios";
import { API_URL } from '../Components/Constants';
import OTPInput from '../Components/OTPInput';




function AssociateSignIn(props){

    const {setAssociatePopupType, associatePopupType} = props

   
    const [countryCode, setCountryCode] = useState("1");
    const [countryCodeError, setCountryCodeError] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState("");
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [isCheckedSignIn, setisCheckedSignIn] = useState(false);
    const [signinError, setSigninError] = useState("");
    const [isOtpPopUp, setIsOtpPopUp] = useState(false);
    const [isOtpSuccess, setIsOtpSuccess] = useState(false);
    const [forgotPasswordData, setForgotPasswordData] = useState({      
        countryCode: "",
        phoneNumber: "",
    });



    const handleCountryCodeChange = (event) => {
        setCountryCode(event.target.value);
        setCountryCodeError(""); 
        setForgotPasswordData((prevData) => ({
            ...prevData,
            countryCode: event.target.value,
        })); 
        setSigninError("")      
    };

    const handleKeyDownCountryCode = (event) => {
        const key = event.key;
        if (!/^\d$/.test(key) && key !== 'Backspace' && key !== 'Delete' && key !== 'ArrowLeft' && key !== 'ArrowRight') {
            event.preventDefault();
        }
    };

    const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value);
        setPhoneNumberError("");
        setSigninError("")  
        setForgotPasswordData((prevData) => ({
            ...prevData,
            phoneNumber: event.target.value,
        }));
        const savedCountryCode = localStorage.getItem("rememberedCountryCodeAssociate");
        const savedPhone = localStorage.getItem("rememberedPhoneAssociate");
        const savedPassword = localStorage.getItem("rememberedPasswordAssociate");
        
    if (savedPhone && event.target.value === savedPhone && countryCode === savedCountryCode) {
        setPassword(savedPassword);
        setisCheckedSignIn(true)
        
    } else {        
        setisCheckedSignIn(false) 
    }        
    };

    const handleKeyDownPhone = (event) => {
        const key = event.key;
        if (!/^\d$/.test(key) && key !== 'Backspace' && key !== 'Delete' && key !== 'ArrowLeft' && key !== 'ArrowRight') {
            event.preventDefault();
        }
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        setPasswordError("");  
        setSigninError("")        
    };

    

    const handleSignInClick = async () => {       
                 
          
             if (countryCode.trim() === "") {
                setCountryCodeError("Please enter your country code");
            }            
            else if (phoneNumber.trim() === "") {
                setPhoneNumberError("Please enter your phone number");
            }
            else if (phoneNumber.trim() !== "" && phoneNumber.length !== 10) {
                setPhoneNumberError('Phone number must be 10 digits');
            }
            else if (password.trim() === "") {
                setPasswordError("Please enter your password");
            }
           
        else {
          
            try {                    
                const response = await axios.post(`${API_URL}/select_associate_details`, {                    
                    countryCode: countryCode,
                    phoneNumber: phoneNumber,                
                    password: password,
                    rememberMe: isCheckedSignIn
                }); 
                console.log('User data inserted successfully:', response.data); 
                setSigninError(response.data.error)
                if(response.data.success === true){                   
                    if (response.data.isCheckedSignIn) {                        
                        localStorage.setItem("associateToken", response.data.token);
                        localStorage.setItem("rememberedCountryCodeAssociate", countryCode);
                        localStorage.setItem("rememberedPhoneAssociate", phoneNumber);
                        localStorage.setItem("rememberedPasswordAssociate", password);
                    } else {
                        localStorage.removeItem("rememberedCountryCodeAssociate");
                        localStorage.removeItem("rememberedPhoneAssociate");
                        localStorage.removeItem("rememberedPasswordAssociate");
                        localStorage.setItem("associateToken", response.data.token);
                    }
                    localStorage.removeItem("userToken")
                    localStorage.removeItem("spToken")
                    try {
                        const otpresponse = await axios.post(`${API_URL}/otp_creation`,
                       {
                           countryCode: countryCode,
                           phone: phoneNumber,
                       });                  
                           if (otpresponse.data.success === true) {
                               setIsOtpPopUp(true) 

                               try {
                                const statusresponse = await axios.post(`${API_URL}/status_update_associate`,
                               {
                                associateTocken: localStorage.getItem("associateToken")
                               });                  
                                                 
                               }
                               catch (error) {
                                   console.error("Request failed:", error);
                               }
                           }               
                       }
                       catch (error) {
                           console.error("Request failed:", error);
                       }
                }
            } catch (error) {
                console.error('Error inserting user data:', error);
                setAssociatePopupType('loginerror')
            } 
        
         }
    };   

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleCheckboxChange = (event) => {
        setisCheckedSignIn(event.target.checked);
    };

    const handleCloseVerifyOtpPopUp = () => {
        setIsOtpPopUp(false)     
    }

    useEffect(() => {
        console.log(isOtpSuccess)
        if (isOtpSuccess) { 
            setIsOtpPopUp(false)  
            window.location.href = `/associate-landing`;
        }
    }, [isOtpSuccess]);
   
 




return(
<Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px' }}>

            <Box sx={{
                width: '100%', height: '100%', gap: '5px',
                justifyContent: 'center', alignItems: 'center', position: 'fixed',
                display: 'flex', flexDirection: 'column',}}>
                   
                    <Box sx={{display: 'flex', flexDirection: 'row', width: '100%', 
                        justifyContent: 'center', alignContent: 'center', height: '100%'}}>
                            
                            <Box sx={{display:{xs: 'none', md:'flex' }, flexDirection: 'column', width: '50%', gap: '8px',
                                justifyContent: 'center',  height: '100%', alignItems: 'center',
                                backgroundImage: `url(${popupbg})`, backgroundRepeat: 'no-repeat',
                                backgroundSize: '100% 100%',}}>

                                        <Box sx={{display: 'flex', flexDirection: 'column', width: '100%', gap: '12px',
                                        justifyContent: 'center', maxWidth:'75%',alignItems: 'left', }}>
                                            <TypographyNormal sx={{fontSize:'16px', color: Colors.white_80}}>Welcome</TypographyNormal>
                                                <Box sx={{display: 'flex', flexDirection: 'column',  width: '224px', height: '32px',
                                                justifyContent: 'center',alignItems: 'left', }}>
                                                <img src={whitelogo} alt="Logo" style={{width: '100%', height: '100%', }}/>
                                                </Box>
                                            <TypographyNormal sx={{ color: Colors.white_80}}>Find the help you need, right in your neighborhood.</TypographyNormal>
                                        </Box>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: {xs: '100%', md:'50%' }, gap: '8px',
                            justifyContent: 'center',  height: '100%', alignItems: 'center',}}>
                                   
                                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '8px',paddingTop:'20%',
                                     height:'100%', justifyContent: 'center', maxWidth:'75%',alignItems: 'center',paddingBottom:'20%'}}>

                                            <Box sx={{ gap: '8px' ,  display: 'flex', flexDirection: 'column', justifyContent: 'center',
                                                alignItems: 'left', width:'100%'}}>
                                                <TypographyNormal sx={{ fontSize: '24px', color: Colors.black_80, }}>Lets sign in</TypographyNormal>
                                                <TypographyNormal >Enter your credentials to continue</TypographyNormal>
                                            </Box>
                                            <Box sx={{ gap: {xs:  '8px', md: '16px' }, display: 'flex', flexDirection: 'column', 
                                            paddingTop:{xs:  '8px', md: '16px' }, width:'100%'}}>                                            
                                                
                                                <Box sx={{ gap: {xs:  '8px', md: '24px' }, display: 'flex', flexDirection: 'column', width:'100%'}}>
                                                    
                                                    <Box sx={{ gap: {xs:  '8px', md: '16px' }, display: 'flex', flexDirection: 'row', width:'100%'}}>
                                                       
                                                        <Box sx={{ gap: '8px', width:'30%',flexDirection: 'column',  }}>
                                                            <TypographyNormal sx={{ fontSize:{xs:  '10px', md: '14px' }, color: Colors.black_80, }}>Country code*</TypographyNormal>
                                                            <TextfieldNormal placeholder="+1" onKeyDown={handleKeyDownCountryCode}
                                                                            inputProps={{
                                                                                maxLength: 3,
                                                                                inputMode: 'numeric', 
                                                                                pattern: '[0-9]*' 
                                                                            }}
                                                                            sx={{
                                                                                fontSize: { md: '16px', sm: '16px', xs: '14px' },
                                                                                '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
                                                                                    '-webkit-appearance': 'none',
                                                                                    margin: 0
                                                                                },
                                                                                '& input[type=number]': {
                                                                                    '-moz-appearance': 'textfield'
                                                                                }
                                                                            }}
                                                                            value={countryCode}
                                                                            onChange={handleCountryCodeChange} error={!!countryCodeError}>
                                                                            </TextfieldNormal> 
                                                        </Box>

                                                        <Box sx={{ gap: '8px', width:'70%',flexDirection: 'column',  }}>
                                                        <TypographyNormal sx={{ fontSize: {xs:  '10px', md: '14px' }, color: Colors.black_80, }}>Phone number*</TypographyNormal>
                                                        <TextfieldNormal placeholder="Add phone number"
                                                                            onKeyDown={handleKeyDownPhone}                                                                           
                                                                            inputProps={{
                                                                                maxLength: 10,
                                                                                inputMode: 'numeric', 
                                                                                pattern: '[0-9]*' 
                                                                            }}
                                                                            value={phoneNumber}
                                                                            sx={{
                                                                                '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
                                                                                    '-webkit-appearance': 'none',
                                                                                    margin: 0
                                                                                },
                                                                                '& input[type=number]': {
                                                                                    '-moz-appearance': 'textfield'
                                                                                }
                                                                            }}
                                                                            onChange={handlePhoneNumberChange} error={!!phoneNumberError}>                                                       
                                                                            </TextfieldNormal>
                                                        </Box>
                                                    </Box>
                                                    {countryCodeError && !phoneNumberError && <TypographyError sx={{width:'100%',  marginTop:'-16px'}}>{countryCodeError}</TypographyError>}
                                                    {phoneNumberError && !countryCodeError && <TypographyError sx={{width:'100%', paddingLeft:'33%', marginTop:'-16px'}}>{phoneNumberError}</TypographyError>}
                                                    {phoneNumberError && countryCodeError && <TypographyError sx={{width:'100%',  marginTop:'-16px'}}>{countryCodeError} and {phoneNumberError}</TypographyError>}
                                                </Box>

                                                <Box sx={{ gap: '8px', width:'100%',flexDirection: 'column',  }}>
                                                    <TypographyNormal sx={{ fontSize:  {xs:  '10px', md: '14px' }, color: Colors.black_80, }}>Password*</TypographyNormal>
                                                    <TextfieldNormal placeholder="Enter your password"
                                                        type={showPassword ? 'text' : 'password'}
                                                        value={password}
                                                        onChange={handlePasswordChange} error={!!passwordError}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        onClick={togglePasswordVisibility}
                                                                        edge="end"
                                                                        aria-label="toggle password visibility"
                                                                        color="primary">
                                                                        {showPassword ? (
                                                                            <VisibilityOutlinedIcon sx={{ color: Colors.black_60 }} />
                                                                        ) : (
                                                                            <VisibilityOffOutlinedIcon sx={{ color: Colors.black_60 }} />
                                                                        )}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}></TextfieldNormal>
                                                    {passwordError && <TypographyError sx={{paddingTop:{xs:  '4px', md: '8px' }}}>{passwordError}</TypographyError>}
                                                </Box>

                                                <Box  sx={{display: 'flex',flexDirection: 'row',justifyContent: 'space-between',
                                                            alignItems: 'center', width: '100%',marginTop:'-8px' }}>
                                                        <Box sx={{display: 'flex', flexDirection: 'row',alignItems: 'center', }}>
                                                            <Checkbox
                                                            checked={isCheckedSignIn}
                                                            onChange={(event) => handleCheckboxChange(event)}
                                                            sx={{ paddingLeft:'0px',
                                                                '& .MuiSvgIcon-root': {
                                                                width: '19.8px',
                                                                height: '19px',padding:'0px'
                                                                },
                                                                '&.Mui-checked': {
                                                                '& .MuiIconButton-label': {
                                                                    backgroundColor: 'white',
                                                                    borderRadius: '4px',
                                                                },
                                                                '& .MuiSvgIcon-root': {
                                                                    color: Colors.primary_blue,
                                                                },                                                                   
                                                                },
                                                                
                                                            }}
                                                            />
                                                            <TypographyNormal sx={{ fontSize: {xs:  '10px', md: '14px' } , color: '#626262' }}>
                                                            Remember me
                                                            </TypographyNormal>
                                                        </Box>

                                                        
                                                        </Box>

                                                {signinError && <TypographyError>{signinError}</TypographyError>}
                                                    <Box sx={{ width:'100%',flexDirection: 'column',justifyContent:'center', display:'flex',
                                                    alignItems:'center', alignContent:'center', gap:'8px'  }}>
                                                        <ButtonBlue onClick={handleSignInClick}>Sign in</ButtonBlue>
                                                        
                                                    </Box>

                                            
                                            </Box>

                                    </Box>
                            </Box>
                    </Box>
                    
                
            </Box>

            {isOtpPopUp && <Box sx={{
            position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.6)',
            display: 'flex',alignItems: 'center',justifyContent: 'center',zIndex: '2',

        }}>
            <Box sx={{
                width: '100%', height: '100%', gap: '5px',
                justifyContent: 'center', alignItems: 'center', position: 'fixed',
                display: 'flex', flexDirection: 'column',
            }}>
                <Box sx={{
                    width: { md: '75%', sm: '75%', xs: '85%' }, height: '36px',
                    justifyContent: 'right', alignItems: 'right', display: 'flex',
                }}>
                        <IconButton onClick={handleCloseVerifyOtpPopUp} sx={{
                    position: 'fixed', width: '36px', height: '36px',}} >
                        <CloseOutlinedIcon sx={{ width: '36px', height: '36px', color: Colors.black_60, '&:hover': {
                        width: '40px', height: '40px', color: Colors.black_60, backgroundColor: 'none',
                        background:'none'},}} ></CloseOutlinedIcon>
                    </IconButton>
                </Box>
                <Box sx={{width: { md: '75%', sm: '75%', xs: '85%' },height: 'fit-content',
                        backgroundColor: Colors.white, border: '1px solid',
                        borderColor: Colors.white, borderRadius: '4px ',boxShadow: 'none',
                        zIndex: '3', alignItems: 'center', 
                    }}>

                 
                            <Box sx={{
                                display: 'flex', flexDirection: 'row', width: '100%', 
                                justifyContent: 'center', alignContent: 'center', height: '100%'}}>
                                    
                                    <Box sx={{
                                        display: {xs: 'none', md:'flex' }, flexDirection: 'column', width: '50%', gap: '8px',
                                        justifyContent: 'center',  height: '100%', alignItems: 'center',
                                        backgroundImage: `url(${popupbg})`, backgroundRepeat: 'no-repeat',
                                        backgroundSize: '100% 100%',}}>
                                              <Box sx={{
                                                display: 'flex', flexDirection: 'column', width: '100%', gap: '12px',
                                                justifyContent: 'center', maxWidth:'75%',alignItems: 'left', }}>
                                                <TypographyNormal sx={{fontSize:'16px', color: Colors.white_80}}>Welcome</TypographyNormal>
                                                <Box sx={{
                                                display: 'flex', flexDirection: 'column',  width: '224px', height: '32px',
                                                justifyContent: 'center',alignItems: 'left', }}>
                                                <img src={whitelogo} alt="Logo" style={{width: '100%', height: '100%', }}/>
                                                </Box>
                                                <TypographyNormal sx={{ color: Colors.white_80}}>Find the help you need, right in your neighborhood.</TypographyNormal>
                                             </Box>
                                    </Box>
                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', width: {xs: '100%', md:'50%' }, gap: '8px',
                                        justifyContent: 'center',  height: '100%', alignItems: 'center',}}>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '12px',
                                            paddingTop:'25%',paddingBottom:'25%',
                                            height:'100%', justifyContent: 'center', maxWidth:'75%',alignItems: 'left',}}>

                                            <OTPInput phoneNumber={phoneNumber} countryCode={countryCode} setIsOtpSuccess={setIsOtpSuccess}></OTPInput>


                                            </Box>
                                    </Box>
                            </Box>
                    
                </Box>
            </Box>
        </Box>}
       
</Grid>
)
}
export default AssociateSignIn;