import { Box, Button, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { API_URL } from './Constants';
import TypographyNormal from './TypographyNormal';
import TextfieldNormal from './TextfieldNormal';
import { TableSortLabel } from '@mui/material';

function AdminSp({spDetails,setSpDetails,updateTrigger}) {

    const token = localStorage.getItem('adminToken');
    // const [sPDetails, setSPDetails] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredSpDetails, setFilteredSpDetails] = useState([]);
    const [editId, setEditId] = useState(null);
    const [newSpName, setNewSpName] = useState('');
    const [newEmail, setNewEmail] = useState('');
    const [newCountryCode, setNewCountryCode] = useState('');
    const [newPhoneNumber, setNewPhoneNumber] = useState('');
    const [newProvince, setNewProvince] = useState('');
    const [newPostalCode, setNewPostalCode] = useState('');
    const [newWorkingRadius, setNewWorkingRadius] = useState('');
    const [newCountry, setNewCountry] = useState('');
    const [newContactPerson, setNewContactPerson] = useState('');

    const [page, setPage] = useState(0); // State for current page
    const [rowsPerPage, setRowsPerPage] = useState(5); // State for rows per page
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    useEffect(() => {
        // Set filteredSpDetails whenever spDetails changes
        setFilteredSpDetails(spDetails);
    }, [spDetails]); 
    useEffect(() => {
        // Code to fetch or update data goes here
        setFilteredSpDetails(spDetails)
        console.log('AdminSp component updated');
    }, [updateTrigger]);

    const handleSort = (columnKey) => {
        let direction = 'asc';
        if (sortConfig.key === columnKey && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key: columnKey, direction });

        const sortedData = [...filteredSpDetails].sort((a, b) => {
            if (columnKey === 'business_name') {
                if (a.business_name < b.business_name) return direction === 'asc' ? -1 : 1;
                if (a.business_name > b.business_name) return direction === 'asc' ? 1 : -1;
                return 0;
            } else if (columnKey === 'joined_date') {
                const dateA = new Date(a.joined_date);
                const dateB = new Date(b.joined_date);
                return direction === 'asc' ? dateA - dateB : dateB - dateA;
            }
            return 0;
        });

        setFilteredSpDetails(sortedData);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const formatDateTime = (dateTimeString) => {
        const dateUTC = new Date(dateTimeString);
        const timeOptions = {
            timeZone: 'America/Toronto',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        };

        const timeFormatter = new Intl.DateTimeFormat('en-CA', timeOptions);
        const formattedTime = timeFormatter.format(dateUTC);

        const dateInToronto = new Date(dateUTC.toLocaleString('en-US', { timeZone: 'America/Toronto' }));
        const day = String(dateInToronto.getDate()).padStart(2, '0');
        const month = String(dateInToronto.getMonth() + 1).padStart(2, '0');
        const year = dateInToronto.getFullYear();

        return `${day}-${month}-${year} ${formattedTime}`;
    };

    const handleDeleteClick = async (serviceProviderId) => {
        console.log(serviceProviderId)
        try {
            const response = await axios.post(`${API_URL}/admin_delete_service_provider`, {
                token,
                sp_id: serviceProviderId
            });

            if (response.data.success) {
                setSpDetails((prevDetails) => prevDetails.filter((sp) => sp.service_provider_id !== serviceProviderId));
                setFilteredSpDetails((prevDetails) => prevDetails.filter((sp) => sp.service_provider_id !== serviceProviderId));
                console.log('Service provider deleted successfully');
            } else {
                console.error('Error:', response.data.error);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const filterData = (value) => {
        setSearchQuery(value); // Update the search query state
        const filteredData = filteredSpDetails.filter(sp =>
            sp.business_name.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredSpDetails(filteredData);
        setPage(0); // Reset page to 0 when filter changes
    };
    const handleSaveClick = async (service) => {
        try {
            const response = await axios.post(`${API_URL}/admin_update_service_providers`, {
                token,
                sp_id: service.service_provider_id,
                sp_name: newSpName,
                sp_email: newEmail,
                sp_contact_person: newContactPerson,
                sp_country: newCountry,
                sp_country_code: newCountryCode,
                sp_phone_number: newPhoneNumber,
                sp_postal_code: newPostalCode,
                sp_province: newProvince,
                sp_working_radius: newWorkingRadius
            });

            if (response.data.success) {

                setSpDetails((prevDetails) =>
                    prevDetails.map((srv) =>
                        srv.service_provider_id === service.service_provider_id
                            ? {
                                ...srv,
                                business_name: newSpName,
                                email: newEmail,
                                country_code: newCountryCode,
                                phone_number: newPhoneNumber,
                                province: newProvince,
                                postal_code: newPostalCode,
                                working_radius: newWorkingRadius,
                                country: newCountry,
                                contact_person: newContactPerson
                            }
                            : srv
                    )
                );
                setEditId(null); // Exit edit mode
            } else {
                console.error('Error:', response.data.error);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    };

    const handleCancelClick = () => {
        setEditId(null);
        setNewSpName('');
        setNewEmail('');
        setNewContactPerson('');
        setNewCountry('');
        setNewCountryCode('');
        setNewPhoneNumber('');
        setNewPostalCode('');
        setNewProvince('');
        setNewWorkingRadius('');
    };

    const handleEditClick = (service) => {
        setEditId(service.service_provider_id);
        setNewSpName(service.business_name);
        setNewEmail(service.email);
        setNewContactPerson(service.contact_person);
        setNewCountry(service.country);
        setNewCountryCode(service.country_code);
        setNewPhoneNumber(service.phone_number);
        setNewPostalCode(service.postal_code);
        setNewProvince(service.province);
        setNewWorkingRadius(service.working_radius);

    };
    // useEffect(() => {
    //     fetchAllServiceProviders();

    // }, []);


    // async function fetchAllServiceProviders() {
    //     try {
    //         const response = await axios.post(`${API_URL}/admin_get_all_service_providers`, { token });
    //         console.log(response.data.data);
    //         if (response.data.success) {
    //             setSpDetails(response.data.data);
    //             setFilteredSpDetails(response.data.data);
    //         } else {
    //             console.error('Error:', response.data.error);
    //         }
    //     } catch (error) {
    //         console.error('Error:', error.message);
    //     }
    // }




    return (
        <Box sx={{ mt: 4, width: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <TypographyNormal variant="h6">Service Provider Management</TypographyNormal>
                <TextfieldNormal
                    placeholder="Search Business Name"
                    value={searchQuery}
                    onChange={(e) => filterData(e.target.value)} // Pass the new value to filterData
                />
            </Box>
            <TableContainer component={Paper} sx={{ maxWidth: '95%', overflowX: 'auto', }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ minWidth: 150 }}>Service Provider ID</TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sortConfig.key === 'business_name'}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort('business_name')}
                                >
                                    Business Name
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sx={{ minWidth: 150 }}>Service Provider Code</TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sortConfig.key === 'joined_date'}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort('joined_date')}
                                >
                                    Joined Date
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sx={{ minWidth: 150 }}>Email</TableCell>
                            <TableCell sx={{ minWidth: 150 }}>Country Code</TableCell>
                            <TableCell sx={{ minWidth: 150 }}>Phone Number</TableCell>
                            <TableCell sx={{ minWidth: 150 }}>Province</TableCell>
                            <TableCell sx={{ minWidth: 150 }}>Postal Code</TableCell>
                            <TableCell sx={{ minWidth: 150 }}>Working Radius</TableCell>
                            <TableCell sx={{ minWidth: 150 }}>Country</TableCell>
                            <TableCell sx={{ minWidth: 150 }}>Contact Person</TableCell>
                            <TableCell sx={{ minWidth: 150 }}>Status</TableCell>
                            <TableCell sx={{ minWidth: 150 }}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredSpDetails.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                            <TableRow key={row.service_provider_id}>
                                <TableCell>{row.service_provider_id}</TableCell>
                                <TableCell>
                                    {editId === row.service_provider_id ? (
                                        <TextfieldNormal
                                            value={newSpName}
                                            onChange={(e) => setNewSpName(e.target.value)}
                                        />
                                    ) : (
                                        row.business_name
                                    )}
                                </TableCell>
                                <TableCell>{row.service_provider_code}</TableCell>
                                {/* <TableCell>{row.joined_date}</TableCell> */}
                                <TableCell> <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <span>{formatDateTime(row.joined_date).split(' ')[0]}</span>
                                    <span>
                                        {formatDateTime(row.joined_date).split(' ')[1]} {formatDateTime(row.joined_date).split(' ')[2]}
                                    </span>
                                </Box></TableCell>

                                <TableCell>
                                    {editId === row.service_provider_id ? (
                                        <TextfieldNormal
                                            value={newEmail}
                                            onChange={(e) => setNewEmail(e.target.value)}
                                        />
                                    ) : (
                                        row.email
                                    )}
                                </TableCell>
                                <TableCell>
                                    {editId === row.service_provider_id ? (
                                        <TextfieldNormal
                                            value={newCountryCode}
                                            onChange={(e) => setNewCountryCode(e.target.value)}
                                        />
                                    ) : (
                                        row.country_code
                                    )}
                                </TableCell>
                                <TableCell>
                                    {editId === row.service_provider_id ? (
                                        <TextfieldNormal
                                            value={newPhoneNumber}
                                            onChange={(e) => setNewPhoneNumber(e.target.value)}
                                        />
                                    ) : (
                                        row.phone_number
                                    )}
                                </TableCell>
                                <TableCell>
                                    {editId === row.service_provider_id ? (
                                        <TextfieldNormal
                                            value={newProvince}
                                            onChange={(e) => setNewProvince(e.target.value)}
                                        />
                                    ) : (
                                        row.province
                                    )}
                                </TableCell>
                                <TableCell>
                                    {editId === row.service_provider_id ? (
                                        <TextfieldNormal
                                            value={newPostalCode}
                                            onChange={(e) => setNewPostalCode(e.target.value)}
                                        />
                                    ) : (
                                        row.postal_code
                                    )}
                                </TableCell>
                                <TableCell>
                                    {editId === row.service_provider_id ? (
                                        <TextfieldNormal
                                            value={newWorkingRadius}
                                            onChange={(e) => setNewWorkingRadius(e.target.value)}
                                        />
                                    ) : (
                                        row.working_radius
                                    )}
                                </TableCell>
                                <TableCell>
                                    {editId === row.service_provider_id ? (
                                        <TextfieldNormal
                                            value={newCountry}
                                            onChange={(e) => setNewCountry(e.target.value)}
                                        />
                                    ) : (
                                        row.country
                                    )}
                                </TableCell>
                                <TableCell>
                                    {editId === row.service_provider_id ? (
                                        <TextfieldNormal
                                            value={newContactPerson}
                                            onChange={(e) => setNewContactPerson(e.target.value)}
                                        />
                                    ) : (
                                        row.contact_person
                                    )}
                                </TableCell>
                                <TableCell>Active</TableCell>
                                <TableCell sx={{ display: 'flex', gap: '8px' }}>
                                    {editId === row.service_provider_id ? (
                                        <>
                                            <Button variant="contained" color="primary" onClick={() => handleSaveClick(row)}>Save</Button>
                                            <Button variant="contained" color="secondary" onClick={handleCancelClick}>Cancel</Button>
                                        </>
                                    ) : (
                                        <>
                                            <Button variant="contained" color="primary"
                                                sx={{ height: '30px' }} onClick={() => handleEditClick(row)}>Edit</Button>
                                            <Button variant="contained" color="error"
                                                sx={{ height: '30px',width:'fit-content' }} onClick={() => handleDeleteClick(row.service_provider_id)}>Complete purge</Button>
                                        </>
                                    )}
                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredSpDetails.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
    );
}

export default AdminSp;
