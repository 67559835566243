import React, { useEffect, useState, useRef } from 'react';
import { Grid, Box, Avatar, Button, IconButton, InputAdornment, colors, InputBase, Breadcrumbs } from "@mui/material";
import Header from '../Components/Header';
import TypographySubHeading from "../Components/TypographySubHeading";
import TypographyHeading  from "../Components/TypographyHeading";
import TypographyNormal from "../Components/TypographyNormal";
import Footer from "../Components/Footer";
import Colors from '../Components/Colors';
import { Helmet } from 'react-helmet-async';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import logo from '../Images/logo.svg'
import { API_URL, FRONTEND_URL } from '../Components/Constants';
import axios from "axios";
import TextfieldNormal from '../Components/TextfieldNormal';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Link, useNavigate, useParams } from 'react-router-dom';
import blog1 from '../Images/blog1.jpg'
import share from '../Images/sharewhite.svg'
import ButtonBlue from '../Components/ButtonBlue';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import twitter from '../Images/twitter.svg'
import linkedin from '../Images/linkedin.svg'
import facebook from '../Images/facebook.svg'
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';


function BlogDetail(){
    const generateSlug = (title) => {
        return title
          .toLowerCase()
          .replace(/[^a-z0-9]+/g, '-')
          .replace(/^-/g, '')
          .replace(/-$/g, '');
      };

    function generateUrlFromTitle(title) {
        return title.toLowerCase().replace(/\s+/g, '-');
      }

      const imageMap = {
        blog1: blog1,
       
        // Add other images as needed
    };

    const { blogTitle } = useParams();

    const [blogs, setBlogs] = useState([]);
    const [searchValue, setSearchValue] = useState('')    
    const [selectedUrl, setSelectedUrl] = useState("");
    const [selectedText, setSelectedText] = useState("");
    const [isShare, setIsShare] = useState(false);
    const [filteredBlogs, setFilteredBlogs] = useState([]);    
    const [blog, setBlog] = useState(null);
   
  

    const navigate = useNavigate();

    const boxRef = useRef(null);

    const articlesPerPage = 5;
    const totalPages = Math.ceil(blogs.length / articlesPerPage);

    const [currentPageNumber, setCurrentPageNumber] = useState(1);

    const startIndex = (currentPageNumber - 1) * articlesPerPage;
    const endIndex = Math.min(startIndex + articlesPerPage, blogs.length);

    const displayedprojects = blogs.slice(startIndex, endIndex);


    async function fetchData() {
    
      try {
          const response = await axios.get(`${API_URL}/blog_data`);                   
          console.log("details",response.data)
          if (response.data.success === true) {
          setBlogs(response.data.result)
          }
      } 
      catch (error) {
          console.error('Error:', error.message);
      }
    }
  
    useEffect(() => {
      fetchData();
    }, []);
    
    useEffect(() => {
        // Find the blog based on the blogTitle from the URL
        const selectedBlog = blogs.find(
          (b) => b.title.toLowerCase().replace(/\s+/g, '-') === blogTitle
        );
        setBlog(selectedBlog);       
      }, [blogTitle, blogs]);
    
      if (!blog) {
        return <div>Loading...</div>;
      }
    
  
  
   // Sort blogs to ensure the correct order
   const sortedBlogs = [...blogs].sort((a, b) => a.blogId - b.blogId);
      
   // Get the current blog index
   const currentBlogIndex = sortedBlogs.findIndex(
     (b) => b.title.toLowerCase().replace(/\s+/g, '-') === blogTitle
   );
  
   // Determine the next and previous blogs
   const nextBlog = sortedBlogs[currentBlogIndex + 1] || null;
   const prevBlog = sortedBlogs[currentBlogIndex - 1] || null;
  
   // Handlers for navigating to next/previous blogs
   const handleNext = () => {
     if (nextBlog) {
       navigate(`/blog/${nextBlog.url}`);
     }
   };
  
   const handlePrevious = () => {
     if (prevBlog) {
       navigate(`/blog/${prevBlog.url}`);
     }
   };
  
  
  
      const handlePageClick = (page) => {
          setCurrentPageNumber(page);
      };

     

      const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchValue(value);
        const filtered = blogs.filter(blog => 
          blog.title.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredBlogs(filtered);
        if (boxRef.current) {
          if (value) {
            boxRef.current.style.display = 'block'; 
          } else {
            boxRef.current.style.display = 'none';  
          }
        }
      };

      const handleClearSearch = () => {
        setSearchValue('');
        if (boxRef.current) {
            boxRef.current.style.display = 'none';  
          }
      };


      const handleCopyButtonClick = async () => {
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(`${selectedUrl}`)
                .then(() => {
                    console.log('Link copied to clipboard');
                    setIsShare(false);
                })
                .catch((error) => {
                    console.error('Failed to copy:', error);
                    fallbackCopyTextToClipboard(`${selectedUrl}`);
                });

        } else {
            setIsShare(false);
            console.warn('Clipboard API not supported, unable to copy text');
            fallbackCopyTextToClipboard(`${selectedUrl}`);
        }
     };

    const fallbackCopyTextToClipboard = (text) => {
        const textArea = document.createElement('textarea');
        textArea.value = text;

        // Avoid scrolling to the bottom of the page
        textArea.style.position = 'fixed';
        textArea.style.top = '0';
        textArea.style.left = '0';
        textArea.style.width = '2em';
        textArea.style.height = '2em';
        textArea.style.padding = '0';
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';
        textArea.style.background = 'transparent';

        document.body.appendChild(textArea);
        textArea.select();

        try {
            const successful = document.execCommand('copy');
            console.log('Fallback: Copying text command was ' + (successful ? 'successful' : 'unsuccessful'));
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
        }

        document.body.removeChild(textArea);
    };

    const handleShareButtonClick = (url, text, e) => {
        e.stopPropagation();
        setIsShare(true); 
        setSelectedUrl(`${FRONTEND_URL}/blog/${url}`)
        setSelectedText(text)      
    };  

    
    const handleCloseSharePopUp = () => {
        setIsShare(false);
    };
      
    const recentBlogs = blogs
    .sort((a, b) => new Date(b.publish_date) - new Date(a.publish_date))
    .slice(0, 3);
    
    return(
        <Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px' }}>

            <Helmet>
                <title>handyman services</title>
                <meta name="description" content="handyman services" />
                <link rel="canonical" href="https://brohandy.com/blog" />
                <meta property="og:title" content="handyman services" />
                <meta property="og:description" content="handyman services" />
                <meta property="og:image" content={logo} />
                <meta property="og:url" content="https://brohandy.com/blog" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="BroHandy" />
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "http://schema.org",
                        "@type": "WebPage",
                        "name": "Blog - BroHandy",
                        "url": "https://brohandy.com/blog",
                        "description": "handyman services",
                        "mainEntityOfPage": "https://brohandy.com/blog",
                        "potentialAction": {
                        "@type": "ContactAction",
                        "target": "contact@brohandy.com",
                        "query-input": "required name=email"
                        }
                    }
                    `}
                </script>
            </Helmet>
            <h1 style={{ position: 'absolute', left: '-999999px' }}>handyman services</h1>



       
        <Header currentPage={"Blog"}></Header>

        <Box sx={{
            width: '100%', height: { xs: '95%', md: '97.5%' },justifyContent: 'center', alignItems: 'center', display:  'flex', 
            flexDirection: 'column',gap:{md:'16px', xs:'0px'}, paddingTop:{ xs: '5%', md: '2.5%' }}}>
                <Box sx={{ height: 'fit-content', display: 'flex', width: {md:'92.5%', xs:'97.5%'},flexDirection: 'row', gap:'8px',
                justifyContent:'flex-start', alignItems:'flex-start', paddingLeft:{md:'0%', xs:'0%'} }}>
                <Breadcrumbs 
                            separator={<span style={{ paddingLeft: '8px', fontSize: '32px', justifyContent:'center',
                                 alignItems: 'center', color: Colors.default }}>›</span>} 
                            aria-label="breadcrumb" >                           
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', 
                                    height: '100%' }}  onClick={() => window.location.href = '/blog'} >
                                <ArrowBackIcon  sx={{ color: Colors.black_90, fontSize: {md:'28px', xs:'18px'} }} />
                                </Box>
                               
                            </Breadcrumbs>
                <TypographySubHeading sx={{fontSize:{ xs: '16px', md: '28px' } , fontWeight:600, lineHeight:{ xs: '24px', md: '36px' }, 
                color: Colors.dark_purple, width:'100%', textAlign:'center'}}>Blog</TypographySubHeading>
                </Box>
                <Box sx={{width: '100%', height: '95%', justifyContent: 'flex-start', alignItems: 'center', display:  'flex', 
                      flexDirection: 'column',maxWidth:'92.5%',gap:'20px', paddingBottom:'5%', minHeight:'50vh'}}>
             
              <Box sx={{ height: 'fit-content', display: 'flex', width: '100%',flexDirection: 'column', gap:'8px',
                justifyContent:'flex-start', alignItems:'flex-start' }}>
                <Box sx={{ height: 'fit-content', display: 'flex', width: '100%',flexDirection: 'row', gap:'28px',
                justifyContent:'flex-start', alignItems:'flex-start' }}>                              
                 <Box sx={{ height: 'fit-content', display: 'flex', width: '100%',flexDirection: 'column', gap:'16px',
                    justifyContent:'flex-start', alignItems:'flex-start', marginTop:'28px' }}>    
                <TypographyNormal component="h2"  onClick={() => { window.location.href = `/blog/${generateUrlFromTitle(blog.title)}`; }}
                sx={{fontSize:{ xs: '16px', md: '56px' } , fontWeight:500, 
                        lineHeight:{ xs: '24px', md: '64px' }, color: Colors.black_80, cursor:'pointer',
                        '&:hover':{color: Colors.primary_blue,}}}>{blog.title}</TypographyNormal>
                         
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width:'100%',
                         gap:'16px', position:'relative'}}>
                        <img  src={imageMap[blog.image]}  alt='blog' style={{width:'100%', height:'100%'}}></img>
                        <IconButton
                        onClick={(e) => handleShareButtonClick(generateUrlFromTitle(blog.title), blog.title, e)}
                        sx={{  position: 'absolute', bottom: {md:'10px', xs:'16px'}, right: '10px',
                            background:'#313131C4' ,  '&:hover': { background:'#313131C4'} }} >
                        <img src={share} alt="Share Icon" style={{ width: '20px', height: '20px' }}/>
                        </IconButton>
                        </Box>
                        <TypographyNormal component="h2" sx={{fontSize:{ xs: '16px', md: '16px' } , fontWeight:400, 
                        lineHeight:{ xs: '24px', md: '12px' }, color: '#767676'}}>Published On {blog.publish_date}</TypographyNormal>
                        
                       
                       <Box sx={{ display: 'flex', flexDirection: 'column',  width:'100%', 
                         justifyContent:'flex-start', alignItems:'flex-start', }}>
                            <TypographyNormal                         
                        dangerouslySetInnerHTML={{ __html: blog.blog_content }} 
                        />
                        
                        </Box>
                        
                       
                       </Box>
                
                
                </Box>
            </Box>        
        </Box>
       
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', alignItems: 'center', width: 'calc(100% - 40px)', gap: '10px', padding: '20px', paddingTop: '40px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems:{md:'center', xs:'center'} ,
            width: {md: '25%', xs:'50%'}, gap: '20px',  justifyContent: 'center', }}>
               <ButtonBlue onClick={handlePrevious} disabled={!prevBlog} variant="text"  sx={{               
                color: !prevBlog ? Colors.black_60 :  Colors.white, textTransform: 'none', padding: 0,
                 backgroundColor:!prevBlog ? Colors.grey_30 :  Colors.primary_blue, borderColor:!prevBlog ? Colors.grey_30 : Colors.primary_blue,
                '&:hover':{color: !prevBlog ? Colors.black_60 :  Colors.white,background:!prevBlog ? Colors.grey_30 : Colors.hovering_blue,
                    }
            }}><WestIcon sx={{               
                color: !prevBlog ? Colors.black_60 :  Colors.white, textTransform: 'none', padding: 0,fontSize:'16px',
                 backgroundColor:!prevBlog ? Colors.grey_30 :  Colors.primary_blue, borderColor:!prevBlog ? Colors.grey_30 : Colors.primary_blue,
                '&:hover':{color: !prevBlog ? Colors.black_60 :  Colors.white,background:!prevBlog ? Colors.grey_30 : Colors.hovering_blue,
                    }
            }}></WestIcon>
            Previous
          </ButtonBlue>
          <ButtonBlue onClick={handleNext} disabled={!nextBlog} variant="text" sx={{               
                color: !nextBlog ? Colors.black_60 :  Colors.white, textTransform: 'none', padding: 0,
                 backgroundColor:!nextBlog ? Colors.grey_30 :  Colors.primary_blue, borderColor:!nextBlog ? Colors.grey_30 : Colors.primary_blue,
                '&:hover':{color: !nextBlog ? Colors.black_60 :  Colors.white,background:!nextBlog ? Colors.grey_30 : Colors.hovering_blue,
                    }
            }}>
            Next<EastIcon sx={{               
                color: !nextBlog ? Colors.black_60 :  Colors.white, textTransform: 'none', padding: 0, fontSize:'16px',
                 backgroundColor:!nextBlog ? Colors.grey_30 :  Colors.primary_blue, borderColor:!nextBlog ? Colors.grey_30 : Colors.primary_blue,
                '&:hover':{color: !nextBlog ? Colors.black_60 :  Colors.white,background:!nextBlog ? Colors.grey_30 : Colors.hovering_blue,
                    }}}></EastIcon>
          </ButtonBlue>
            </Box>

            </Box>


        <Footer currentPageFooter={"Blog"}></Footer>

        {isShare && <Box sx={{
                position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.6)',
                display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '2',

            }}>
                <Box sx={{
                    width: '100%', height: '100%', gap: '5px',
                    justifyContent: 'center', alignItems: 'center', position: 'fixed',
                    display: 'flex', flexDirection: 'column',
                }}>
                    <Box sx={{
                        width: { md: '32%', sm: '40%', xs: '85%' }, height: '36px',
                        justifyContent: 'right', alignItems: 'right', display: 'flex',
                    }}>
                        <IconButton onClick={handleCloseSharePopUp} sx={{
                            position: 'fixed', width: '36px', height: '36px',
                        }} >
                            <CloseOutlinedIcon sx={{
                                width: '36px', height: '36px', color: Colors.black_60, '&:hover': {
                                    width: '40px', height: '40px', color: Colors.black_60, backgroundColor: 'none',
                                    background: 'none'
                                },
                            }} ></CloseOutlinedIcon>
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            width: { md: 'calc(30% - 32px)', sm: '40%', xs: '85%' }, height: 'fit-content',
                            backgroundColor: Colors.white, border: '1px solid',
                            borderColor: Colors.white, borderRadius: '4px ', boxShadow: 'none',
                            zIndex: '3', alignItems: 'center',padding:'32px'
                        }}>
                                
                                <Box sx={{
                                    display: 'flex', flexDirection: 'column', width: '100%', gap: '8px',
                                    justifyContent: 'center', alignContent: 'center', height: '100%', alignItems: 'stretch',
                                }}>
                                    <TypographyNormal sx={{ textAlign: 'left', fontWeight:500, fontSize:{md:'20px', xs:'16px'}, lineHeight: {md:'28px', xs:'20px'}}}>
                                        Share
                                    </TypographyNormal>
                                    <Box
                                    sx={{
                                        display:  'flex',
                                        flexDirection: 'row',
                                        alignItems: 'flex-start',
                                        width: '100%',
                                        gap: '12px',
                                        justifyContent: 'flex-start',
                                    }}
                                    >
                                   
                                    <img
                                        src={facebook}
                                        alt="facebook"
                                        style={{ width: '32px', height: '32px', cursor: 'pointer' }}
                                        onClick={() =>
                                            window.open(
                                                `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                                                    selectedUrl + `?title=${encodeURIComponent(selectedText)}`
                                                )}`,
                                                "_blank"
                                            )
                                        }
                                        
                                    />
                                     <img
                                        src={twitter}
                                        alt="twitter"
                                        style={{ width: '32px', height: '32px', cursor: 'pointer' }}
                                        onClick={() => window.open(`https://twitter.com/intent/tweet?url=${selectedUrl}&text=${selectedText}`, "_blank")}
                                    />
                                    <img
                                        src={linkedin}
                                        alt="linkedin"
                                        style={{ width: '32px', height: '32px', cursor: 'pointer' }}
                                        onClick={() =>
                                            window.open(
                                                `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(selectedUrl)}&title=${encodeURIComponent(selectedText)}&summary=&source=`,
                                                "_blank"
                                            )
                                        }
                                    />
                                   
                             
                           




                                    
                                    
                                    </Box> 

                                    <TypographyNormal sx={{ textAlign: 'left', paddingTop: '8px', fontWeight:500, fontSize:{md:'20px', xs:'16px'}, lineHeight: {md:'28px', xs:'20px'} }}>
                                    Share link
                                    </TypographyNormal>

                                    <Box sx={{
                                        width: 'calc(100%-32px)', height: '48px', border: '1px solid', padding: '10px 16px 10px 16px',
                                        borderColor: '#c9c9c9', borderRadius: '4px', display: 'flex', flexDirection: 'row',background:'#f9f9f9'
                                    }}>
                                        <InputBase
                                         value={selectedUrl}
                                            style={{ flex: 1, border: 'none', outline: 'none', background: 'transparent', }}
                                            inputProps={{
                                                style: {
                                                    fontFamily: 'Roboto', fontSize: {md:'16px', xs:'12px'},
                                                    '::placeholder': {
                                                        fontFamily: 'Roboto', fontSize: {md:'16px', xs:'12px'},
                                                    },
                                                },
                                            }}
                                        />
                                    </Box>
                                    <Box sx={{
                                        width: 'calc(100%)', height: '100%', paddingTop: '8px',
                                        display: 'flex', flexDirection: 'row', justifyContent: 'right',
                                    }}
                                    >
                                         <ButtonBlue onClick={handleCopyButtonClick}><ContentCopyIcon sx={{ fontSize: { xs: '12px', md: '18px' } }} />Copy link</ButtonBlue>
                                    </Box>
                                </Box>

              
                </Box>
                </Box>
                </Box>}

        </Grid>
    )
}
export default BlogDetail;