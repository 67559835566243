import React, { useEffect, useState, useRef } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom'; 
import logo from '../Images/userlogo.svg'
import logomob from '../Images/logomob.svg'
import Colors from './Colors';
import ButtonWhite from './ButtonWhite';
import ButtonBlue from './ButtonBlue';
import UserSignUp from '../Pages/UserSignUp';
import UserTermPopUp from '../Pages/UserTermPopUp';
import UserVerifyOtpPopUp from '../Pages/UserVerifyOtpPopUp';
import UserSignIn from '../Pages/UserSignIn';
import axios from "axios";
import { API_URL, IMG_URL } from './Constants';
import { Avatar } from '@mui/material';
import TypographyNormal from './TypographyNormal';
import TypographySubHeading from './TypographySubHeading';
import ForgotOtpPopUp from '../Pages/UserForgotPasswordOtp';
import UserResetPassword from '../Pages/UserResetPassword';
import ChangePasswordSuccessPopUp from '../Pages/UserChangePasswordSuccessPopUp';
import LoginErrorPopUp from '../Pages/LoginErrorPopUp';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SpSignUp from '../Pages/SpSignUp';
import SpSignIn from '../Pages/SpSignIn';
import SpTermPopUp from '../Pages/SpTermPopUp';
import SpVerifyOtpPopUp from '../Pages/SpVerifyOtpPopup';
import SpForgotOtpPopUp from '../Pages/SpforgotPasswordOtp';
import SpChangePasswordSuccessPopUp from '../Pages/SpChangePasswordSuccessPopUp';
import SpLoginErrorPopUp from '../Pages/SpLoginError';
import SpResetPassword from '../Pages/SpResetPassword';
import Badge from '@mui/material/Badge';
import msg from '../Images/msg.svg'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CircleIcon from '@mui/icons-material/Circle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import UserForgotPh from '../Pages/ForgotPhUser';
import SpForgotPh from '../Pages/ForgotPhSp';
import SearchComponentHeader from './SearchComponentHeader';


const pages = ['Home', 'About us', 'Contact us', 'Blog'];

const colors = ['#503259', '#4C50AE', '#FF7D6A', '#B07CC6', '#20C925'];

function stringToColor(string) {
  let hash = 0;
  for (let i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  const colorIndex = Math.abs(hash) % colors.length;
  return colors[colorIndex];
}

function stringAvatar(name = '') {   
    const initial = name.charAt(0).toUpperCase();
    
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: initial || '', 
    };
  }

//   const getStatusColor = (status) => {
//     switch (status) {
//       case 'Available':
//         return '#0DCA64'; // Green for Available
//       case 'Busy':
//         return '#FFA800'; // Orange for Busy
//       case 'Offline':
//         return '#EF2F22'; // Red for Offline
//       default:
//         return '#0DCA64'; // Default to Green if status is null or undefined
//     }
//   };

const getStatusIcon = (status) => {
    switch (status) {
      case 'Available':
        return (
          <DoneOutlinedIcon 
            sx={{ 
              color: 'white', // Green for Available
              backgroundColor: '#0DCA64',
              fontSize: '10px', border:'1px solid white', 
              position: 'absolute', 
              bottom: '0px', 
              right: '-4px', 
              borderRadius: '50%',
              padding: '2px' 
            }} 
          />
        );
      case 'Offline':
        return (
          <CloseIcon 
            sx={{ 
              color: 'white', // White cross
              backgroundColor: '#EF2F22', // Red background
              fontSize: '10px', border:'1px solid white', 
              position: 'absolute', 
              bottom: '0px', 
              right: '-4px', 
              borderRadius: '50%',
              padding: '2px' // Add padding to make the cross icon smaller within the circle
            }} 
          />
        );
      case 'Busy':
        return (
          <CircleIcon 
            sx={{ 
              color: '#FFA800', // Yellowish/orange circle for Busy
              fontSize: '10px',border:'1px solid white', 
              position: 'absolute', 
              bottom: '0px', 
              right: '-4px', 
              borderRadius: '50%', padding: '2px',
              backgroundColor: '#FFA800' // Same color for the background to show a plain circle
            }} 
          />
        );
      default:
        return null; // No icon for undefined status
    }
  };

function HeaderSecondPage(props) {
    const {currentPage} = props

    const dropDownRef = useRef(null);

    const [openDrawer, setOpenDrawer] = useState(false);
    const [selectedPage, setSelectedPage] = useState('');    
    const [popupType, setPopupType] = useState(null); 
    const [userDetails, setUserDetails] = useState([]);
    const [isShowDropdown, setIsShowDropdown] = useState(false);
    const [isSignOut, setIsSignOut] = useState(false);
    const [spPopupType, setSpPopupType] = useState(null); 
    const [serviceProviderDetails, setServiceProviderDetails] = useState([]);
    const [messageDetails, setMessageDetails] = useState([]);
    const [unreadMessageCount, setUnreadMessageCount] = useState(0);
    const [isProfileClick, setIsProfileClick] = useState(false);
    const [termsDetails, setTermsDetails] = useState([]);
    const [search, setSearch] = useState('');

    const handleOpenDrawer = () => {
        setOpenDrawer(true);
    };

    const handleCloseDrawer = () => {
        setOpenDrawer(false);
    };

    const handleMenuItemClick = (page) => {
        setSelectedPage(page);
    };

    const handleSignInButtonClick = () => {
        setPopupType('signin') 
        setSpPopupType("")  
                      
    }

    const handleAvatarButtonClick = () => {       
        setIsShowDropdown(prevState => !prevState);
    };

    useEffect(() => {
        if (localStorage.getItem("service")) {
          setSearch(localStorage.getItem("service"))
        } 
        else if(localStorage.getItem("Type")) {
          setSearch(localStorage.getItem("Type")) 
        }
        else if(localStorage.getItem("SPName")){
          setSearch(localStorage.getItem("SPName"))   
        }
        else if(localStorage.getItem("SPCode")){
          setSearch(localStorage.getItem("SPCode"))  
        }
    }, []);

    useEffect(() => { 
            
        if(localStorage.getItem("userToken")) {         
        const fetchUserData = async () => {            
            try { 
                const response = await axios.post(`${API_URL}/select_user_details_with_tocken`, {                    
                    userToken: localStorage.getItem("userToken")
                }); 
                setUserDetails(response.data.result)
                
            } catch (error) {
                console.error('Error fetching data:', error);
                
            }
        };
        fetchUserData();
    } 
    }, [localStorage.getItem("userToken")]);

    useEffect(() => {        
        if(localStorage.getItem("spToken")) {         
        const fetchSpData = async () => {            
            try { 
                const response = await axios.post(`${API_URL}/select_sp_details_with_tocken`, {                    
                    spToken: localStorage.getItem("spToken")
                }); 
                console.log(response.data.result.message_details)
                setServiceProviderDetails(response.data.result)
                setMessageDetails(response.data.result.message_details)
                const unreadCount = response.data.result.message_details.reduce((acc, message) => {
                if (message.read === 'Unread') {
                    return acc + 1;
                }
                return acc;
                }, 0);
                setUnreadMessageCount(unreadCount);

                
            } catch (error) {
                console.error('Error fetching data:', error);
                
            }
        };
        fetchSpData();
        const intervalId = setInterval(fetchSpData, 1000); 

        return () => clearInterval(intervalId);
    } 
    }, [localStorage.getItem("spToken")]);

    const handleSignOutClick = () => {
       setIsSignOut(true)
    };

    const handleSpSettingsClick = () => {
        window.location.href = "/sp-delete-account"  
     }; 

    const handleProfileClick = () => {
       setIsProfileClick(!isProfileClick) 
     };

     const handleUserProfileClick = () => {
        window.location.href = "/user-edit-profile"   
      };

     
    
    const handleIsSignOutPopUpSignOutButtonClick = () => {
        if(localStorage.getItem("userToken")){
            localStorage.removeItem("userToken")
        }
       else{
            localStorage.removeItem("spToken")
            
       }
        window.location.href = "/"
        setIsSignOut(false) 
        localStorage.setItem('signout', Date.now());        
     };     

     window.addEventListener('storage', (event) => {
        if (event.key === 'signout') {
            if(localStorage.getItem("userToken")){
                localStorage.removeItem("userToken")
            }
           else{
                localStorage.removeItem("spToken")
                
           }
          window.location.href = '/home';
        }
      });

    useEffect(() => {
        function handleClickOutside(event) {
          if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
            setIsShowDropdown(false); // Hide the box when clicking outside
          }
        }
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [dropDownRef, setIsShowDropdown]);


      
    const handleCloseIsSignOutPopUp = () => {
        setIsSignOut(false);     
    }

    const handleSpSignInButtonClick = () => {
        setSpPopupType('signin')  
        setPopupType('')                
    }
    
    const handleMessageClick = () => {
        localStorage.setItem("msgIndex", 0)
        window.location.href = '/view-message'
      };

      
      const handleUserSettingsClick = () => {
        window.location.href = "/user-delete-account"  
     }; 

     
     const handleProfileOfflineClick = async () => {
        
        try {
          const response = await axios.post(`${API_URL}/update_sp_status_offline`,
             { spToken: localStorage.getItem("spToken")
             });
             if(response.data.success === true){
                setIsProfileClick(false) 
                setIsShowDropdown(false)  
            }
        } catch (error) {
          console.error('Error updating stay away:', error);
        } 
      };

      const handleProfileAvailableClick = async () => {
        
        try {
          const response = await axios.post(`${API_URL}/update_sp_status_available`,
             { spToken: localStorage.getItem("spToken")
             });
             if(response.data.success === true){
                setIsProfileClick(false) 
                setIsShowDropdown(false)  
            }
        } catch (error) {
          console.error('Error updating stay away:', error);
        } 
      };
     
      const  handleProfileBusyClick = async () => {
        
        try {
          const response = await axios.post(`${API_URL}/update_sp_status_busy`,
             { spToken: localStorage.getItem("spToken")
             });
             if(response.data.success === true){
                setIsProfileClick(false) 
                setIsShowDropdown(false) 
            }
        } catch (error) {
          console.error('Error updating stay away:', error);
        } 
      };

      useEffect(() => {         
        const fetchTermseData = async () => {            
            try { 
                const response = await axios.post(`${API_URL}/select_terms`, {                    
                    title: 'signup'
                });  
                console.log(response.data.result)
                setTermsDetails(response.data.result);               
            } catch (error) {
                console.error('Error fetching data:', error);
                setTermsDetails('Failed to load Terms and condition. Please try again later.');
            }
        };
        fetchTermseData(); 

    }, []);

   
      

    return (
        <AppBar position="static" sx={{height:{ xs: '68px', md: '74px' } , justifyContent: 'center',width:'100%',gap:'32px',
        boxShadow: 'none', background: Colors.white_80, alignItems:'center',border:'1px solid ', borderColor: Colors.grey_30}}>
            <Container maxWidth={false}  sx={{ height: { xs: '48px', md: '74px' },width:{ xs: '98%', md: '96%' } ,}}>
                <Toolbar disableGutters sx={{ height: '100%',}}>
                <Box sx={{width: '100%' , height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',
                flexDirection:'row' }}>                   
                <Box onClick={() => { window.location.href = "/" }} sx={{width: { xs: '36px', md: '36px' } , height: { xs: '36px', md: '36px' }, display: { xs: 'none', md: 'flex' },
                justifyContent: 'center', alignItems: 'center',}}>
                <img src={logo} alt="Logo" style={{maxWidth: '100%', maxHeight: '100%', cursor: 'pointer' }} />
                </Box>
                <Box onClick={() => { window.location.href = "/" }} sx={{width: { xs: '32px', md: '24px' } , height: { xs: '32px', md: '24px' }, display: { xs: 'flex', md: 'none' },
                justifyContent: 'center', alignItems: 'center',}}>
                <img src={logomob} alt="Logo" style={{maxWidth: '100%', maxHeight: '100%', objectFit: 'contain',cursor: 'pointer' }} />
                </Box>
                <Box sx={{ flexGrow: 1, display: { xs:'flex', md:'none' },width:'16%' }}>
                        <IconButton  size="large"  aria-label="open drawer"
                        onClick={handleOpenDrawer} color={Colors.black_80}>
                        <MenuIcon />
                        </IconButton>
                        <Drawer
                            anchor="left"  open={openDrawer}
                            onClose={handleCloseDrawer}
                            sx={{'& .MuiDrawer-paper': {
                                    backgroundColor: Colors.hovering_blue, 
                                    zIndex:'9'
                                },
                                backdropFilter: 'none', }}
                                BackdropProps={{ 
                                    sx: { backgroundColor: 'rgba(0, 0, 0, 0)' }, 
                                }}
                             >
                            <Box sx={{ display: 'flex' }}>
                                <IconButton  aria-label="close drawer"
                                    onClick={handleCloseDrawer}
                                    sx={{ ml: 'auto', color: 'white' }}  >
                                <CloseIcon />
                                </IconButton>
                            </Box>
                            <Box  sx={{ width: 200 }} >                               
                                  {pages.map((page) => (
                                    <Link 
                                        key={page}
                                        to={`/${page.toLowerCase().replace(' ', '-')}`} 
                                        style={{ textDecoration: 'none', color: 'inherit' }}
                                    >
                                        <MenuItem
                                        selected={selectedPage === page}
                                        onClick={() => handleMenuItemClick(page)}
                                        sx={{
                                            color: "white", display: "block", textTransform: "none", background:  Colors.hovering_blue,
                                            fontFamily: "Roboto", fontSize: "16px", lineHeight: "36px", fontWeight: 400,
                                            '&:hover': { color: "white", bgcolor: 'white', fontWeight: "600" }
                                        }}
                                        >
                                        {page}
                                        </MenuItem>
                                    </Link>
                                    ))}
                                     { !localStorage.getItem("userToken") && !localStorage.getItem("spToken") &&
                <Box sx={{width:{ xs: '70%', md: '30%' }  , height: { xs: '28px', md: '28px' }, display:{md:'none', xs:'flex'}, justifyContent: 'center', 
                alignItems: 'center', gap:{ xs: '12px', md: '16px' } , flexDirection:'column', maxWidth:'98%', paddingLeft:'8%',paddingTop:'42px'}}>
               <ButtonWhite  onClick={handleSpSignInButtonClick} sx={{width:{ xs:'100% !important', md: '100% !important' }, borderColor: Colors.white,
                              background: Colors.hovering_blue, color: Colors.white}}> Business area</ButtonWhite>
               <ButtonBlue onClick={handleSignInButtonClick} sx={{width:{ xs:'100% !important', md: '100% !important' }}}>Sign in</ButtonBlue>
                </Box>}
                            </Box>
                           
                    </Drawer>
                       
                </Box>
                <Box sx={{flexGrow: 1, display: { xs: 'none', md: 'flex' }, height: '100%', width:'35%',justifyContent:'center',
                 alignItems:'center',gap:'26px' }}>
                        {pages.map((page) => (
                            <Button key={page}
                                sx={{color: Colors.black_80, display: "block",  textTransform: "none",
                                    fontFamily: "Roboto",fontSize: "16px", lineHeight: "24px", fontWeight: 400,
                                    '&:hover': { color: Colors.black_80,bgcolor:Colors.white_80, fontWeight: 600}}}>
                                <Link to={`/${page.toLowerCase().replace(' ', '-')}`} style={{ textDecoration: 'none', color: 'inherit' }}>{page}</Link>
                            </Button>
                        ))}
                </Box>
                <Box sx={{width:{ xs: '85%', md: '55%' }  , height: { xs: '36px', md: '36px' }, display: 'flex',
                 justifyContent: 'right', maxHeight:'36px', alignItems: 'center', }}>
                 <SearchComponentHeader isHeader={true} searchValue={search}>
                </SearchComponentHeader>
                </Box>
                { !localStorage.getItem("userToken") && !localStorage.getItem("spToken") &&
                <Box sx={{width:{ xs: '0%', md: '30%' }  , height: { xs: '36px', md: '36px' }, display:{md:'flex', xs:'none'}, justifyContent: 'right', 
                alignItems: 'center', gap:{ xs: '8px', md: '16px' } }}>
               <ButtonWhite  onClick={handleSpSignInButtonClick} sx={{width:{ xs:'fit-content !important', md: '100% !important' }}}> Business area</ButtonWhite>
               <ButtonBlue onClick={handleSignInButtonClick} sx={{width:{ xs:'fit-content !important', md: '100% !important' }}}>Sign in</ButtonBlue>
                </Box>}

                { (localStorage.getItem("userToken") || localStorage.getItem("spToken")) &&
                <>
                <Box sx={{width:{ xs: '10%', md: '30%' }  , height: { xs: '36px', md: '36px' }, display: 'flex', justifyContent: 'right', 
                alignItems: 'center', gap:{ xs: '12px', md: '16px' },  }} >              
                 { localStorage.getItem("userToken") && userDetails.name && (
                    <>
                    {userDetails.profile_img ? (
                        <Avatar alt="profile"
                            src={IMG_URL + userDetails.profile_img}  onClick={handleAvatarButtonClick}
                            sx={{  width: '32px', height: '32px', cursor: 'pointer'}}/>
                            ) : (
                                <Avatar  {...stringAvatar(userDetails.name)} onClick={handleAvatarButtonClick}
                                sx={{ width: '32px', height: '32px', fontSize: '20px', bgcolor:'#503259', cursor: 'pointer' }} /> 
                     )}                        
                         
                     {isShowDropdown && ( <Box ref={dropDownRef}   sx={{position: 'absolute', top: '100%', width:{ xs: '150px', md: '200px' } ,right:'0px',
                                borderRadius: '4px', marginTop: '4px', padding:{ xs: '4px', md: '10px' } , backgroundColor: Colors.white,
                                zIndex: 1,boxShadow: '0px 4px 4px 0px #00000040', gap:{ xs: '0px ', md: '4px' } , display:'flex', flexDirection:'column'
                            }}>
                                <Box sx={{ padding:{ xs: '4px 10px', md: '4px 10px' } , cursor: 'pointer', borderRadius: '4px', color: Colors.black_90,
                                    '&:hover': { backgroundColor: Colors.primary_blue,  color: Colors.white },
                                    }}>
                                    <TypographyNormal sx={{maxWidth: '98%', whiteSpace: 'nowrap',overflow: 'hidden',
                                        textOverflow: 'ellipsis',color: 'inherit'}} onClick={handleUserProfileClick}>
                                    My profile
                                    </TypographyNormal>
                                </Box>
                                <Box sx={{ padding: { xs: '4px 10px', md: '4px 10px' } , cursor: 'pointer', borderRadius: '4px',color: Colors.black_90,
                                    '&:hover': { backgroundColor: Colors.primary_blue ,  color: Colors.white},
                                    }} onClick={handleUserSettingsClick}>
                                    <TypographyNormal sx={{maxWidth: '98%', whiteSpace: 'nowrap',overflow: 'hidden',
                                        textOverflow: 'ellipsis',color: 'inherit'}}>
                                       Settings
                                    </TypographyNormal>
                                </Box>
                                <Box sx={{ padding: { xs: '4px 10px', md: '4px 10px' } , cursor: 'pointer', borderRadius: '4px',color: Colors.black_90,
                                    '&:hover': { backgroundColor: Colors.primary_blue ,  color: Colors.white},
                                    }} onClick={handleSignOutClick}>
                                    <TypographyNormal sx={{maxWidth: '98%', whiteSpace: 'nowrap',overflow: 'hidden',
                                        textOverflow: 'ellipsis',color: 'inherit'}}>
                                   Sign out
                                    </TypographyNormal>
                                </Box>
                                </Box>)}
                    </> 
                            )}
                
            { localStorage.getItem("spToken") && serviceProviderDetails.business_name && (
                <>
                    <Badge badgeContent={unreadMessageCount} color='error' overlap="circular"  
                    sx={{"& .MuiBadge-badge": {                          
                          height: 16, 
                          minWidth: 16, 
                          padding: '0 4px',
                        } , display:{md:'flex', xs:'none'}}} >
                        <img src={msg}
                        alt="Messages"
                        onClick={handleMessageClick}
                        style={{height: "32px",width: "32px",cursor: 'pointer',}}/>
                   </Badge>


                 
                    {serviceProviderDetails.profile_image ? (                        
                          <Box sx={{ position: 'relative', display: 'inline-block' }}> 
                            <Avatar alt="profile"
                            src={IMG_URL + serviceProviderDetails.profile_image}  onClick={handleAvatarButtonClick}
                            sx={{  width: '32px', height: '32px', cursor: 'pointer' }}/>
                                {getStatusIcon(serviceProviderDetails.profile_status)}
                                </Box>

                            ) : (
                                <Box sx={{ position: 'relative', display: 'inline-block' }}> 
                                <Avatar  
                                    {...stringAvatar(serviceProviderDetails.business_name)} 
                                    onClick={handleAvatarButtonClick}
                                    sx={{ width: '32px', height: '32px', fontSize: '20px', bgcolor:'#503259', cursor: 'pointer' }} 
                                />
                               {getStatusIcon(serviceProviderDetails.profile_status)}
                                </Box>
                     )}                        
                         
                     {isShowDropdown && ( <Box ref={dropDownRef}   sx={{position: 'absolute', top: '100%', width:{ xs: '150px', md: '200px' } ,right:'0px',
                                borderRadius: '4px', marginTop: '4px', padding:{ xs: '4px', md: '10px' } , backgroundColor: Colors.white,
                                zIndex: 1,boxShadow: '0px 4px 4px 0px #00000040', gap:{ xs: '0px ', md: '4px' } , display:'flex', flexDirection:'column'
                            }}>
                               <Box sx={{ padding:{ xs: '4px 10px', md: '4px 10px' } , cursor: 'pointer', borderRadius: '4px', 
                               color: Colors.black_90,
                                    '&:hover': { backgroundColor: Colors.primary_blue,  color: Colors.white },
                                    }}>
                                    <TypographyNormal sx={{maxWidth: '98%', whiteSpace: 'nowrap',overflow: 'hidden',
                                        textOverflow: 'ellipsis',color: 'inherit',justifyContent: 'space-between', 
                                        alignItems: 'center', display:'flex'  }} onClick={handleProfileClick}>
                                    Profile status {isProfileClick ? <KeyboardArrowUpIcon sx={{fontSize:'24px'}} /> : <KeyboardArrowDownIcon sx={{fontSize:'24px'}} />}

                                    </TypographyNormal>
                                </Box> 
                               { isProfileClick && (<Box sx={{ gap:'2px', display:'flex', flexDirection:'column', }}>
                                   <Box sx={{ padding:{ xs: '0px 10px', md: '4px 10px' } , cursor: 'pointer', borderRadius: '4px', 
                                    color: Colors.black_90, gap:'8px', display:'flex', flexDirection:'column',
                                    '&:hover': { backgroundColor: Colors.primary_blue,  color: Colors.white },
                                    }}>
                                    <TypographyNormal sx={{maxWidth: '98%', whiteSpace: 'nowrap',overflow: 'hidden',
                                        textOverflow: 'ellipsis',color: 'inherit',justifyContent: 'left', 
                                        alignItems: 'center', display:'flex'  }} onClick={handleProfileAvailableClick}>
                                        <CircleIcon sx={{ fontSize: 14, color: '#0DCA64', paddingRight:'8px' }} />Available

                                    </TypographyNormal>
                                    </Box>
                                    <Box sx={{ padding:{ xs: '4px 10px', md: '4px 10px' } , cursor: 'pointer', borderRadius: '4px', 
                                    color: Colors.black_90, gap:'8px', display:'flex', flexDirection:'column',
                                    '&:hover': { backgroundColor: Colors.primary_blue,  color: Colors.white },
                                    }}>
                                    <TypographyNormal sx={{maxWidth: '98%', whiteSpace: 'nowrap',overflow: 'hidden',
                                        textOverflow: 'ellipsis',color: 'inherit',justifyContent: 'left', 
                                        alignItems: 'center', display:'flex'  }} onClick={handleProfileBusyClick}>
                                        <CircleIcon sx={{ fontSize: 14, color: '#FFA800', paddingRight:'8px' }} />Busy

                                    </TypographyNormal>
                                   </Box>
                                   <Box sx={{ padding:{ xs: '4px 10px', md: '4px 10px' } , cursor: 'pointer', borderRadius: '4px', 
                                    color: Colors.black_90, gap:'8px', display:'flex', flexDirection:'column',
                                    '&:hover': { backgroundColor: Colors.primary_blue,  color: Colors.white },
                                    }}>
                                    <TypographyNormal sx={{maxWidth: '98%', whiteSpace: 'nowrap',overflow: 'hidden',
                                        textOverflow: 'ellipsis',color: 'inherit',justifyContent: 'left', 
                                        alignItems: 'center', display:'flex'  }} onClick={handleProfileOfflineClick}>
                                        <CircleIcon sx={{ fontSize: 14, color: '#EF2F22', paddingRight:'8px' }} />Offline

                                    </TypographyNormal>
                                   </Box>
                                </Box>)} 
                                <Box sx={{ padding: { xs: '4px 10px', md: '4px 10px' } , cursor: 'pointer', borderRadius: '4px',color: Colors.black_90,
                                    '&:hover': { backgroundColor: Colors.primary_blue ,  color: Colors.white},
                                    }} onClick={handleSpSettingsClick}>
                                    <TypographyNormal sx={{maxWidth: '98%', whiteSpace: 'nowrap',overflow: 'hidden',
                                        textOverflow: 'ellipsis',color: 'inherit'}}>
                                      Settings
                                    </TypographyNormal>
                                </Box> 
                                <Box sx={{ padding: { xs: '4px 10px', md: '4px 10px' } , cursor: 'pointer', borderRadius: '4px',color: Colors.black_90,
                                    '&:hover': { backgroundColor: Colors.primary_blue ,  color: Colors.white},
                                    }} onClick={handleSignOutClick}>
                                    <TypographyNormal sx={{maxWidth: '98%', whiteSpace: 'nowrap',overflow: 'hidden',
                                        textOverflow: 'ellipsis',color: 'inherit'}}>
                                   Sign out
                                    </TypographyNormal>
                                </Box>
                                </Box>)}
                                </>
               
                            )}
             </Box>
             </> }
                   
                </Box>
                </Toolbar>
            </Container>

            {popupType === 'signup' && (
               <UserSignUp setPopupType={setPopupType} popupType={popupType} />
            )}

            {popupType === 'terms' && (
                <UserTermPopUp setPopupType={setPopupType} popupType={popupType} termsDetails={termsDetails}/>
            )}
            
            {popupType === 'otp' && (
                <UserVerifyOtpPopUp setPopupType={setPopupType} popupType={popupType}/>
            )}
            
            {popupType === 'signin' && (
                <UserSignIn setPopupType={setPopupType} popupType={popupType}/>
            )}

            {popupType === 'forgototp' && (
                <ForgotOtpPopUp setPopupType={setPopupType} popupType={popupType}/>
            )}

            {popupType === 'userforgotph' && (
                <UserForgotPh setPopupType={setPopupType} popupType={popupType}/>
            )}      

            {popupType === 'changePassword' && (
                <UserResetPassword setPopupType={setPopupType} popupType={popupType}/>
            )}
               
            {popupType === 'successmsg' && (
                <ChangePasswordSuccessPopUp setPopupType={setPopupType} popupType={popupType}/>
            )}

            {popupType === 'loginerror' && (
                <LoginErrorPopUp setPopupType={setPopupType} popupType={popupType}/>
            )}

            {isSignOut && <Box sx={{
                            position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.6)',
                            display: 'flex',alignItems: 'center',justifyContent: 'center',zIndex: '5',

                        }}>
                            <Box sx={{
                                width: '100%', height: '100%', gap: '5px',
                                justifyContent: 'center', alignItems: 'center', position: 'fixed',
                                display: 'flex', flexDirection: 'column',
                            }}>
                                <Box sx={{
                                    width: { md: '25%', sm: '25%', xs: '85%' }, height: '36px',
                                    justifyContent: 'right', alignItems: 'right', display: 'flex',
                                }}>
                                     <IconButton onClick={handleCloseIsSignOutPopUp} sx={{
                                    position: 'fixed', width: '36px', height: '36px',}} >
                                        <CloseOutlinedIcon sx={{ width: '36px', height: '36px', color: Colors.black_60, '&:hover': {
                                        width: '40px', height: '40px', color: Colors.black_60, backgroundColor: 'none',
                                        background:'none'},}} ></CloseOutlinedIcon>
                                    </IconButton>
                                </Box>
                                <Box
                                    sx={{width: { md: '25%', sm: '25%', xs: '85%' },height: 'fit-content',
                                        backgroundColor: Colors.white, border: '1px solid',
                                        borderColor: Colors.white, borderRadius: '4px ',boxShadow: 'none',
                                        boxSizing: 'border-box',zIndex: '3', alignItems: 'center', 
                                    }}>

                                    <Box sx={{
                                        display: 'flex', flexDirection: 'column', width: '100%', gap: '16px',
                                        justifyContent: 'center', alignItems: 'center', height: '100%',
                                    }}>
                                          <Box sx={{
                                                display: 'flex', flexDirection: 'column', width: '100%', gap: '16px',paddingTop:'13%',
                                                justifyContent: 'center', alignContent: 'center', height: '86%',maxWidth:'75%',
                                                paddingBottom:'13%'
                                             }}>
                                        <Box sx={{
                                            display: 'flex', flexDirection: 'column', width: '100%', gap: '20px',
                                            justifyContent: 'center', alignContent: 'center', height: '100%', alignItems: 'center',
                                        }}>
                                            <TypographySubHeading sx={{fontSize:'18px' }}>
                                            Sign out
                                            </TypographySubHeading>

                                            <TypographyNormal sx={{textAlign: 'center',  }}>
                                            Are you sure you would like to sign out your account?
                                            </TypographyNormal>
                                            <Box sx={{ width:'100%',flexDirection: 'row',justifyContent:'center', display:'flex',
                                                    alignItems:'center',  gap:{xs:  '8px', md: '16px' } }}>                                            
                                                    <ButtonWhite onClick={handleCloseIsSignOutPopUp}>Cancel</ButtonWhite>
                                                    <ButtonBlue onClick={handleIsSignOutPopUpSignOutButtonClick} >Sign out</ButtonBlue>
                                                </Box>
                                           
                                        </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
            </Box>}  

            {spPopupType === 'signup' && (
               <SpSignUp setSpPopupType={setSpPopupType} spPopupType={spPopupType} />
            )}

            {spPopupType === 'signin' && (
                <SpSignIn setSpPopupType={setSpPopupType} spPopupType={spPopupType}/>
            )}

            {spPopupType === 'terms' && (
                <SpTermPopUp setSpPopupType={setSpPopupType} spPopupType={spPopupType} termsDetails={termsDetails}/>
            )}
             {spPopupType === 'otp' && (
                <SpVerifyOtpPopUp setSpPopupType={setSpPopupType} spPopupType={spPopupType}/>
            )}
            {spPopupType === 'forgototp' && (
                <SpForgotOtpPopUp setSpPopupType={setSpPopupType} spPopupType={spPopupType}/>
            )}

            {spPopupType === 'Spforgotph' && (
                <SpForgotPh setSpPopupType={setSpPopupType} spPopupType={spPopupType}/>
            )}

             {spPopupType === 'changePassword' && (
                <SpResetPassword setSpPopupType={setSpPopupType} spPopupType={spPopupType}/>
            )}
             {spPopupType === 'successmsg' && (
                <SpChangePasswordSuccessPopUp setSpPopupType={setSpPopupType} spPopupType={spPopupType}/>
            )}
             {spPopupType === 'loginerror' && (
                <SpLoginErrorPopUp setSpPopupType={setSpPopupType} spPopupType={spPopupType}/>
            )}

        </AppBar>
    );
}

export default HeaderSecondPage;