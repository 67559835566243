import React, { useEffect, useState, useRef } from 'react';
import { Grid, Box, Avatar, Button, IconButton, InputAdornment, colors, InputBase } from "@mui/material";
import Header from '../Components/Header';
import TypographySubHeading from "../Components/TypographySubHeading";
import TypographyHeading  from "../Components/TypographyHeading";
import TypographyNormal from "../Components/TypographyNormal";
import Footer from "../Components/Footer";
import Colors from '../Components/Colors';
import { Helmet } from 'react-helmet-async';
import logo from '../Images/logo.svg'
import { API_URL, FRONTEND_URL } from '../Components/Constants';
import axios from "axios";
import TextfieldNormal from '../Components/TextfieldNormal';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Link, useNavigate } from 'react-router-dom';
import blog1 from '../Images/blog1.jpg'
import share from '../Images/sharewhite.svg'
import ButtonBlue from '../Components/ButtonBlue';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import twitter from '../Images/twitter.svg'
import linkedin from '../Images/linkedin.svg'
import facebook from '../Images/facebook.svg'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';


function Blog(){

    function generateUrlFromTitle(title) {
        return title.toLowerCase().replace(/\s+/g, '-');
      }

      const imageMap = {
        blog1: blog1,
       
        // Add other images as needed
    };

    const [blogs, setBlogs] = useState([]);
    const [searchValue, setSearchValue] = useState('')    
    const [selectedUrl, setSelectedUrl] = useState("");
    const [selectedText, setSelectedText] = useState("");
    const [isShare, setIsShare] = useState(false);
    const [filteredBlogs, setFilteredBlogs] = useState([]);
    const [startPage, setStartPage] = useState(1);
    const maxVisiblePages = 5;

    const navigate = useNavigate();

    const boxRef = useRef(null);

    const articlesPerPage = 5;
    const totalPages = Math.ceil(blogs.length / articlesPerPage);

    const [currentPageNumber, setCurrentPageNumber] = useState(1);

    const startIndex = (currentPageNumber - 1) * articlesPerPage;
    const endIndex = Math.min(startIndex + articlesPerPage, blogs.length);

    const displayedprojects = blogs.slice(startIndex, endIndex);


    async function fetchData() {
    
      try {
          const response = await axios.get(`${API_URL}/blog_data`);                   
          console.log("details",response.data)
          if (response.data.success === true) {
          setBlogs(response.data.result)
          }
      } 
      catch (error) {
          console.error('Error:', error.message);
      }
    }
  
    useEffect(() => {
      fetchData();
    }, []);
    
     
  
      const handlePageClick = (page) => {
          setCurrentPageNumber(page);
      };

     

      const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchValue(value);
        const filtered = blogs.filter(blog => 
          blog.title.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredBlogs(filtered);
        if (boxRef.current) {
          if (value) {
            boxRef.current.style.display = 'block'; 
          } else {
            boxRef.current.style.display = 'none';  
          }
        }
      };

      const handleClearSearch = () => {
        setSearchValue('');
        if (boxRef.current) {
            boxRef.current.style.display = 'none';  
          }
      };


      const handleCopyButtonClick = async () => {
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(`${selectedUrl}`)
                .then(() => {
                    console.log('Link copied to clipboard');
                    setIsShare(false);
                })
                .catch((error) => {
                    console.error('Failed to copy:', error);
                    fallbackCopyTextToClipboard(`${selectedUrl}`);
                });

        } else {
            setIsShare(false);
            console.warn('Clipboard API not supported, unable to copy text');
            fallbackCopyTextToClipboard(`${selectedUrl}`);
        }
     };

    const fallbackCopyTextToClipboard = (text) => {
        const textArea = document.createElement('textarea');
        textArea.value = text;

        // Avoid scrolling to the bottom of the page
        textArea.style.position = 'fixed';
        textArea.style.top = '0';
        textArea.style.left = '0';
        textArea.style.width = '2em';
        textArea.style.height = '2em';
        textArea.style.padding = '0';
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';
        textArea.style.background = 'transparent';

        document.body.appendChild(textArea);
        textArea.select();

        try {
            const successful = document.execCommand('copy');
            console.log('Fallback: Copying text command was ' + (successful ? 'successful' : 'unsuccessful'));
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
        }

        document.body.removeChild(textArea);
    };

    const handleShareButtonClick = (url, text, e) => {
        e.stopPropagation();
        setIsShare(true); 
        setSelectedUrl(`${FRONTEND_URL}/blog/${url}`)
        setSelectedText(text)      
    };  

    
    const handleCloseSharePopUp = () => {
        setIsShare(false);
    };
      
    const recentBlogs = blogs
    .sort((a, b) => new Date(b.publish_date) - new Date(a.publish_date))
    .slice(0, 3);

    const handleNext = () => {
        const newStartPage = Math.min(startPage + maxVisiblePages, totalPages - maxVisiblePages + 1);
        setStartPage(newStartPage);
      };
    
      const handlePrevious = () => {
        const newStartPage = Math.max(startPage - maxVisiblePages, 1);
        setStartPage(newStartPage);
      };
    
      const visiblePages = Array.from(
        { length: Math.min(maxVisiblePages, totalPages - startPage + 1) },
        (_, index) => startPage + index
      );
    
    return(
        <Grid sx={{ width: '100%', height: '100%', padding: '0px', margin: '0px', }}>

            <Helmet>
                <title>handyman services</title>
                <meta name="description" content="handyman services" />
                <link rel="canonical" href="https://brohandy.com/blog" />
                <meta property="og:title" content="handyman services" />
                <meta property="og:description" content="handyman services" />
                <meta property="og:image" content={logo} />
                <meta property="og:url" content="https://brohandy.com/blog" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="BroHandy" />
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "http://schema.org",
                        "@type": "WebPage",
                        "name": "Blog - BroHandy",
                        "url": "https://brohandy.com/blog",
                        "description": "handyman services",
                        "mainEntityOfPage": "https://brohandy.com/blog",
                        "potentialAction": {
                        "@type": "ContactAction",
                        "target": "contact@brohandy.com",
                        "query-input": "required name=email"
                        }
                    }
                    `}
                </script>
            </Helmet>
            <h1 style={{ position: 'absolute', left: '-999999px' }}>handyman services</h1>



       
        <Header currentPage={"Blog"}></Header>

        <Box sx={{
            width: '100%', height: { xs: '95%', md: '97.5%' },justifyContent: 'center', alignItems: 'center', display:  'flex', 
            flexDirection: 'column',gap:'16px', paddingTop:{ xs: '5%', md: '2.5%' }, }}>
                <TypographySubHeading sx={{fontSize:{ xs: '16px', md: '28px' } , fontWeight:600, lineHeight:{ xs: '24px', md: '36px' }, color: Colors.dark_purple}}>Blog</TypographySubHeading>
                <Box sx={{width: '100%', height: '95%', justifyContent: 'center', alignItems: 'center', display:  'flex', 
                      flexDirection: 'column',maxWidth:'92.5%', paddingBottom:'5%', minHeight:'50vh',  }}>
             <Box sx={{width: '100%', height: '100%', justifyContent: 'flex-start', alignItems: 'center', display:  'flex', 
                      flexDirection: 'column',gap:'20px', }}>

              <Box sx={{ height: 'fit-content', display: 'flex', width: '100%',flexDirection: 'column', }}>   
                <TextfieldNormal sx={{ width: {md:'40%', xs:'100%'} }} 
                value={searchValue} name="search"                placeholder="Search"
                onChange={handleSearchChange}
                InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                    {searchValue ? (
                        <IconButton onClick={handleClearSearch}>
                         <CloseOutlinedIcon sx={{ color: Colors.black_80, fontSize: { xs: '16px', md: '18px' } }} />
                        </IconButton>
                    ) : (
                        <SearchOutlinedIcon sx={{ color: Colors.black_80, fontSize: { xs: '16px', md: '18px' } }} />
                    )}
                    </InputAdornment>
                ),
                }}/>

                {searchValue && (
                <Box
                    ref={boxRef}
                    sx={{
                    width: '37%',
                    position: 'absolute',
                    marginTop: '46px', // Adjust based on header height
                    borderRadius: '4px',
                    paddingBottom: '8px',
                    maxHeight: {md:'170px', xs:'130px'},
                    overflow: 'auto',
                    boxShadow: ' 0px 4px 4px 0px #00000040',
                    display: 'flex',justifyContent:'flex-start',alignItems:'flex-start',
                    flexDirection: 'column',                   
                    background: 'white',
                    paddingTop: '8px',
                    zIndex: 9,
                    gap: '4px',border: '1px solid', borderColor: Colors.grey_30,
                    '&::-webkit-scrollbar': {
                        width: '11px',
                        height: '8px',
                        borderRadius: '16px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: 'grey',
                        borderRadius: '16px',
                        border: '2px solid white',
                    },
                    '&::-webkit-scrollbar-track': {
                        borderRadius: '16px',
                        backgroundColor: 'white',
                    },
                    }}
                >
                    {filteredBlogs.length === 0 ? (
                    <Box
                        sx={{
                            width: 'calc(100% - 16px)',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '8px',
                            padding: '8px',                          
                            justifyContent: 'center',
                            color: '#313131',fontFamily:'Roboto', 
                        }}
                    >
                         <TypographyNormal sx={{ minWidth: 'fit-content', color: 'inherit' }}>
                         No results found for your search.
                        </TypographyNormal>
                       
                    </Box>
                    ) : (
                    filteredBlogs.map((blog, index) => (
                        <Box
                        key={blog.title}
                        sx={{
                            width: 'calc(100% - 16px)',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '8px',
                            padding: '8px',
                            cursor: 'pointer',
                            justifyContent: 'flex-start',
                            color: '#313131',fontFamily:'Roboto', 
                            '&:hover': { backgroundColor: Colors.hovering_grey, color: Colors.black_80 },
                        }}
                        onClick={() => { window.location.href = `/blog/${generateUrlFromTitle(blog.title)}`; }}
                        >
                        <TypographyNormal sx={{ minWidth: 'fit-content', color: 'inherit' ,}}>
                            {blog.title}
                        </TypographyNormal>
                       
                        </Box>
                    ))
                    )}
                </Box>
                )}
              </Box> 
              <Box sx={{ height: 'fit-content', display: 'flex', width: '100%',flexDirection: 'column', gap:'8px',paddingBottom:'16px',
                justifyContent:'flex-start', alignItems:'flex-start', borderBottom:'1px solid', borderColor:Colors.grey_30 ,}}>              
                <TypographySubHeading sx={{fontSize:{ xs: '20px', md: '32px' } , fontWeight:600, lineHeight:{ xs: '24px', md: '36px' }, color: Colors.black_80}}>Latest articles</TypographySubHeading>
                <Box sx={{ height: 'fit-content', display: 'flex', width: '100%',flexDirection: 'row', gap:{ xs: '20px', md: '32px' },
                justifyContent:'flex-start', alignItems:'flex-start'}}>
                    {recentBlogs.map((blog) => (
                    <Box key={blog.blog_id} sx={{ padding: {md:'8px', xs:'0px'},  borderRadius: '4px', width:{md:'calc(100% / 3)', xs:'100%'}, gap:'8px',
                        height: 'fit-content', display: 'flex', flexDirection: 'column',
                        justifyContent:'flex-start', alignItems:'flex-start'    
                     }}>
                        <TypographyNormal component="h2"  onClick={() => { window.location.href = `/blog/${generateUrlFromTitle(blog.title)}`; }}
                sx={{fontSize:{ xs: '16px', md: '20px' } , fontWeight:500, 
                        lineHeight:{ xs: '24px', md: '36px' }, color: Colors.black_80, cursor:'pointer',
                        '&:hover':{color: Colors.primary_blue,}}}>{blog.title}</TypographyNormal>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width:'100%', height:'275px',
                         gap:'16px', position:'relative'}}>
                        <img onClick={() => { window.location.href = `/blog/${generateUrlFromTitle(blog.title)}`; }}  
                        src={imageMap[blog.image]}  alt='blog' style={{width:'100%', height:'275px', cursor:'pointer'}}></img>
                        <IconButton
                        onClick={(e) => handleShareButtonClick(generateUrlFromTitle(blog.title), blog.title, e)}
                        sx={{  position: 'absolute', bottom: {md:'10px', xs:'16px'}, right: '10px',
                            background:'#313131C4' ,  '&:hover': { background:'#313131C4'} }} >
                        <img src={share} alt="Share Icon" style={{ width: '20px', height: '20px' }}/>
                        </IconButton>
                        </Box>
                        <TypographyNormal component="h2" sx={{fontSize:{ xs: '16px', md: '12px' } , fontWeight:400, 
                        lineHeight:{ xs: '24px', md: '12px' }, color: '#767676'}}>Published On {blog.publish_date}</TypographyNormal>
                        <TypographyNormal 
                        sx={{                               
                            display: '-webkit-box',marginTop:'-8px',
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            WebkitLineClamp: 4,  // Limits the content to 4 lines
                        }}
                        dangerouslySetInnerHTML={{ __html: blog.blog_content }} 
                        />
                        <Link to={ `/blog/${generateUrlFromTitle(blog.title)}`} sx={{ textDecoration: 'none', }}>
                        <Button variant="text" sx={{
                            fontFamily: 'Roboto', fontSize:{md: '20px', xs:'12px'}, fontWeight: 600, lineHeight: '36px',
                            color:Colors.primary_blue, textTransform: 'none', padding: 0,backgroundColor:'none', 
                            '&:hover':{color:Colors.hovering_blue, fontWeight:600, backgroundColor:'none', background:'none'}
                        }}>
                            Read more
                        </Button>
                       </Link>
                    </Box>
                    ))} 
                    
                        
                        
                </Box>
              
              </Box>
              <Box sx={{ height: 'fit-content', display: 'flex', width: '100%',flexDirection: 'column', gap:'8px',
                justifyContent:'flex-start', alignItems:'flex-start', }}>              
                <TypographySubHeading sx={{fontSize:{ xs: '20px', md: '32px' } , fontWeight:600, lineHeight:{ xs: '24px', md: '36px' }, color: Colors.black_80}}>Articles</TypographySubHeading>
                <Box sx={{ height: 'fit-content', display: 'flex', width: '100%',flexDirection: {md:'row', xs:'column'}, gap:'28px',
                justifyContent:'flex-start', alignItems:'flex-start' }}> 
                <Box sx={{ height: 'fit-content', display: 'flex', width:{md: '68%', xs:'100%'},flexDirection: 'column', gap:'16px',
                justifyContent:'flex-start', alignItems:'flex-start' }}> 
                {displayedprojects.map((blog, index) => (
                 <Box key={index} sx={{ height: 'fit-content', display: 'flex', width: '100%',flexDirection: 'column', gap:'16px',
                    justifyContent:'flex-start', alignItems:'flex-start' }}>    
                <TypographyNormal component="h2"  onClick={() => { window.location.href = `/blog/${generateUrlFromTitle(blog.title)}`; }}
                sx={{fontSize:{ xs: '16px', md: '20px' } , fontWeight:500, 
                        lineHeight:{ xs: '24px', md: '36px' }, color: Colors.black_80, cursor:'pointer',
                        '&:hover':{color: Colors.primary_blue,}}}>{blog.title}</TypographyNormal>
                         <Box sx={{ height: 'fit-content', display: 'flex', width: '100%',flexDirection:{md: 'row', xs:'column'}, gap:'16px',
                        justifyContent:'flex-start', alignItems:{md:'stretch',xs: 'center'}, }}> 
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width:{md:'40%', xs:'100%'},
                         gap:'16px', position:'relative',height:{md:'235px', xs:'225px'}  }}>
                        <img  src={imageMap[blog.image]} onClick={() => { window.location.href = `/blog/${generateUrlFromTitle(blog.title)}`; }}
                          alt='blog' style={{width:'100%', height:'100%', cursor:'pointer' }}></img>
                        <IconButton
                        onClick={(e) => handleShareButtonClick(generateUrlFromTitle(blog.title), blog.title, e)}
                        sx={{  position: 'absolute', bottom: {md:'10px', xs:'16px'}, right: '10px',
                            background:'#313131C4' ,  '&:hover': { background:'#313131C4'} }} >
                        <img src={share} alt="Share Icon" style={{ width: '20px', height: '20px' }}/>
                        </IconButton>
                        </Box>
                       
                       <Box sx={{ display: 'flex', flexDirection: 'column',  width:{md:'60%', xs:'100%'}, 
                         justifyContent:'flex-start', alignItems:'flex-start', }}>
                            <TypographyNormal 
                        sx={{                               
                            display: '-webkit-box', marginTop:'-16px',
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            WebkitLineClamp: 8,  // Limits the content to 4 lines
                        }}
                        dangerouslySetInnerHTML={{ __html: blog.blog_content }} 
                        />
                        <Link to={ `/blog/${generateUrlFromTitle(blog.title)}`} sx={{ textDecoration: 'none', }}>
                        <Button variant="text" sx={{
                            fontFamily: 'Roboto', fontSize:{md: '20px', xs:'12px'}, fontWeight: 600,  marginBottom:'-12px',
                            color:Colors.primary_blue, textTransform: 'none', padding: 0,backgroundColor:'none', 
                            '&:hover':{color:Colors.hovering_blue, fontWeight:600, backgroundColor:'none', background:'none'}
                        }}>
                            Read more
                        </Button>
                       </Link>
                        </Box>
                        </Box>
                        <TypographyNormal component="h2" sx={{fontSize:{ xs: '16px', md: '16px' } , fontWeight:400, 
                        lineHeight:{ xs: '24px', md: '12px' }, color: '#767676'}}>Published On {blog.publish_date}</TypographyNormal>
                        
                       </Box>))}
                </Box>
                <Box sx={{width:'2%', display:{md:'flex', xs:'none'}}}></Box>
                <Box sx={{ height: 'fit-content', display: 'flex', width:{md: '30%', xs:'100%'},flexDirection: 'column', gap:'12px',
                justifyContent:'flex-start', alignItems:'flex-start' }}> 
                <TypographyNormal sx={{fontSize:{ xs: '20px', md: '20px' } , fontWeight:400, 
                lineHeight:{ xs: '24px', md: '20px' }, color: '#000'}}>More  articles</TypographyNormal>
                {blogs.map((blog) => (
                <TypographyNormal  key={blog.blog_id} onClick={() => { window.location.href = `/blog/${generateUrlFromTitle(blog.title)}`; }}
                 sx={{fontSize:{ xs: '16px', md: '16px' } , fontWeight:400, cursor:'pointer' ,
                lineHeight:{ xs: '24px', md: '20px' }, color: Colors.black_80, '&:hover':{color:Colors.hovering_blue,}}}>{blog.title}</TypographyNormal>
                ))}
                </Box>
                </Box>
            </Box>        
        </Box>
        </Box>
      

        {/* <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', alignItems: 'center', width: '100%', gap: '10px', padding: '20px', paddingTop: '40px' }}>
                {Array.from({ length: totalPages }, (_, index) => (
                    <Button
                        key={index + 1}
                        onClick={() => handlePageClick(index + 1)}
                        variant={currentPageNumber === index + 1 ? "contained" : "outlined"}
                        sx={{
                            width: '28px', height: '28px', borderRadius: '16px', padding: 0, minWidth: 0,
                            backgroundColor: currentPageNumber === index + 1 ? Colors.primary_blue : Colors.white_80,
                            color: currentPageNumber === index + 1 ? '#ffffff' : Colors.primary_blue, border: '1px solid #5D5FEF',
                            '&:hover': { bgcolor: Colors.hovering_blue, color: '#fff', border: '1px solid #5D5FEF' }
                        }}
                    >
                        {index + 1}
                    </Button>
                ))}
            </Box> */}
            <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "row",
        alignItems: "center",
        width: 'calc(100% - 40px)', gap: "10px",
       
        paddingTop: "40px", paddingBottom:'40px'
      }}
    >
      {startPage > 1 && (
        <IconButton onClick={handlePrevious} sx={{ color: Colors.black_70, background: Colors.white_80, width: "30px",
            height: "30px", "&:hover": {background: Colors.grey_30} }}>
          <ArrowBackIosIcon sx={{fontSize:'14px'}}/>
        </IconButton>
      )}

      {visiblePages.map((page) => (
        <Button
          key={page}
          onClick={() => handlePageClick(page)}
          variant={currentPageNumber === page ? "contained" : "outlined"}
          sx={{
            width: "28px",
            height: "28px",
            borderRadius: "16px",
            padding: 0,
            minWidth: 0,
            backgroundColor: currentPageNumber === page ? Colors.primary_blue : Colors.white_80,
            color: currentPageNumber === page ? "#ffffff" : Colors.primary_blue,
            border: "1px solid ",borderColor:  Colors.primary_blue,
            "&:hover": {
              bgcolor: Colors.hovering_blue,
              color: "#fff",
              border: "1px solid", borderColor:  Colors.hovering_blue,
            },
          }}
        >
          {page}
        </Button>
      ))}

      {startPage + maxVisiblePages - 1 < totalPages && (        
        <IconButton onClick={handleNext} sx={{ color: Colors.black_70, background: Colors.white_80, width: "30px",
            height: "30px", "&:hover": {background: Colors.grey_30} }}>
          <ArrowForwardIosIcon sx={{fontSize:'14px'}} />
        </IconButton>
     )} 
    </Box>
    </Box>

        <Footer currentPageFooter={"Blog"}></Footer>

        {isShare && <Box sx={{
                position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.6)',
                display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '2',

            }}>
                <Box sx={{
                    width: '100%', height: '100%', gap: '5px',
                    justifyContent: 'center', alignItems: 'center', position: 'fixed',
                    display: 'flex', flexDirection: 'column',
                }}>
                    <Box sx={{
                        width: { md: '32%', sm: '40%', xs: '85%' }, height: '36px',
                        justifyContent: 'right', alignItems: 'right', display: 'flex',
                    }}>
                        <IconButton onClick={handleCloseSharePopUp} sx={{
                            position: 'fixed', width: '36px', height: '36px',
                        }} >
                            <CloseOutlinedIcon sx={{
                                width: '36px', height: '36px', color: Colors.black_60, '&:hover': {
                                    width: '40px', height: '40px', color: Colors.black_60, backgroundColor: 'none',
                                    background: 'none'
                                },
                            }} ></CloseOutlinedIcon>
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            width: { md: 'calc(30% - 32px)', sm: '40%', xs: '85%' }, height: 'fit-content',
                            backgroundColor: Colors.white, border: '1px solid',
                            borderColor: Colors.white, borderRadius: '4px ', boxShadow: 'none',
                            zIndex: '3', alignItems: 'center',padding:'32px'
                        }}>
                                
                                <Box sx={{
                                    display: 'flex', flexDirection: 'column', width: '100%', gap: '8px',
                                    justifyContent: 'center', alignContent: 'center', height: '100%', alignItems: 'stretch',
                                }}>
                                    <TypographyNormal sx={{ textAlign: 'left', fontWeight:500, fontSize:{md:'20px', xs:'16px'}, lineHeight: {md:'28px', xs:'20px'}}}>
                                        Share
                                    </TypographyNormal>
                                    <Box
                                    sx={{
                                        display:  'flex',
                                        flexDirection: 'row',
                                        alignItems: 'flex-start',
                                        width: '100%',
                                        gap: '12px',
                                        justifyContent: 'flex-start',
                                    }}
                                    >
                                   
                                    <img
                                        src={facebook}
                                        alt="facebook"
                                        style={{ width: '32px', height: '32px', cursor: 'pointer' }}
                                        onClick={() =>
                                            window.open(
                                                `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                                                    selectedUrl + `?title=${encodeURIComponent(selectedText)}`
                                                )}`,
                                                "_blank"
                                            )
                                        }
                                        
                                    />
                                     <img
                                        src={twitter}
                                        alt="twitter"
                                        style={{ width: '32px', height: '32px', cursor: 'pointer' }}
                                        onClick={() => window.open(`https://twitter.com/intent/tweet?url=${selectedUrl}&text=${selectedText}`, "_blank")}
                                    />
                                    <img
                                        src={linkedin}
                                        alt="linkedin"
                                        style={{ width: '32px', height: '32px', cursor: 'pointer' }}
                                        onClick={() =>
                                            window.open(
                                                `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(selectedUrl)}&title=${encodeURIComponent(selectedText)}&summary=&source=`,
                                                "_blank"
                                            )
                                        }
                                    />
                                   
                             
                           




                                    
                                    
                                    </Box> 

                                    <TypographyNormal sx={{ textAlign: 'left', paddingTop: '8px', fontWeight:500, fontSize:{md:'20px', xs:'16px'}, lineHeight: {md:'28px', xs:'20px'} }}>
                                    Share link
                                    </TypographyNormal>

                                    <Box sx={{
                                        width: 'calc(100%-32px)', height: '48px', border: '1px solid', padding: '10px 16px 10px 16px',
                                        borderColor: '#c9c9c9', borderRadius: '4px', display: 'flex', flexDirection: 'row',background:'#f9f9f9'
                                    }}>
                                        <InputBase
                                         value={selectedUrl}
                                            style={{ flex: 1, border: 'none', outline: 'none', background: 'transparent', }}
                                            inputProps={{
                                                style: {
                                                    fontFamily: 'Roboto', fontSize: {md:'16px', xs:'12px'},
                                                    '::placeholder': {
                                                        fontFamily: 'Roboto', fontSize: {md:'16px', xs:'12px'},
                                                    },
                                                },
                                            }}
                                        />
                                    </Box>
                                    <Box sx={{
                                        width: 'calc(100%)', height: '100%', paddingTop: '8px',
                                        display: 'flex', flexDirection: 'row', justifyContent: 'right',
                                    }}
                                    >
                                         <ButtonBlue onClick={handleCopyButtonClick}><ContentCopyIcon sx={{ fontSize: { xs: '12px', md: '18px' } }} />Copy link</ButtonBlue>
                                    </Box>
                                </Box>

              
                </Box>
                </Box>
                </Box>}

        </Grid>
    )
}
export default Blog;