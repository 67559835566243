import { Box, Button, MenuItem, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { API_URL } from './Constants';
import TypographyNormal from './TypographyNormal';
import TextfieldNormal from './TextfieldNormal';
import { TableSortLabel } from '@mui/material';

function AdminServices({serviceDetails,setServiceDetails,updateTrigger}) {
    console.log(serviceDetails)
    const token = localStorage.getItem('adminToken');
    // const [serviceDetails, setServiceDetails] = useState([]);
    const [editId, setEditId] = useState(null);
    const [newServiceName, setNewServiceName] = useState('');
    const [addingService, setAddingService] = useState(false);
    const [newServiceInput, setNewServiceInput] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [professionalName, setProfessionalName] = useState('');
    const [newProfessionalName, setNewProfessionalName] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');

    useEffect(() => {
        // Code to fetch or update data goes here
        console.log('AdminService component updated');
    }, [updateTrigger]);
    const handleSortRequest = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);

        const sortedData = [...serviceDetails].sort((a, b) => {
            if (order === 'asc') {
                return a[property] > b[property] ? 1 : -1;
            } else {
                return a[property] < b[property] ? 1 : -1;
            }
        });
        setServiceDetails(sortedData);
    };

    const handleSaveClick = async (service) => {
        if (!newServiceName || !newProfessionalName) {
            alert('Service Name and Professional Name cannot be empty.');
            return;
        }
        try {
            const response = await axios.post(`${API_URL}/admin_update_service`, {
                token,
                service_id: service.service_id,
                service_name: newServiceName,
                professional_name: newProfessionalName,
                // category_id:service.service_category
            });

            if (response.data.success) {
                // Update the local state with the new service name
                setServiceDetails((prevDetails) =>
                    prevDetails.map((srv) =>
                        srv.service_id === service.service_id
                            ? { ...srv, service_name: newServiceName }
                            : srv
                    )
                );
                setEditId(null); // Exit edit mode
            } else {
                console.error('Error:', response.data.error);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    };

    const handleCancelClick = () => {
        setEditId(null);
        setNewServiceName('');
    };

    const handleEditClick = (service) => {
        setAddingService(false);
        setNewServiceInput('');
        setSelectedCategory('');
        setProfessionalName('');
        setEditId(service.service_id);
        setNewServiceName(service.services);
        setNewProfessionalName(service.professional_name);
    };
    useEffect(() => {
        fetchAllServices();
        // fetchAllCategories();
    }, []);
    // async function fetchAllCategories() {
    //     try {
    //         const response = await axios.post(`${API_URL}/admin_get_all_categories_for_dropdown`, { token });
    //         console.log(response.data.data);
    //         if (response.data.success) {
    //             setCategories(response.data.data);
    //         } else {
    //             console.error('Error:', response.data.error);
    //         }
    //     } catch (error) {
    //         console.error('Error:', error.message);
    //     }
    // }

    async function fetchAllServices() {
        try {
            const response = await axios.post(`${API_URL}/admin_get_all_services`, { token });
            console.log(response.data.data);
            if (response.data.success) {
                setServiceDetails(response.data.data);
            } else {
                console.error('Error:', response.data.error);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    }

    const handleAddClick = () => {
        setEditId(null);
        setNewServiceName('');
        setAddingService(true);
    };

    const handleAddSaveClick = async () => {
        if (!newServiceInput ||  !professionalName) {
            alert('Service Name and Professional Name cannot be empty.');
            return;
        }
        try {
            const response = await axios.post(`${API_URL}/admin_add_service`, {
                token,
                service_name: newServiceInput,
                // service_category_id: selectedCategory,
                professional_name: professionalName
            });

            if (response.data.success) {
                setServiceDetails([...serviceDetails, response.data.data]);
                console.log("serviceDetails", serviceDetails)
                console.log(",,,,,,,,,,,,", response.data.data)
                setAddingService(false);
                setNewServiceInput('');
                setSelectedCategory('');
                setProfessionalName('');
            } else {
                console.error('Error:', response.data.error);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    };

    const handleAddCancelClick = () => {
        setAddingService(false);
        setNewServiceInput('');
        setSelectedCategory('');
        setProfessionalName('');
    };
    const handleStatusToggle = async (service) => {
        const newStatus = service.status === 'Active' ? 'Inactive' : 'Active';
        try {
            const response = await axios.post(`${API_URL}/admin_update_service_status`, {
                token,
                service_id: service.service_id,
                status: newStatus
            });

            if (response.data.success) {
                setServiceDetails((prevDetails) =>
                    prevDetails.map((srv) =>
                        srv.service_id === service.service_id
                            ? { ...srv, status: newStatus }
                            : srv
                    )
                );
            } else {
                console.error('Error:', response.data.error);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    };

    return (
        <Box sx={{ mt: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <TypographyNormal variant="h6">Service Management</TypographyNormal>
                <Button variant="contained" color="primary" onClick={handleAddClick}>Add Service</Button>
            </Box>
            {addingService && (
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, width: '100%' }}>
                    {/* <Select
                        value={selectedCategory}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                        displayEmpty
                        sx={{ mr: 2, width: '20%' }}
                    >
                        <MenuItem value="" disabled>Select Category</MenuItem>
                        {categories.map((category) => (
                            <MenuItem key={category.service_category_id} value={category.service_category_id}>
                                {category.service_category}
                            </MenuItem>
                        ))}
                    </Select> */}
                    <TextfieldNormal
                        placeholder="New Service Name"
                        value={newServiceInput}
                        onChange={(e) => setNewServiceInput(e.target.value)}
                        sx={{ mr: 2, width: '25%' }}
                    />

                    <TextfieldNormal
                        placeholder="Professional Name"
                        value={professionalName}
                        onChange={(e) => setProfessionalName(e.target.value)}
                        sx={{ mr: 2, width: '25%' }}
                    />
                    <Button variant="contained" color="primary" onClick={handleAddSaveClick}>Save</Button>
                    <Button variant="contained" color="secondary" onClick={handleAddCancelClick} sx={{ ml: 1 }}>Cancel</Button>
                </Box>
            )}
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Service ID</TableCell>
                            <TableCell> <TableSortLabel
                                active={orderBy === 'services'}
                                direction={orderBy === 'services' ? order : 'asc'}
                                onClick={() => handleSortRequest('services')}
                            >
                                Service
                            </TableSortLabel></TableCell>
                            <TableCell>Professional name</TableCell>
                            {/* <TableCell> <TableSortLabel
                                active={orderBy === 'service_category'}
                                direction={orderBy === 'service_category' ? order : 'asc'}
                                onClick={() => handleSortRequest('service_category')}
                            >
                                Category
                            </TableSortLabel></TableCell> */}
                            <TableCell>Status</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {serviceDetails && serviceDetails.map((row) => (
                            <TableRow key={row.service_id}>
                                <TableCell>{row.service_id}</TableCell>
                                <TableCell>
                                    {editId === row.service_id ? (
                                        <TextfieldNormal
                                            value={newServiceName}
                                            onChange={(e) => setNewServiceName(e.target.value)}
                                        />
                                    ) : (
                                        row.services
                                    )}
                                </TableCell>
                                <TableCell>
                                    {editId === row.service_id ? (
                                        <TextfieldNormal
                                            value={newProfessionalName}
                                            onChange={(e) => setNewProfessionalName(e.target.value)}
                                        />
                                    ) : (
                                        row.professional_name
                                    )}
                                </TableCell>
                                {/* <TableCell>{row.service_category}</TableCell> */}
                                <TableCell>
                                    <Switch
                                        checked={row.status === 'Active'}
                                        onChange={() => handleStatusToggle(row)}

                                    />
                                </TableCell>
                                <TableCell sx={{ display: 'flex', gap: '8px' }}>
                                    {editId === row.service_id ? (
                                        <>
                                            <Button variant="contained" color="primary" onClick={() => handleSaveClick(row)}>Save</Button>
                                            <Button variant="contained" color="secondary" onClick={handleCancelClick}>Cancel</Button>
                                        </>
                                    ) : (
                                        <Button variant="contained" color="primary" onClick={() => handleEditClick(row)}>Edit</Button>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default AdminServices;
