import { Box, Button, MenuItem, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { API_URL } from './Constants';
import TypographyNormal from './TypographyNormal';
import TextfieldNormal from './TextfieldNormal';
import { TableSortLabel } from '@mui/material';

function AdminUserReview({ userDetails, setUserDetails, updateTrigger }) {
    console.log(userDetails)
    const token = localStorage.getItem('adminToken');
    // const [sPDetails, setSPDetails] = useState([]);
    const ratings = userDetails?.ratings || [];

    const [searchQuery, setSearchQuery] = useState('');
    const [filteredSpDetails, setFilteredSpDetails] = useState([]);
    const [editId, setEditId] = useState(null);
    const [newSpName, setNewSpName] = useState('');
    const [newEmail, setNewEmail] = useState('');
    const [newCountryCode, setNewCountryCode] = useState('');
    const [newPhoneNumber, setNewPhoneNumber] = useState('');
    const [newProvince, setNewProvince] = useState('');
    const [newPostalCode, setNewPostalCode] = useState('');
    const [newWorkingRadius, setNewWorkingRadius] = useState('');
    const [newCountry, setNewCountry] = useState('');
    const [newContactPerson, setNewContactPerson] = useState('');

    const [page, setPage] = useState(0); // State for current page
    const [rowsPerPage, setRowsPerPage] = useState(5); // State for rows per page
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    useEffect(() => {
        // Set filteredSpDetails whenever spDetails changes
        setFilteredSpDetails(userDetails);
    }, [userDetails]);
    useEffect(() => {
        // Code to fetch or update data goes here
        setFilteredSpDetails(userDetails)
        console.log('AdminSp component updated');
    }, [updateTrigger]);

    // const handleStatusToggle = async (user, rating) => {
    //     try {
           
    //         const newStatus = rating.status === 'Active' ? 'Inactive' : 'Active';
            
           
    //         const response = await axios.post(`${API_URL}/admin_update_user_ratings_status`, {
    //             token,
    //             user_id: user.user_id, // Send the user_id
    //             rating_id: rating.rating_id, // Send the specific rating_id
    //             status: newStatus // Send the new status
    //         });
    
    //         if (response.data.success) {
             
    //             setUserDetails((prevDetails) =>
    //                 prevDetails.map((srv) => {
                     
    //                     if (srv.user_id === user.user_id) {
    //                         return {
    //                             ...srv,
    //                             ratings: srv.ratings.map((r) => 
    //                                 r.rating_id === rating.rating_id
    //                                     ? { ...r, status: newStatus } // Update only the target rating's status
    //                                     : r // Keep other ratings unchanged
    //                             )
    //                         };
    //                     }
    //                     return srv;
    //                 })
    //             );
    //         } else {
    //             console.error('Error:', response.data.error);
    //         }
    //     } catch (error) {
    //         console.error('Error:', error.message);
    //     }
    // };
    const handleStatusToggle = async (row, rating) => {
        try {
            const newStatus = rating.status === 'Active' ? 'Inactive' : 'Active';
    
            // Call the backend to update the status
            const response = await axios.post(`${API_URL}/admin_update_user_ratings_status`, {
                token,
                user_id: row.user_id,
                rating_id: rating.rating_id,
                status: newStatus,
            });
    
            if (response.data.success) {
                // Update the filteredSpDetails state
                setFilteredSpDetails((prevDetails) =>
                    prevDetails.map((user) => {
                        if (user.user_id === row.user_id) {
                            return {
                                ...user,
                                ratings: user.ratings.map((r) =>
                                    r.rating_id === rating.rating_id
                                        ? { ...r, status: newStatus }
                                        : r
                                ),
                            };
                        }
                        return user;
                    })
                );
            } else {
                console.error('Error updating status:', response.data.error);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    };
    
    
    
    

    const handleSort = (columnKey) => {
        let direction = 'asc';
        if (sortConfig.key === columnKey && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key: columnKey, direction });

        const sortedData = [...filteredSpDetails].sort((a, b) => {
            if (columnKey === 'business_name') {
                if (a.business_name < b.business_name) return direction === 'asc' ? -1 : 1;
                if (a.business_name > b.business_name) return direction === 'asc' ? 1 : -1;
                return 0;
            } else if (columnKey === 'joined_date') {
                const dateA = new Date(a.joined_date);
                const dateB = new Date(b.joined_date);
                return direction === 'asc' ? dateA - dateB : dateB - dateA;
            }
            return 0;
        });

        setFilteredSpDetails(sortedData);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const formatDateTime = (dateTimeString) => {
        const dateUTC = new Date(dateTimeString);
        const timeOptions = {
            timeZone: 'America/Toronto',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        };

        const timeFormatter = new Intl.DateTimeFormat('en-CA', timeOptions);
        const formattedTime = timeFormatter.format(dateUTC);

        const dateInToronto = new Date(dateUTC.toLocaleString('en-US', { timeZone: 'America/Toronto' }));
        const day = String(dateInToronto.getDate()).padStart(2, '0');
        const month = String(dateInToronto.getMonth() + 1).padStart(2, '0');
        const year = dateInToronto.getFullYear();

        return `${day}-${month}-${year} ${formattedTime}`;
    };

    const handleDeleteClick = async (serviceProviderId) => {
        console.log(serviceProviderId)
        try {
            const response = await axios.post(`${API_URL}/admin_delete_service_provider`, {
                token,
                sp_id: serviceProviderId
            });

            if (response.data.success) {
                setUserDetails((prevDetails) => prevDetails.filter((sp) => sp.service_provider_id !== serviceProviderId));
                setFilteredSpDetails((prevDetails) => prevDetails.filter((sp) => sp.service_provider_id !== serviceProviderId));
                console.log('Service provider deleted successfully');
            } else {
                console.error('Error:', response.data.error);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const filterData = (value) => {
        setSearchQuery(value); // Update the search query state
        const filteredData = filteredSpDetails.filter(sp =>
            sp.business_name.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredSpDetails(filteredData);
        setPage(0); // Reset page to 0 when filter changes
    };
    const handleSaveClick = async (service) => {
        try {
            const response = await axios.post(`${API_URL}/admin_update_service_providers`, {
                token,
                sp_id: service.service_provider_id,
                sp_name: newSpName,
                sp_email: newEmail,
                sp_contact_person: newContactPerson,
                sp_country: newCountry,
                sp_country_code: newCountryCode,
                sp_phone_number: newPhoneNumber,
                sp_postal_code: newPostalCode,
                sp_province: newProvince,
                sp_working_radius: newWorkingRadius
            });

            if (response.data.success) {

                setUserDetails((prevDetails) =>
                    prevDetails.map((srv) =>
                        srv.service_provider_id === service.service_provider_id
                            ? {
                                ...srv,
                                business_name: newSpName,
                                email: newEmail,
                                country_code: newCountryCode,
                                phone_number: newPhoneNumber,
                                province: newProvince,
                                postal_code: newPostalCode,
                                working_radius: newWorkingRadius,
                                country: newCountry,
                                contact_person: newContactPerson
                            }
                            : srv
                    )
                );
                setEditId(null); // Exit edit mode
            } else {
                console.error('Error:', response.data.error);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    };

    const handleCancelClick = () => {
        setEditId(null);
        setNewSpName('');
        setNewEmail('');
        setNewContactPerson('');
        setNewCountry('');
        setNewCountryCode('');
        setNewPhoneNumber('');
        setNewPostalCode('');
        setNewProvince('');
        setNewWorkingRadius('');
    };

    const handleEditClick = (service) => {
        setEditId(service.service_provider_id);
        setNewSpName(service.business_name);
        setNewEmail(service.email);
        setNewContactPerson(service.contact_person);
        setNewCountry(service.country);
        setNewCountryCode(service.country_code);
        setNewPhoneNumber(service.phone_number);
        setNewPostalCode(service.postal_code);
        setNewProvince(service.province);
        setNewWorkingRadius(service.working_radius);

    };
    // useEffect(() => {
    //     fetchAllServiceProviders();

    // }, []);


    // async function fetchAllServiceProviders() {
    //     try {
    //         const response = await axios.post(`${API_URL}/admin_get_all_service_providers`, { token });
    //         console.log(response.data.data);
    //         if (response.data.success) {
    //             setSpDetails(response.data.data);
    //             setFilteredSpDetails(response.data.data);
    //         } else {
    //             console.error('Error:', response.data.error);
    //         }
    //     } catch (error) {
    //         console.error('Error:', error.message);
    //     }
    // }




    return (
        <Box sx={{ mt: 4, width: '100%' }}>
            <TableContainer component={Paper} sx={{ maxWidth: '95%', overflowX: 'auto', }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ minWidth: 80 }}>User ID</TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sortConfig.key === 'user_name'}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort('user_name')}
                                >
                                    user Name
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sx={{ minWidth: 100 }}>country code</TableCell>
                            <TableCell sx={{ minWidth: 150 }}>phone number</TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sortConfig.key === 'joined_date'}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort('joined_date')}
                                >
                                    joined date
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sx={{ minWidth: 80 }}>rating</TableCell>
                            <TableCell sx={{ minWidth: 200 }}>comment</TableCell>
                            <TableCell sx={{ minWidth: 100 }}>status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredSpDetails.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                            row.ratings && row.ratings.length > 0 ? (
                                row.ratings.map((rating, index) => (
                                    // Only render a new row if rating exists
                                    rating ? (
                                        <TableRow key={`${row.user_id}-${rating.rating_id || index}`}>
                                            {/* Display User ID */}
                                            <TableCell>{row.user_id}</TableCell>

                                            {/* Display User Name */}
                                            <TableCell>
                                                {editId === row.user_id ? (
                                                    <TextfieldNormal
                                                        value={newSpName}
                                                        onChange={(e) => setNewSpName(e.target.value)}
                                                    />
                                                ) : (
                                                    row.user_name
                                                )}
                                            </TableCell>

                                            {/* Display Country Code */}
                                            <TableCell>{row.country_code}</TableCell>

                                            {/* Display Phone Number */}
                                            <TableCell>{row.phone_number}</TableCell>

                                            {/* Display Joined Date */}
                                            <TableCell>{formatDateTime(row.joined_date)}</TableCell>

                                            {/* Check if rating exists before rendering */}
                                            <TableCell>{rating.rating_value || 'No Rating'}</TableCell>

                                            {/* Display Comment */}
                                            <TableCell>{rating.rating_comment || 'No Comment'}</TableCell>

                                            {/* Display Status */}
                                            <TableCell>
                                                <Switch
                                                    checked={rating.status === 'Active'}
                                                    onChange={() => handleStatusToggle(row,rating)}
                                                />
                                            </TableCell>
                                            {/* <TableCell>{row.active_status}</TableCell> */}
                                        </TableRow>
                                    ) : null // If rating is null, skip rendering the row
                                ))
                            ) : (
                                // If no ratings, render one row with "No Ratings" and "No Comments"
                                <TableRow key={row.user_id}>
                                    <TableCell>{row.user_id}</TableCell>
                                    <TableCell>
                                        {editId === row.user_id ? (
                                            <TextfieldNormal
                                                value={newSpName}
                                                onChange={(e) => setNewSpName(e.target.value)}
                                            />
                                        ) : (
                                            row.user_name
                                        )}
                                    </TableCell>
                                    <TableCell>{row.country_code}</TableCell>
                                    <TableCell>{row.phone_number}</TableCell>
                                    <TableCell>{formatDateTime(row.joined_date)}</TableCell>
                                    <TableCell>No Ratings</TableCell>
                                    <TableCell>No Comments</TableCell>

                                </TableRow>
                            )
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredSpDetails.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
    );
}

export default AdminUserReview;
