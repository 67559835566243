import { Box, Button, MenuItem, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { API_URL } from './Constants';
import TypographyNormal from './TypographyNormal';
import TextfieldNormal from './TextfieldNormal';
import { TableSortLabel } from '@mui/material';

function AdminTypes({serviceDetails,setServiceDetails, typesDetails,setTypesDetails,updateTrigger }) {

    const token = localStorage.getItem('adminToken');
    // const [serviceDetails, setServiceDetails] = useState([]);
    const [editId, setEditId] = useState(null);
    const [newTypeName, setNewTypeName] = useState('');
    const [addingType, setAddingType] = useState(false);
    const [newTypeInput, setNewTypeInput] = useState('');
    // const [typeDetails, setTypeDetails] = useState([]);
    const [selectedService, setSelectedService] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

    const sortedTypes = React.useMemo(() => {
        if (!Array.isArray(typesDetails)) {
            console.error("typesDetails is not an array:", typesDetails);
            return []; // Return an empty array or handle it as needed
        }
        let sortableItems = [...typesDetails];
        if (sortConfig.key) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [typesDetails, sortConfig]);

    const handleSort = (columnKey) => {
        const isAsc = sortConfig.key === columnKey && sortConfig.direction === 'asc';
        setSortConfig({ key: columnKey, direction: isAsc ? 'desc' : 'asc' });
    };



    useEffect(() => {
        // Code to fetch or update data goes here
        console.log('AdminType component updated');
    }, [updateTrigger]);

    const handleSaveClick = async (type) => {
        if (!newTypeName.trim()) {
            console.error('Type name cannot be empty');
            return;
        }
        try {
            console.log("type", type)
            const response = await axios.post(`${API_URL}/admin_update_type`, {
                token,
                type_id: type.types_id,
                type_name: newTypeName,
                // service_id: type.type_service
            });

            if (response.data.success) {
                // Update the local state with the new type name
                setServiceDetails((prevDetails) =>
                    prevDetails.map((srv) =>
                        srv.type_id === type.type_id
                            ? { ...srv, type_name: newTypeName }
                            : srv
                    )
                );
                setEditId(null); // Exit edit mode
            } else {
                console.error('Error:', response.data.error);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    };

    const handleCancelClick = () => {
        setEditId(null);
        setNewTypeName('');
    };

    const handleEditClick = (type) => {
        setAddingType(false);
        setNewTypeInput('');
        setSelectedService('');
        setEditId(type.types_id);
        setNewTypeName(type.types);
    };

    useEffect(() => {
        // fetchAllTypes();
        // fetchAllServices();
    }, []);

    // async function fetchAllServices() {
    //     try {
    //         const response = await axios.post(`${API_URL}/admin_get_all_services`, { token });
    //         console.log(response.data.data);
    //         if (response.data.success) {
    //             setServiceDetails(response.data.data);
    //         } else {
    //             console.error('Error:', response.data.error);
    //         }
    //     } catch (error) {
    //         console.error('Error:', error.message);
    //     }
    // }

    // async function fetchAllTypes() {
    //     try {
    //         const response = await axios.post(`${API_URL}/admin_get_all_types`, { token });
    //         console.log(response.data.data);
    //         if (response.data.success) {
    //             setTypesDetails(response.data.data);
    //         } else {
    //             console.error('Error:', response.data.error);
    //         }
    //     } catch (error) {
    //         console.error('Error:', error.message);
    //     }
    // }

    const handleAddClick = () => {
        setEditId(null);
        setNewTypeName('');
        setAddingType(true);

    };

    const handleAddSaveClick = async () => {
        if (!newTypeInput.trim() || !selectedService) {
            console.error('Type name and service must be selected');
            return;
        }
        try {
            const response = await axios.post(`${API_URL}/admin_add_type`, {
                token,
                type_name: newTypeInput,
                service_id: selectedService
            });

            if (response.data.success) {
                setServiceDetails([...serviceDetails, response.data.data]);
                setAddingType(false);
                setNewTypeInput('');
                setSelectedService('');
            } else {
                console.error('Error:', response.data.error);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    };

    const handleAddCancelClick = () => {
        setAddingType(false);
        setNewTypeInput('');
        setSelectedService('');
    };
    const handleStatusToggle = async (type) => {
        const newStatus = type.status === 'Active' ? 'Inactive' : 'Active';
        try {
            const response = await axios.post(`${API_URL}/admin_update_type_status`, {
                token,
                type_id: type.types_id,
                status: newStatus
            });

            if (response.data.success) {
                setTypesDetails((prevDetails) =>
                    prevDetails.map((srv) =>
                        srv.types_id === type.types_id
                            ? { ...srv, status: newStatus }
                            : srv
                    )
                );
            } else {
                console.error('Error:', response.data.error);
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    };

    return (
        <Box sx={{ mt: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <TypographyNormal variant="h6">Type of Service Management</TypographyNormal>
                <Button variant="contained" color="primary" onClick={handleAddClick}>Add Type of Service</Button>
            </Box>
            {addingType && (
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, width: '100%' }}>
                    <Select
                        value={selectedService}
                        onChange={(e) => setSelectedService(e.target.value)}
                        displayEmpty
                        sx={{ mr: 2, width: '30%' }}
                    >
                        <MenuItem value="" disabled>Select Service</MenuItem>
                        {serviceDetails.map((service) => (
                            <MenuItem key={service.service_id} value={service.service_id}>
                                {service.services}
                            </MenuItem>
                        ))}
                    </Select>
                    <TextfieldNormal
                        placeholder="New Type of Service Name"
                        value={newTypeInput}
                        onChange={(e) => setNewTypeInput(e.target.value)}
                        sx={{ mr: 2, width: '40%' }}
                    />

                    <Button variant="contained" color="primary" onClick={handleAddSaveClick}>Save</Button>
                    <Button variant="contained" color="secondary" onClick={handleAddCancelClick} sx={{ ml: 1 }}>Cancel</Button>
                </Box>
            )}
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Type ID</TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sortConfig.key === 'types'}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort('types')}
                                >
                                    Type of Service
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sortConfig.key === 'services'}
                                    direction={sortConfig.direction}
                                    onClick={() => handleSort('services')}
                                >
                                    Service
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedTypes.map((row) => (
                            <TableRow key={row.types_id}>
                                <TableCell>{row.types_id}</TableCell>
                                <TableCell>
                                    {editId === row.types_id ? (
                                        <TextfieldNormal
                                            value={newTypeName}
                                            onChange={(e) => setNewTypeName(e.target.value)}
                                        />
                                    ) : (
                                        row.types
                                    )}
                                </TableCell>
                                <TableCell>{row.services}</TableCell>
                                <TableCell>
                                    <Switch
                                        checked={row.status === 'Active'}
                                        onChange={() => handleStatusToggle(row)}

                                    />
                                </TableCell>
                                <TableCell sx={{ display: 'flex', gap: '8px' }}>
                                    {editId === row.types_id ? (
                                        <>
                                            <Button variant="contained" color="primary" onClick={() => handleSaveClick(row)}>Save</Button>
                                            <Button variant="contained" color="secondary" onClick={handleCancelClick}>Cancel</Button>
                                        </>
                                    ) : (
                                        <Button variant="contained" color="primary" onClick={() => handleEditClick(row)}>Edit</Button>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default AdminTypes;
